export const LOCATIONS_MAP_CONSTANTS = {
  mapHeight: '570px' as string,
  mapWidth: '100%' as string,
  mapOptions: {
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'all',
        stylers: [{ saturation: -100 }],
      },
    ],
  } as google.maps.MapOptions,
  googleMapsApiLoadWait: 800 as number,
  googleDirectionsLinkZoomLevel: 15 as number,
  googleDirectionsLinkTemplate:
    'https://www.google.com/maps/dir//{{addressUriEncoded}}/@{{latitude}},{{longitude}},{{zoomLevel}}z/' as string,
};
