import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { PlatformService } from 'src/app/services/platform.service';
import { CanonicalLinkService } from './canonical-link.service';

@Injectable({
  providedIn: 'root',
})
export class SeoHelperService {
  constructor(
    private title: Title,
    private meta: Meta,
    private eventTrackingService: EventTrackingService,
    private platformService: PlatformService,
    private canonicalLinkService: CanonicalLinkService
  ) {}

  setPageData(
    pageTitle: string,
    pageDescription: string,
    event: NavigationEnd
  ): void {
    this.title.setTitle(pageTitle);
    this.meta.updateTag(
      { property: 'og:title', content: pageTitle },
      "name='og:title'"
    );
    this.meta.updateTag(
      { property: 'description', content: pageDescription },
      "name='description'"
    );

    this.canonicalLinkService.createLinkForCanonicalURL();
    // remove pixel scripts on each navigation to avoid their duplication and unnecessary precense on the pag
    // they are dynamically added by google tag manager
    if (this.platformService.isBrowser()) {
      const existingPixelScriptTags =
        document.querySelectorAll('#beback-pixel');
      existingPixelScriptTags.forEach((pixelScriptNode) =>
        pixelScriptNode.remove()
      );
    }
    this.eventTrackingService.logPageViewToGA(
      event.urlAfterRedirects,
      pageTitle
    );
  }
}
