<div class="contain-nav">
  <div class="nav-content">
    <div class="top-nav-bar">
      <div (click)="navigateTo('/')" class="logo">
        <img
          src="{{ S3_URL }}logos/KeswickLogoBlack.svg"
          alt="Keswick Homes Logo"
        />
      </div>
      <img
        (click)="close()"
        class="mobile-icon close"
        src="{{ S3_URL }}icons/CloseIcon_Pink.svg"
      />
    </div>
    <div class="mobile-full-menu">
      <ul>
        <li class="mobile-nav-item" (click)="navigateTo('/build-your-home')">
          BUILD YOUR DREAM HOME
        </li>
        <li class="mobile-nav-item" (click)="navigateTo('/gallery')">
          GALLERY
        </li>
        <li class="mobile-nav-item" (click)="navigateTo('/home-design')">
          HOME DESIGN PORTFOLIO
        </li>

        <!-- <li class="mobile-nav-item" (click)="navigateTo('/available-land')">
          AVAILABLE LAND
        </li> -->
        <li class="mobile-nav-item" (click)="navigateTo('/about')">ABOUT US</li>
        <li class="mobile-nav-item" (click)="navigateTo('/referral')">
          REFERRAL REWARDS
        </li>
        <li class="mobile-nav-item" (click)="navigateTo('/financing')">
          FINANCING
        </li>
        <!-- <li class="mobile-nav-item" (click)="navigateTo('/realtors')">
          FOR REALTORS
        </li> -->

        <!-- <li class="mobile-nav-item" (click)="navigateTo('/home-stories')">
          HOME STORIES
        </li> -->
        <li class="mobile-nav-item" (click)="navigateTo('/contact')">
          CONTACT US
        </li>
      </ul>
    </div>
  </div>
</div>
