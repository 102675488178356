import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AvailableLandService } from 'src/app/services/available-land.service';
import { PlansService } from './../../services/plans.service';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ScreenService } from 'src/app/services/screen-size.service';
import { BreakpointsUtils } from 'src/app/utils/breakpoints-base-class.utils';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { FormsUtils } from 'src/app/utils/forms.utils';
import { FormSubmissionModalComponent } from '../../common/form-submission-modal/form-submission-modal.component';
import { NavigationService } from '../../services/navigation.service';
import { FormValidationService } from './../../services/form-validation.service';
import { FormService } from './../../services/form.service';
import { FieldAndValue } from './../../types/interfaces';
import { ContactFormInputs } from './contact-form.models';

@Component({
  selector: 'kwh-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent
  extends BreakpointsUtils
  implements OnInit, OnDestroy
{
  @ViewChild('contactForm') contactForm: ElementRef;

  private subs: Subscription[] = [];

  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService,
    private fb: UntypedFormBuilder,
    private validationService: FormValidationService,
    private formService: FormService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private doc: Document,
    private availableLandService: AvailableLandService,
    private plansService: PlansService
  ) {
    super(screenService);
  }

  @Input() public id: string;
  @Input() public formName: string;
  @Input() public contactFormInputs: ContactFormInputs;
  public contactFormInputsToUse: ContactFormInputs;
  private contactFormInputsWithCMSData: ContactFormInputs;
  @Input() public heading: string;
  @Input() public subheading: string;
  @Input() public disclaimers: string[];
  @Input() public viewMapLink: string;
  @Input() public includeViewMapButton: boolean;
  @Input() public includeContactPreferenceBox: boolean = true;
  @Input() public submitFormButtonText: string = 'SEND';
  @Input() public useShadedBackground: boolean = false;
  @Input() public formFieldConfigs = []; //TODO: add types and models
  @Input() public contactInfoItems = []; //TODO: add types and models
  @Input() public sidePanelImages = []; //TODO: add types and models
  @Input() public mobileImageAbove: string;
  @Input() public smallerHeading: boolean = false;

  public isContactPage: boolean;

  contactFormGroup: UntypedFormGroup;
  defaultValues: any = {};
  public errorMessages = {};

  public landOptions = [];
  public homeOptions = [];

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'contactFormInputs');

    // Silence error about missing contactMethod control
    this.contactFormGroup = this.fb.group({
      formidentifier: ['placeholder-identifier'],
      contactMethod: [null],
    });

    this.fetchFormData();

    const wind = this.doc.defaultView;
    this.isContactPage = wind.location.pathname === '/contact';
  }

  fetchFormData() {
    this.contactFormInputs?.formName &&
      this.subs.push(
        this.formService
          .getFormStructure(this.contactFormInputs?.formName)
          .pipe(
            tap((formData: any) => {
              const contactFormInputsWithCMSData = {
                ...this.contactFormInputsToUse,
                formFieldConfigs:
                  formData?.formFields
                    ?.map((fF) => {
                      const returnFields = [
                        {
                          ...fF.metadata,
                          id: fF.id,
                          label: fF.label,
                          sequence: fF.sequence,
                          elementType:
                            fF.inputType === 'text' ? undefined : fF.inputType,
                          ...(fF.metadata?.options && {
                            options:
                              fF.metadata?.options?.map?.((o) => ({
                                label: o,
                              })) || fF?.metadata?.options,
                          }),
                        },
                      ];
                      if (fF.metadata?.placeholderAfter) {
                        returnFields.push({
                          elementType: 'placeholder',
                        });
                      }
                      return returnFields;
                    })
                    .reduce((newList, list) => [...newList, ...list], [])
                    ?.sort?.((a: any, b: any) => a.sequence - b.sequence) || [],
              };
              this.contactFormInputsWithCMSData = cloneDeep(
                contactFormInputsWithCMSData
              );
              this.mergeNewReceivedFormFieldsWithLocalOnes(
                contactFormInputsWithCMSData,
                true
              );
              // Populate dropdowns
              const formFieldsNeedingAsyncLoad = this.formFieldConfigs.filter(
                (fieldConfig) => fieldConfig.options?.useProperty
              );
              if (formFieldsNeedingAsyncLoad.length) {
                formFieldsNeedingAsyncLoad.forEach((fieldConfig) => {
                  this[`load_${fieldConfig.options?.useProperty}`]?.();
                });
              }
            })
          )
          .subscribe()
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contactFormInputs']?.currentValue) {
      // Merge incoing inputs with local inputs copy
      if (!changes['contactFormInputs']?.previousValue) {
        this.fetchFormData();
      }
      this.mergeNewReceivedFormFieldsWithLocalOnes(
        changes['contactFormInputs'].currentValue
      );
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  private mergeNewReceivedFormFieldsWithLocalOnes(
    newInputs,
    becauseCMSFetch = false
  ) {
    let cloned = cloneDeep(this.contactFormInputsToUse || newInputs);
    cloned = {
      ...cloned,
      ...newInputs,
      // For most stuff, we can just overwrite, but formFieldConfigs
      // require merging with CMS data
      formFieldConfigs: cloned.formFieldConfigs,
    };
    for (let formFieldConfig of newInputs.formFieldConfigs) {
      // If no CMS data, just use new inputs
      if (!this.contactFormInputsWithCMSData) {
        cloned.formFieldConfigs = newInputs.formFieldConfigs;
      } else if (
        // If it's a totally new formConfigField, just push it in
        (becauseCMSFetch ||
          !this.contactFormInputsWithCMSData.formFieldConfigs.some(
            (f) => f.formControlName === formFieldConfig.formControlName
          )) &&
        !cloned.formFieldConfigs.some(
          (f) => f.formControlName === formFieldConfig.formControlName
        )
      ) {
        cloned.formFieldConfigs.push(formFieldConfig);
      } else if (
        // If it exists in current configs, but it's not a CMS driven field,
        // replace the existing formConfigField with the new one
        !this.contactFormInputsWithCMSData.formFieldConfigs.some(
          (f) => f.formControlName === formFieldConfig.formControlName
        ) &&
        cloned.formFieldConfigs.some(
          (f) => f.formControlName === formFieldConfig.formControlName
        )
      ) {
        cloned.formFieldConfigs = cloned.formFieldConfigs.map((f) =>
          f.formControlName === formFieldConfig.formControlName
            ? formFieldConfig
            : f
        );
      }
    }
    cloned.formFieldConfigs = cloned.formFieldConfigs.sort(
      (a: any, b: any) => a.sequence - b.sequence
    );
    this.contactFormInputsToUse = cloned;
    useInputsObjectIfApplicable(this, 'contactFormInputsToUse');
    this.initContactForm();
  }

  // These are called dynamically based on database config
  private load_landOptions(): void {
    this.subs.push(
      this.availableLandService
        .getAvailableLand()
        .pipe(
          tap((items) => {
            this.landOptions = items?.map((item) => {
              const addr = `${item?.address?.address1}${
                item?.address?.address2 ? ` ${item?.address?.address2}` : ''
              }`;
              return {
                label: addr,
                value: addr,
              };
            });
          })
        )
        .subscribe()
    );
  }

  // These are called dynamically based on database config
  private load_homeOptions(): void {
    this.subs.push(
      this.plansService
        .getPlans({})
        .pipe(
          tap((planResp) => {
            const plans = planResp?.items || [];
            this.homeOptions = plans.map((plan) => ({
              label: plan.expandedTitle,
              value: plan.expandedTitle,
            }));
          })
        )
        .subscribe()
    );
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }

  private getFormValues(): FieldAndValue[] {
    return FormsUtils.getFormValues(this.contactForm);
  }

  public checkForErrors(): void {
    const newErrorsObj = {};
    const controls = this.contactFormGroup.controls;
    Object.keys(controls).forEach((field) => {
      const control = controls[field];
      if (field !== 'formIdentifier' && !control.pristine) {
        if (control.errors?.['required']) {
          newErrorsObj[field] = 'Required';
        } else {
          const invalidMessage = Object.keys(control.errors || {}).find((key) =>
            key.startsWith('invalid')
          );
          if (control.status === 'INVALID') {
            newErrorsObj[field] = `Invalid ${field}`;
            const fieldConfig = this.contactFormInputs.formFieldConfigs?.find(
              (c) => c.formControlName === field
            );
            if (fieldConfig) {
              if (fieldConfig.type === 'email') {
                newErrorsObj[field] = `Enter valid email: [addressee]@[domain]`;
              } else if (fieldConfig.type === 'tel') {
                newErrorsObj[field] = `Enter valid phone: xxx-xxx-xxxx`;
              } else if (fieldConfig.mask === '00000') {
                newErrorsObj[field] = `Enter valid zip: xxxxx`;
              }
            }
          }
        }
      }
    });
    this.errorMessages = newErrorsObj;
  }

  public checkFormIsValid(): boolean {
    const missingRequiredField = Object.keys(
      this.contactFormGroup.controls
    ).find(
      (field) => this.contactFormGroup.controls[field]?.errors?.['required']
    );
    return (
      !this.contactFormGroup.pristine &&
      !missingRequiredField &&
      !Object.keys(this.errorMessages).length
    );
  }

  private initContactForm(): void {
    if (this.contactFormInputsToUse) {
      const formHasContactPreferenceBox = !(
        this.contactFormInputsToUse.hasOwnProperty(
          'includeContactPreferenceBox'
        ) && this.contactFormInputsToUse.includeContactPreferenceBox === false
      );
      const fbGroup = {
        formidentifier: [this.contactFormInputsToUse.id],
      };
      for (const item of this.contactFormInputsToUse.formFieldConfigs) {
        if (item.elementType !== 'placeholder') {
          const formCont = item.formControlName;
          const initialValue = item.forceValue ?? null;
          if (!!initialValue) {
            this.defaultValues[item.formControlName] = initialValue;
          }
          let itemConfig;
          if (!!item.type && item.type === 'email') {
            itemConfig = [
              initialValue,
              Validators.compose([
                Validators.required,
                this.validationService.isValidEmail,
              ]),
            ];
          } else if (!!item.type && item.type === 'tel') {
            itemConfig = [
              initialValue,
              Validators.compose([
                this.validationService.isValidPhone,
                ...(item.required ? [Validators.required] : []),
              ]),
            ];
          } else {
            itemConfig = item.required
              ? [initialValue, Validators.required]
              : [initialValue];
          }
          fbGroup[formCont] = itemConfig;
        }
      }
      this.contactFormGroup = this.fb.group(fbGroup);
      formHasContactPreferenceBox &&
        this.contactFormGroup.controls['contactMethod']?.setValue('email');
      for (const item of this.contactFormInputsToUse.formFieldConfigs) {
        // handle "derive:" syntax in form field metada
        if (item.forceValue?.startsWith('derive:')) {
          const accessor = item.forceValue?.split(':')[1];
          const value = get(this.contactFormInputs, accessor);
          this.contactFormGroup.controls[item.formControlName]?.setValue(value);
        }
      }
    }
  }

  private openModal(): void {
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.panelClass = 'wmargin';
    modalConfig.backdropClass = 'backdrop-dark';
    this.dialog.open(FormSubmissionModalComponent, modalConfig);
  }

  public submit(): void {
    this.subs.push(
      this.formService
        .submitFormData({
          name: this.contactFormInputsWithCMSData.formName,
          fields: this.contactFormInputsWithCMSData.formFieldConfigs
            .map((c) =>
              c.id
                ? {
                    id: c.id,
                    value: this.contactFormGroup.value[c.formControlName],
                  }
                : null
            )
            .filter(Boolean),
        })
        .subscribe()
    );

    this.subs.push(
      this.formService
        .submitFormDataToAR(this.contactFormGroup.value)
        .subscribe((res) => {
          // console.log('contact-form.component.ts submit => res', res);

          if (res.success === true) {
            this.openModal();
            this.contactFormGroup.reset();
            this.contactFormGroup.patchValue({
              formidentifier: this.contactFormInputs.id,
              ...(!!this.contactFormGroup.get('contactMethod')
                ? { contactMethod: 'email' }
                : null),
              ...(Object.keys(this.defaultValues).length
                ? this.defaultValues
                : null),
            });
          } else {
            console.log(res.error);
            //this.serverError = this.formatServerErrorForDisplay(res.error);
          }
        })
    );
  }

  getRadioFormField() {
    return this.formFieldConfigs?.find((f) => f.elementType === 'radio');
  }
}
