export const buildParams = (conditions: {
  [key: string]: any;
}): { [key: string]: any } => {
  const params = Object.keys(conditions).reduce((acc, key) => {
    if (!conditions[key]) {
      return { ...acc };
    }

    if (conditions[key] instanceof Array) {
      return { ...acc, [`${key}[]`]: conditions[key] };
    }

    return { ...acc, [key]: conditions[key] };
  }, {});

  return params;
};
