import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BreakpointsUtils } from 'src/app/utils/breakpoints-base-class.utils';
import { ScreenService } from './../../services/screen-size.service';

@Component({
  selector: 'kwh-full-shadow-modal',
  templateUrl: './full-shadow-modal.component.html',
  styleUrls: ['./full-shadow-modal.component.scss'],
})
export class FullShadowModalComponent
  extends BreakpointsUtils
  implements OnInit
{
  @ViewChild('overlay') overlay: ElementRef;
  @ViewChild('closeIcon') closeIcon: ElementRef;

  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  /**
   * In most cases, you should pass in an anonymous function like this
   * or you might get ExpressionChangedAfterItHasBeenCheckedError
   *
   * MyComponent {
   *   closeModal = () => { this.isModalOpen = false; }
   * }
   *
   * <kwh-full-shadow-modal [onClose]="closeModal"></kwh-full-shadow-modal>
   */
  @Input() onClose: any;
  @Input() extraDark: boolean = false;
  @Input() coverScreen: boolean = false;

  ngOnInit(): void {}

  public handleOnClose(e: Event = null): void {
    if (
      e.target === this.overlay.nativeElement ||
      (this.closeIcon && e.target === this.closeIcon.nativeElement)
    ) {
      this.onClose?.();
    }
  }
}
