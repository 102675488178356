<div
  class="photo-slider-container {{ noMargins ? 'no-margins' : '' }}"
  [style]="
    'margin-top: ' + marginTop + '; margin-bottom: ' + marginBottom + ';'
  "
>
  <div class="photo-slider-window">
    <div class="photo-slider-items-container" [style]="photoItemsStyle">
      <div
        *ngFor="let photoItem of photoItems || []; index as i"
        class="photo-slider-item"
      >
        <div
          *ngIf="photoItem.iconOverlaySrc"
          class="photo-slider-item-icon-overlay"
        >
          <img src="{{ photoItem.iconOverlaySrc }}" />
        </div>
        <img
          class="photo-slider-item-photo"
          src="{{ resolvedImageSrcs.get(i) }}"
          alt="{{ photoItem.imageAltText }}"
        />
        <div
          *ngIf="!buttonsOnOneSide"
          class="photo-slider-nav-button previous"
          (click)="goToPhoto(i - 1)"
          [style]="arrowsStyle"
          [style.backgroundImage]="
            'url(' +
            S3_URL +
            (isDesktop
              ? 'icons/about/Left_Arrow_Box_White.svg)'
              : 'icons/gallery/left_gallery_arrow_MOBILE.svg')
          "
        ></div>
        <div
          *ngIf="!buttonsOnOneSide"
          class="photo-slider-nav-button next"
          (click)="goToPhoto(i + 1)"
          [style]="arrowsStyle"
          [style.backgroundImage]="
            'url(' +
            S3_URL +
            (isDesktop
              ? 'icons/about/Right_Arrow_Box_White.svg'
              : 'icons/gallery/right_gallery_arrow_MOBILE.svg')
          "
        ></div>
        <div *ngIf="buttonsOnOneSide" class="photo-slider-nav-buttons-on-side">
          <div
            class="photo-slider-nav-button next"
            (click)="goToPhoto(i + 1)"
            [style]="arrowsStyle"
            [style.backgroundImage]="
              'url(' +
              S3_URL +
              (isDesktop
                ? 'icons/about/Right_Arrow_Box_White.svg'
                : 'icons/gallery/right_gallery_arrow_MOBILE.svg')
            "
          ></div>
          <div
            class="photo-slider-nav-button previous"
            (click)="goToPhoto(i - 1)"
            [style]="arrowsStyle"
            [style.backgroundImage]="
              'url(' +
              S3_URL +
              (isDesktop
                ? 'icons/about/Left_Arrow_Box_White.svg)'
                : 'icons/gallery/left_gallery_arrow_MOBILE.svg')
            "
          ></div>
        </div>
        <div
          *ngIf="photoItem.caption && includeCaptions"
          class="photo-slider-item-caption-overlay"
        >
          <p>{{ photoItem.caption }}</p>
        </div>
        <div
          *ngIf="photoItem.heading"
          class="photo-slider-item-heading"
          [style]="
            photoItem.headingWidth
              ? 'max-width: ' + photoItem.headingWidth + ';'
              : ''
          "
        >
          <h2>
            {{ photoItem.heading }}<br />{{ photoItem.headingAfterForcedBreak }}
          </h2>
        </div>
        <div
          *ngIf="photoItem.subheading"
          class="photo-slider-item-subheading"
          [style]="
            photoItem.subheadingWidth
              ? 'max-width: ' + photoItem.subheadingWidth + ';'
              : ''
          "
        >
          <h3>{{ photoItem.subheading }}</h3>
        </div>
        <div
          *ngIf="photoItem.buttonText"
          class="photo-slider-item-button-container"
        >
          <button type="button" (click)="navigateTo(photoItem.buttonLink)">
            {{ photoItem.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
