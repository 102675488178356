import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationService } from './../../services/navigation.service';

import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { SliderDimensions } from './../photo-slider/photo-slider.models';
import { ButtonsAndGalleryInputs, Gallery } from './buttons-and-gallery.models';

@Component({
  selector: 'kwh-buttons-and-gallery',
  templateUrl: './buttons-and-gallery.component.html',
  styleUrls: ['./buttons-and-gallery.component.scss'],
})
export class ButtonsAndGalleryComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService,
    private detector: ChangeDetectorRef
  ) {
    super(screenService);
  }

  public isMobileGalleriesSelectorOpen: boolean;

  @Input() public buttonsAndGalleryInputs: ButtonsAndGalleryInputs;
  @Input() public screenName: string;
  @Input() public galleries: Gallery[] = [];

  public selectedGallery: Gallery;

  public SLIDER_DIMENSIONS: SliderDimensions = {
    x: '954px',
    y: '716px',
  };

  public MOBILE_SLIDER_DIMENSIONS: SliderDimensions = {
    x: '100%',
    y: '78vw',
  };

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'buttonsAndGalleryInputs');
    this.setInitialGalleryData();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['buttonsAndGalleryInputs']) {
      useInputsObjectIfApplicable(this, 'buttonsAndGalleryInputs');
      this.setInitialGalleryData();
    }
  }

  public setInitialGalleryData(): void {
    this.selectedGallery = this.galleries[0] as Gallery;
  }

  public selectGallery(event: Event, galleryIndex: number): void {
    event.stopPropagation();
    this.selectedGallery = this.galleries[galleryIndex] as Gallery;
    this.navigationService.navigateTo('#gallery-photo-slider');
    this.detector.detectChanges();
  }

  public toggleIsMobileGalleriesSelectorOpen(
    bool: boolean = !this.isMobileGalleriesSelectorOpen
  ): void {
    this.isMobileGalleriesSelectorOpen = bool;
  }
}
