import { Component, Input, OnInit } from '@angular/core';

import { NavigationService } from 'src/app/services/navigation.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { escapeCharactersForCss } from './../../utils/css-utils';
import { ImageShadowedCalloutInputs } from './image-shadowed-callout.models';

@Component({
  selector: 'kwh-image-shadowed-callout',
  templateUrl: './image-shadowed-callout.component.html',
  styleUrls: ['./image-shadowed-callout.component.scss'],
})
export class ImageShadowedCalloutComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  @Input() public imageShadowedCalloutInputs: ImageShadowedCalloutInputs;
  @Input() public id: string;
  @Input() public imageSrc: string;
  @Input() public mobileImageSrc: string;
  @Input() public heading: string = null;
  @Input() public subheading: string = null;
  @Input() public subheadingWidth: string;
  @Input() public verticalOffset: number;
  @Input() public desktopButtonWidth: string = '350px';
  @Input() public buttons = []; //TODO: add types and models

  public contentStyle: string;
  public subheadingStyle: string;
  public backgroundStyle: string;
  public buttonContainerStyle: string;
  public buttonStyle: string;

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'imageShadowedCalloutInputs');
    this.setHtmlAttributes();
    this.aBreakpointWasCrossed$.subscribe(() => this.setHtmlAttributes());
  }

  private setHtmlAttributes(): void {
    this.contentStyle =
      this.verticalOffset != null
        ? `position: relative; top: ${this.verticalOffset}px;`
        : null;
    this.subheadingStyle = this.subheadingWidth
      ? `max-width: ${this.subheadingWidth};`
      : '';
    this.backgroundStyle = `background-image: url(${escapeCharactersForCss(
      this.imageSrc
    )})`;
    this.buttonContainerStyle =
      this.buttons.length === 1
        ? 'justify-content: center;'
        : 'justify-content: space-between;';
    if (this.isDesktop) {
      this.buttonStyle =
        this.buttons.length === 1
          ? `width: ${this.desktopButtonWidth}`
          : 'width: 30%';
    } else {
      this.buttonStyle =
        this.buttons.length === 1 ? 'width: 90%' : 'width: 50%';
    }
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
