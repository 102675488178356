import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class CanonicalLinkService {
  private link: HTMLLinkElement;
  constructor(@Inject(DOCUMENT) private doc) {}
  createLinkForCanonicalURL() {
    if (this.link === undefined) {
      this.link = this.doc.createElement('link');
      this.link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(this.link);
    }
    this.link.setAttribute('href', this.doc.URL.split('?')[0]);
  }
}
