import { GOOGLE_ANALYTICS_ID } from './../../constants/common.constants';
import { SiteService } from 'src/app/services/site.service';
import { Inject, Injectable } from '@angular/core';
import { DropdownItemValue } from '../types/interfaces';
import { getDataLayer } from '../utils/data-layer.utils';
import { WINDOW } from './window.service';

declare const gtag: Function;

export interface Window {
    dataLayer: any;
    self?: any;
    top?: any;
    atlasSettings?: any;
}

@Injectable({
    providedIn: 'root'
})
export class EventTrackingService {
    constructor(private siteService: SiteService, @Inject(WINDOW) public window: Window) { }

    generateDataLayerEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel: string | DropdownItemValue
    ): any {
        // Data layer will be present only in prod
        if (!this.window.dataLayer) {
            console.log('Google Analytics:', getDataLayer(eventCategory, eventAction, eventLabel));
            return;
        }

        const dataLayerEvent = { eventCategory, eventAction, eventLabel };

        this.window.dataLayer.push(dataLayerEvent);
    }

    logPageViewToGA(pagePath: string, pageTitle: string): void {
        if (this.window.dataLayer) {
            const pageViewEvent = {
                event: 'gtm.historyChange',
                page_path: pagePath,
                pageTitle
            };
            this.window.dataLayer.push(pageViewEvent);
            if (typeof gtag !== typeof undefined) {
                gtag('config', GOOGLE_ANALYTICS_ID, {
                    page_path: pagePath
                });
            }
        }
    }
}
