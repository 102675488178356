<div id="{{ id }}" class="s-b-section-container">
  <div class="s-b-section">
    <h2>{{ heading }}</h2>
    <p class="s-b-section-subheading">{{ subheading }}</p>
    <div class="s-b-section-buttons-container">
      <button *ngFor="let button of buttons" (click)="navigateTo(button.link)">
        <span>{{ button.text }}</span
        ><img
          *ngIf="button.includeDropdownChevron"
          src="{{ S3_URL }}icons/download_icon_white.svg"
        />
      </button>
    </div>
  </div>
</div>
