import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactComponent } from './contact.component';
import { SharedModule } from 'src/app/common/shared.module';
import { ContactRoutingModule } from './contact-routing.module';
import { MatOptionModule } from '@angular/material/core';

@NgModule({
  declarations: [ContactComponent],
  imports: [
    CommonModule,
    SharedModule,
    ContactRoutingModule,
    MatSelectModule,
    MatOptionModule,
  ],
  exports: [ContactComponent],
})
export class ContactModule {}
