import {
  Component,
  HostListener,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { environment } from './../../../environments/environment';

import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import {
  MockedChanges,
  SubheadingGalleryItem,
  SubheadingGallerySectionInputs,
} from './subheading-gallery-section.models';

type subheadingIndex = number;

@Component({
  selector: 'kwh-subheading-gallery-section',
  templateUrl: './subheading-gallery-section.component.html',
  styleUrls: ['./subheading-gallery-section.component.scss'],
})
export class SubheadingGallerySectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  private static AUTOSLIDE_INTERVAL_MS = 5000;

  @Input() subheadingGallerySectionInputs: SubheadingGallerySectionInputs;
  @Input() heading: string;
  @Input() subheadingGalleryItems: SubheadingGalleryItem[];
  @Input() id: string;
  @Input() fancyHeading: boolean;
  @Input() invertColors: boolean = true;
  @Input() useShadedBackground: boolean = true;
  @Input() subheadingWidth: string;
  @Input() mobileSubheadingWidth: string;

  public leftButtonSrc: string = `${environment.S3_URL}icons/home/Left_Arrow_Black.svg`;
  public rightButtonSrc: string = `${environment.S3_URL}icons/home/Right_Arrow_Black.svg`;
  public mobileLeftButtonSrc: string = `${environment.S3_URL}icons/home/Left_Arrow_Black_noborder.svg`;
  public mobileRightButtonSrc: string = `${environment.S3_URL}icons/home/Right_Arrow_Black_noborder.svg`;
  public centerIconSrc: string = `${environment.S3_URL}icons/home/Quote_Mark_Icon.svg`;
  public containerStyle: string;
  public headingStyle: string;
  public currentSubheadingGalleryItemIndex: subheadingIndex = 0;
  public galleryItemsStyle: string = 'left: 0px;';

  public subheadingWidthToUse: string = '500px';
  public mobileSubheadingWidthToUse: string = '300px';

  private autoslideInterval;

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'subheadingGallerySectionInputs');
    this.setSubheadingWidth();
    this.setStyles({
      fancyHeading: { currentValue: this.fancyHeading },
      invertColors: { currentValue: this.invertColors },
      useShadedBackground: { currentValue: this.useShadedBackground },
    } as MockedChanges);
    this.startAutoslide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSubheadingWidth();
  }

  // Must set subheading width in pixels for transition to work
  private setSubheadingWidth(): void {
    const maxFractionOfWindow = 0.8;

    if (this.subheadingWidth.includes('px')) {
      const maxWidth = Math.round(window.innerWidth * maxFractionOfWindow);
      this.subheadingWidthToUse = `${Math.min(
        maxWidth,
        Number(this.subheadingWidth.split('px')[0])
      )}px`;
    } else {
      this.subheadingWidthToUse = this.subheadingWidth;
    }
    if (this.mobileSubheadingWidth.includes('px')) {
      const maxWidth = window.innerWidth * maxFractionOfWindow;
      this.mobileSubheadingWidthToUse = `${Math.min(
        maxWidth,
        Number(this.mobileSubheadingWidth.split('px')[0])
      )}px`;
    } else {
      this.mobileSubheadingWidthToUse = this.mobileSubheadingWidth;
    }
  }

  public useMinWidth(): boolean {
    return (this.isDesktop
      ? this.subheadingWidthToUse
      : this.mobileSubheadingWidthToUse
    ).includes('px')
      ? false
      : true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setStyles(changes);
  }

  public ngAfterViewInit() {
    this.shrinkOversizedReviews();
  }

  private shrinkOversizedReviews(): void {
    const subheadingComponents = Array.from(
      document.querySelectorAll('.sh-gallery-section-subheading-item')
    );
    subheadingComponents.forEach((subheadingComponent) => {
      const element = subheadingComponent as HTMLElement;
      if (!this.isDesktop && subheadingComponent.clientHeight > 285) {
        let initialFontSize: number = Number(
          getComputedStyle(element).fontSize.split('px')[0]
        );
        let amountToSubstract = 0;
        while (
          element.clientHeight > 285 &&
          amountToSubstract < initialFontSize
        ) {
          amountToSubstract++;
          element.style.fontSize = `${initialFontSize - amountToSubstract}px`;
        }
      }
    });
  }

  public setStyles(changes: SimpleChanges | MockedChanges): void {
    let headingStyle = '';
    if (changes['fancyHeading']) {
      if (changes['fancyHeading'].currentValue) {
        headingStyle += `
            font-family: var(--font-annabelle);
            color: var(--kwh-pink-text);
            font-size: 95px;
            letter-spacing: 0;
            line-height: 104px;
            text-align: center;
        `;
      }
    }
    if (headingStyle !== this.headingStyle) {
      this.headingStyle = headingStyle;
    }
    let containerStyle = '';
    if (changes['invertColors']) {
      if (changes['invertColors'].currentValue) {
        containerStyle += `
          background-color: var(--kwh-black);
          color: var(--kwh-white);
        `;
      }
    }
    if (changes['useShadedBackground']) {
      containerStyle += `
        ${
          changes['useShadedBackground'].currentValue
            ? 'background-color: var(--kwh-offwhite)'
            : 'background-color: var(--kwh-white)'
        }
      `;
    }
    if (containerStyle !== this.containerStyle) {
      this.containerStyle = containerStyle;
    }
  }

  private startAutoslide(): void {
    if (this.subheadingGalleryItems?.length > 1) {
      clearInterval(this.autoslideInterval);
      this.autoslideInterval = setInterval(() => {
        this.goToItem(this.currentSubheadingGalleryItemIndex + 1, false);
      }, SubheadingGallerySectionComponent.AUTOSLIDE_INTERVAL_MS);
    }
  }

  public goToItem(itemIndex, userInititated: boolean = true): void {
    let resolvedItemIndex: number = itemIndex;
    if (!this.subheadingGalleryItems) resolvedItemIndex = 0;
    else if (itemIndex + 1 > this.subheadingGalleryItems.length)
      resolvedItemIndex = 0;
    else if (itemIndex < 0)
      resolvedItemIndex = this.subheadingGalleryItems.length - 1;

    this.currentSubheadingGalleryItemIndex = resolvedItemIndex;
    this.transitionToItem();
    if (userInititated) {
      this.startAutoslide();
    }
  }

  public transitionToItem(): void {
    if (this.isDesktop) {
      if (this.subheadingWidthToUse.includes('px')) {
        this.galleryItemsStyle =
          'left: -' +
          (
            Number(this.subheadingWidthToUse.split('px')[0]) *
            this.currentSubheadingGalleryItemIndex
          ).toString() +
          'px;';
      } else if (this.subheadingWidthToUse.includes('%')) {
        this.galleryItemsStyle =
          'left: -' +
          (
            Number(this.subheadingWidthToUse.split('%')[0]) *
            this.currentSubheadingGalleryItemIndex
          ).toString() +
          '%;';
      } else if (this.subheadingWidthToUse.includes('vw')) {
        this.galleryItemsStyle =
          'left: -' +
          (
            Number(this.subheadingWidthToUse.split('vw')[0]) *
            this.currentSubheadingGalleryItemIndex
          ).toString() +
          'vw;';
      }
    } else {
      if (this.mobileSubheadingWidthToUse.includes('px')) {
        this.galleryItemsStyle =
          'left: -' +
          (
            Number(this.mobileSubheadingWidthToUse.split('px')[0]) *
            this.currentSubheadingGalleryItemIndex
          ).toString() +
          'px;';
      } else if (this.mobileSubheadingWidthToUse.includes('%')) {
        this.galleryItemsStyle =
          'left: -' +
          (
            Number(this.mobileSubheadingWidthToUse.split('%')[0]) *
            this.currentSubheadingGalleryItemIndex
          ).toString() +
          '%;';
      } else if (this.mobileSubheadingWidthToUse.includes('vw')) {
        this.galleryItemsStyle =
          'left: -' +
          (
            Number(this.mobileSubheadingWidthToUse.split('vw')[0]) *
            this.currentSubheadingGalleryItemIndex
          ).toString() +
          'vw;';
      }
    }
  }
}
