import { Component, Input, OnInit } from '@angular/core';

import { HeroItem } from './hero.models';
import {
  SliderDimensions,
  SliderPhotoItem,
} from '../photo-slider/photo-slider.models';
import { HeroInputs } from './hero.models';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { ScreenService } from './../../services/screen-size.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';

@Component({
  selector: 'kwh-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent extends BreakpointsUtils implements OnInit {
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  @Input() heroInputs: HeroInputs;
  @Input() id: string;
  @Input() sliderDimensions: SliderDimensions;
  @Input() heroItems: HeroItem[];
  @Input() photoItems: SliderPhotoItem[];

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'heroInputs');
    this.setPhotoItemsForSlider();
  }

  public setPhotoItemsForSlider(): void {
    this.photoItems = this.heroItems as SliderPhotoItem[];
  }
}
