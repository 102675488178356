import { environment } from './../../environments/environment';
export const HomeData = [
  {
    property: 'heroInputs',
    defaults: {
      heroItems: [
        {
          imageAltText: 'Photo coming soon',
          caption: null,
          iconOverlaySrc: `${environment.S3_URL}icons/home/K_Mark_Graphic.svg`,
        },
      ],
    },
    inputs: {
      id: 'home-hero',
      sliderDimensions: {
        x: '100%',
        y: '55vw',
      },
      heroItems: [
        {
          desktopImageSrc: `${environment.S3_URL}images/home/carousel/Slide_1_Cropped_Desktop_2x.jpg`,
          mobileImageSrc: `${environment.S3_URL}images/home/carousel/Slide_1_Cropped_Mobile_2x.jpg`,
          heading: "Let's Build Your Dream Home",
          headingWidth: '547px',
          subheading:
            "Together, we'll create a 100% custom design or personalize your favorite plan just for you. It's your chance to dream big.",
          subheadingWidth: '609px',
          buttonText: 'TELL ME MORE',
          buttonLink: '/build-your-home',
        },
        {
          desktopImageSrc: `${environment.S3_URL}images/home/carousel/Slide_2_Cropped_Desktop_2x.jpg`,
          mobileImageSrc: `${environment.S3_URL}images/home/carousel/Slide_2_Cropped_Mobile_2x.jpg`,
          headingWidth: '689px',
          heading: 'Inspiration Abounds',
          subheading:
            'See our previous custom homes to inspire new ideas for your one-of-a-kind dream home.',
          subheadingWidth: '508px',
          buttonText: 'BROWSE THE GALLERY',
          buttonLink: '/gallery',
        },
        {
          desktopImageSrc: `${environment.S3_URL}images/home/carousel/Slide_3_Cropped_Desktop_2x.jpg`,
          mobileImageSrc: `${environment.S3_URL}images/home/carousel/Slide_3_Cropped_Mobile_2x.jpg`,
          headingWidth: '615px',
          heading: 'The Perfect',
          headingAfterForcedBreak: 'Place to Start',
          subheading:
            'Our portfolio of luxurious home designs can provide the starting point for your custom home journey.',
          subheadingWidth: '508px',
          buttonText: 'BROWSE HOME DESIGNS',
          buttonLink: '/home-design',
        },
        /**
         * TODO: uncomment once available land is launched
         */
        // {
        //   desktopImageSrc:
        //     `${environment.S3_URL}images/home/carousel/Slide_4_Cropped_Desktop_2x.jpg`,
        //   mobileImageSrc:
        //     `${environment.S3_URL}images/home/carousel/Slide_4_Cropped_Mobile_2x.jpg`,
        //   headingWidth: '732px',
        //   heading: 'Find the Perfect Land',
        //   subheading:
        //     'Search homesites we have available now or we can use our connections to seek out the ideal place for you in Virginia, Maryland or D.C.',
        //   subheadingWidth: '669px',
        //   buttonText: 'BROWSE AVAILABLE LAND',
        //   buttonLink: '/available-land',
        // },
        {
          desktopImageSrc: `${environment.S3_URL}images/home/carousel/Slide_5_Cropped_Desktop_2x.jpg`,
          mobileImageSrc: `${environment.S3_URL}images/home/carousel/Slide_5_Cropped_Mobile_2x.jpg`,
          headingWidth: '547px',
          heading: "Let's Build Your Dream Home",
          subheading:
            "Together, we'll create a 100% custom design or personalize your favorite plan just for you. It's your chance to dream big.",
          subheadingWidth: '609px',
          buttonText: 'TELL ME MORE',
          buttonLink: '/build-your-home',
        },
        {
          desktopImageSrc: `${environment.S3_URL}images/home/carousel/Slide_6_Cropped_Desktop_2x.jpg`,
          mobileImageSrc: `${environment.S3_URL}images/home/carousel/Slide_6_Cropped_Mobile_2x.jpg`,
          headingWidth: '689px',
          heading: 'Inspiration Abounds',
          subheading:
            'See our previous custom homes to inspire new ideas for your one-of-a-kind dream home.',
          subheadingWidth: '508px',
          buttonText: 'BROWSE THE GALLERY',
          buttonLink: '/gallery',
        },
        {
          desktopImageSrc: `${environment.S3_URL}images/home/carousel/Slide_7_Cropped_Desktop_2x.jpg`,
          mobileImageSrc: `${environment.S3_URL}images/home/carousel/Slide_7_Cropped_Mobile_2x.jpg`,
          headingWidth: '615px',
          heading: 'The Perfect Place to Start',
          subheading:
            'Our portfolio of luxurious home designs can provide the starting point for your custom home journey.',
          subheadingWidth: '508px',
          buttonText: 'BROWSE HOME DESIGNS',
          buttonLink: '/home-design',
        },
        /**
         * TODO: uncomment once available land is launched
         */
        // {
        //   desktopImageSrc:
        //     `${environment.S3_URL}images/home/carousel/Slide_8_Cropped_Desktop_2x.jpg`,
        //   mobileImageSrc:
        //     `${environment.S3_URL}images/home/carousel/Slide_8_Cropped_Mobile_2x.jpg`,
        //   headingWidth: '732px',
        //   heading: 'Find the Perfect Land',
        //   subheading:
        //     'Search homesites we have available now or we can use our connections to seek out the ideal place for you in Virginia, Maryland or D.C.',
        //   subheadingWidth: '669px',
        //   buttonText: 'BROWSE AVAILABLE LAND',
        //   buttonLink: '/available-land',
        // },
      ],
    },
  },
  {
    property: 'simpleSectionInputs',
    inputs: {
      id: 'all-about-you',
      invertColors: false,
      fancyHeading: true,
      subheadingWidth: 'min(94%, 786px)',
      marginBottom: '25px',
      heading: "It's All About You",
      subheading:
        'No other custom builder will work so hard to ensure your ultimate satisfaction. Choosing to build your dream home with us is the beginning of a close partnership and a personal relationship. Your happiness is our only goal.',
    },
  },
  {
    property: 'imageShadowedCalloutInputs',
    inputs: {
      id: 'elevate-expectations-callout',
      imageSrc: `${environment.S3_URL}images/home/Expectations_BG_Cropped_Desktop_2x.jpg`,
      mobileImageSrc: `${environment.S3_URL}images/home/Expectations_BG_Cropped_Mobile_2x.jpg`,
      heading: 'Elevate Your Expectations',
      subheading:
        "You won't have to settle on anything in your custom home. Every square foot will serve a purpose, maximizing functionality and beauty throughout. Together we'll create a design that feels both modern and timeless at once.",
      subheadingWidth: '737px',
      buttons: [
        {
          text: 'PREVIEW THE POSSIBILITIES',
          link: '/gallery',
        },
      ],
    },
  },
  {
    property: 'subheadingGallerySectionInputs',
    inputs: {
      id: 'what-customers-say',
      invertColors: false,
      subheadingWidth: '896px',
      mobileSubheadingWidth: '75vw',
      heading: 'What Our Customers Say',
      subheadingGalleryItems: [
        {
          text: `“This is the 3rd house John has built for us. That should say it all! We are delighted with the craftsmanship, attention to detail, and professionalism.”`,
          bottomCaptionLine1: 'Harry Oken',
          bottomCaptionLine2: 'Keswick Homeowner',
        },
        {
          text: `“John built our home which was a very complex construction.  He was very accommodating to all of our requests and always had a solution for the problems that arose.  He is very easy to work with, is very professional, and an all-around nice guy.”`,
          bottomCaptionLine1: 'Krause',
          bottomCaptionLine2: 'Keswick Homeowner',
        },
        {
          text: `“I highly recommend Keswick Homes. From obtaining the first permits to picking out the last cabinet knob, Keswick Homes was responsive, creative, and sensitive to our needs. I never had to worry about work being completed on time and all of Keswick\'s work is top-notch. Keswick has great contacts in this field and that was a huge benefit for us.”`,
          bottomCaptionLine1: 'Grace',
          bottomCaptionLine2: 'Keswick Homeowner',
        },
        {
          text: `“Constructed my custom build house. Very friendly & detail-oriented. Assisted in each step of the selection process and provided the best value-based advice.”`,
          bottomCaptionLine1: 'Kbala',
          bottomCaptionLine2: 'Keswick Homeowner',
        },
      ],
    },
  },
  {
    property: 'calloutWithIconInputs',
    inputs: {
      id: 'home-schedule-appointment-callout',
      backgroundColor: 'white',
      iconSrc: `${environment.S3_URL}icons/Calendar_Appt_Icon_Teal.svg`,
      heading: 'Schedule an Appointment',
      subheading:
        "When you choose Keswick you're not only getting a custom home, but a personalized process every step of the way. It's why we offer convenient virtual and private in-person appointments from the very beginning. ",
      buttons: [
        {
          text: 'SELECT A TIME',
          link: 'https://calendly.com/jmcdonough-7/30min',
        },
        {
          text: 'LEARN MORE',
          link: '/our-process',
        },
      ],
    },
  },
  {
    property: 'imageShadowedCallout2Inputs',
    inputs: {
      id: 'referral-rewards-callout',
      imageSrc: `${environment.S3_URL}images/home/Referral_BG_Cropped_Desktop_2x.jpg`,
      mobileImageSrc: `${environment.S3_URL}images/home/Referral_BG_Cropped_Mobile_2x.jpg`,
      heading: 'Referral Rewards',
      subheading:
        "Know someone looking for their custom dream home? Send them our way and, when they choose us, we'll have a special reward for you. And one for your friends, too.",
      subheadingWidth: '650px',
      buttons: [
        {
          text: 'REFER A FRIEND',
          link: '/referral',
        },
      ],
    },
  },
];
