import { environment } from './../../environments/environment';
import { LocationsMapData } from './locations-map.data';

export const AboutData = [
  {
    property: 'thinHeroInputs',
    inputs: {
      id: 'about-hero',
      imageSrc: `${environment.S3_URL}images/contact/Contact_Header_Cropped_Desktop_2x.jpg`,
      mobileImageSrc: `${environment.S3_URL}images/contact/About_Header_Cropped_Mobile_2x.jpg`,
      heading: 'Your Personal Builder',
      subheading:
        "We build only a handful of homes each year. That's by design, to ensure that you and your home always get our full attention. Because you deserve that and so much more.",
      mobileSubheading:
        "We build only a handful of homes each year. That's by design, to ensure that you and your home always get our full attention.",
      subheadingWidth: '911px',
      mobileSubheadingWidth: '90%',
      buttons: [
        {
          text: 'Our Process',
          link: '/our-process',
        },
        {
          text: 'Meet The Team',
          link: '#the-team',
        },
        {
          text: 'Our Trade Partners',
          link: '#trade-partners',
        },
      ],
    },
  },
  {
    property: 'infoSectionWithImageInputs',
    inputs: {
      id: 'message-from-president',
      imagePlacement: 'left',
      heading: 'A Message from Our President',
      paragraph:
        "Open communication and a clear vision. Trust and teamwork. Caring for our customers. That's our formula for success. Read a welcome letter from Keswick President John McDonough to find out how we do it.",
      imageSrc: `${environment.S3_URL}images/about/President_Image_2x.jpg`,
      buttons: [
        {
          text: 'READ THE LETTER',
          link: `${environment.S3_URL}pdfs/31021_KWH_LetterFromPresident_OLA_i1.pdf`,
        },
      ],
    },
  },
  {
    property: 'infoSectionWithImageInputs2',
    inputs: {
      id: 'passion-and-satisfaction',
      imagePlacement: 'right',
      heading: 'Passion and Satisfaction',
      paragraph:
        "It's doesn't feel like work for us, because we are passionate about what we do. We truly enjoy it and we wake up each day excited. Imagine working with a builder who loves your home as much as you will.",
      imageSrc: `${environment.S3_URL}images/about/passion_satisfaction_callout.jpg`,
    },
  },
  {
    property: 'numberedInfoSectionInputs',
    inputs: {
      id: 'our-process',
      heading: 'Our Process',
      subheading:
        'No two homes we build are ever alike. Because no two families are the same. Our detailed process takes us from the first handshake to handing you the keys to a truly custom dream home.',
      items: [
        {
          number: '01',
          heading: 'We Learn About You',
          paragraph:
            "We'll begin with a private virtual or in-person meeting designed to get to know you, your lifestyle, you wants, your needs and your dreams. This is the start of a fun and productive relationship.",
        },
        {
          number: '02',
          heading: 'Select Your Homesite and Design',
          paragraph:
            "We'll review floorplan options and discuss your custom needs. We'll tour homesites, as well as completed Keswick homes. We'll start preliminary sketches and discuss pricing.",
        },
        {
          number: '03',
          heading: 'Sign the Contract and Start Planning',
          paragraph:
            "Once we're in agreement and the contract is signed, planning begins for architecture and grading, permits, financing and more. We'll design your kitchen and begin selecting finishes.",
        },
        {
          number: '04',
          heading: 'Close on the Homesite',
          paragraph:
            "You'll sign off on the house siting after a walk-through with Keswick President John McDonough. You'll review and approve contract drawings and a timeline for home delivery.",
        },
        {
          number: '05',
          heading: 'Begin Construction',
          paragraph:
            "We'll walk you through the home at various points during the construction process. You'll be in constant communication with the Keswick team.",
        },
        {
          number: '06',
          heading: 'Welcome Home',
          paragraph:
            "After a final walk-through, we'll address any issues and hand you the keys to your dream home. As part of your warranty, we'll check in at 45 to 60 days and at 1 year to ensure everything is just right.",
        },
      ],
    },
  },
  {
    property: 'peopleBoxesSectionInputs',
    defaults: {
      items: [
        {
          imageAltText: 'Photo coming soon',
        },
      ],
    },
    inputs: {
      heading: 'Meet the Team',
      subheading:
        'Our team brings decades of custom home building experience and a personal touch to ensure you enjoy every moment of this process.',
      id: 'the-team',
      items: [
        {
          imageSrc: `${environment.S3_URL}images/about/John_McDonough_2x.jpg`,
          caption: 'John McDonough',
          subcaption: 'Owner and President',
          readMoreOverlayTextParagraph1:
            "John has been the President of Keswick Homes since 1998 and has more than 40+ years of experience in the homebuilding industry. Throughout his career he has built over 400 custom homes in sought-after areas like McLean, VA; Lorton, VA; Great Falls, VA; Columbia, MD and St. Michaels, MD and has established himself as one of the region's most trusted and in-demand home builders. John is a licensed home builder in both Maryland and Virginia and an involved member of the National Association of Home Builders, Northern Virginia Building Industry Association and Maryland Building Industry Association.",
          readMoreOverlayTextParagraph2:
            'John is a graduate of the University of Maryland Baltimore County with a degree in Political Science. He is also an active member of the Maryland community, having been a long-time travel-level soccer and basketball coach, along with serving as a Special Olympics soccer coach and league organizer. A dedicated and loving family man, he is the proud father of four children and grandfather of four, with two more grandbabies on the way. He applies that same family mentality to his work and prides himself on forming lasting relationships with designers, trade partners and, most importantly, homebuyers.',
        },
        {
          imageSrc: `${environment.S3_URL}images/about/Michael_Ball_2x.jpg`,
          caption: 'Michael Ball',
          subcaption: 'Maryland Realtor & Land Expert',
          readMoreOverlayTextParagraph1:
            "Michael is one of Keswick Homes' trusted realtor partners. He received his Maryland Real Estate License in 1992 and is based in Howard County. After decades in managerial positions at local brokerages, Michael currently uses his extensive experience in land sales as an independent agent for new home builders.",
        },
        {
          imageSrc: `${environment.S3_URL}images/about/John_Stacey_2x.jpg`,
          caption: 'John Stacey',
          subcaption: 'Virginia Realtor and Land Expert',
          readMoreOverlayTextParagraph1:
            "John is one of Keswick Homes' trusted realtor partners. He began his career in Publishing, working as a graphic artist in Florida after graduating from college. In 1986, John pursued a professional career in Golf, continuing on the Mini Tours, PGA Tour and US Open until 1995. John embarked on a real estate career in 2000. His reputation has been built on integrity, total discretion and attention to detail. Earning the trust of his clients, he has received countless referrals and more importantly invaluable friendships.",
        },
      ],
    },
  },
  {
    property: 'brandBoxesSectionInputs',
    defaults: {
      items: [
        {
          imageAltText: '{[{brandName}]}',
          buttonHref: 'https://www.{[{brandName}]}.com',
        },
      ],
    },
    inputs: {
      heading: 'Dedicated Specialists',
      subheading:
        "By building with us, you'll not only have access to our in-house team, but also to a group of experts that will assist with customization.",
      id: 'dedicated-specialists',
      items: [
        {
          brandName: 'Ferguson',
          imageSrc: `${environment.S3_URL}logos/about/ferguson-logo.png`,
          mobileImageSrc: `${environment.S3_URL}logos/about/2_ferguson-logo.png`,
          caption1: 'Victoria Kozak',
          caption2: 'Amrita Srinivas',
        },
        {
          brandName: 'McCormick Paints',
          imageSrc: `${environment.S3_URL}logos/about/McCormick_Paints.png`,
          mobileImageSrc: `${environment.S3_URL}logos/about/10_McCormick_Paints.png`,
          caption1: 'Matt McCormick',
          buttonHref: 'https://www.mccormickpaints.com',
        },
        {
          brandName: 'Pella',
          imageSrc: `${environment.S3_URL}logos/about/pella.png`,
          mobileImageSrc: `${environment.S3_URL}logos/about/4_pella.png`,
          caption1: 'Richard Dowdy',
        },
        {
          brandName: 'Builders First Source',
          imageSrc: `${environment.S3_URL}logos/about/Builders_FirstSource.png`,
          mobileImageSrc: '',
          caption1: 'Christopher Baglio',
          buttonHref: 'https://www.bldr.com',
        },
        {
          brandName: 'Signature Company',
          imageSrc: `${environment.S3_URL}logos/about/Signature_Company.png`,
          mobileImageSrc: '',
          caption1: 'Kelly Manas',
          buttonHref: 'http://www.signaturecos.com',
        },
        {
          brandName: 'Interior Logic Group',
          imageSrc: `${environment.S3_URL}logos/about/Interior_Logic_Group.png`,
          mobileImageSrc: '',
          caption1: 'Carma Becker',
          buttonHref: 'https://interiorlogicgroup.com',
        },
        {
          brandName: 'Select Construction',
          imageSrc: '',
          mobileImageSrc: '',
          caption1: 'Alex Kabiri',
          buttonHref: 'https://www.selectccinc.com/',
        },
        {
          brandName: 'Jose Pimenta Construction Company',
          imageSrc: '',
          mobileImageSrc: '',
          caption1: 'Paul Pimenta',
          buttonHref: 'http://pimentastone.com/',
        },
        {
          brandName: 'Silver Screen & Sound',
          imageSrc: '',
          mobileImageSrc: '',
          caption1: 'Art Cuevas',
          buttonHref: 'https://www.silverscreenandsound.com/',
        },
        {
          brandName: 'S & H Plumbing',
          imageSrc: '',
          mobileImageSrc: '',
          caption1: 'Di HiDalgo',
          buttonHref: null,
        },
        {
          brandName: 'Campos Construction',
          imageSrc: '',
          mobileImageSrc: '',
          caption1: 'George Campos',
          buttonHref: null,
        },
        {
          brandName: 'Delta Concrete',
          imageSrc: '',
          mobileImageSrc: '',
          caption1: 'Manny Carneiro',
          buttonHref: null,
        },
      ],
    },
  },
  {
    property: 'largeBrandScrollerInputs',
    defaults: {
      items: [
        {
          href: 'https://www.{[{nameForSectionFooter}]}.com',
        },
      ],
    },
    inputs: {
      heading: 'Our Trade Partners',
      subheading:
        'Keswick Homes teams with only the most respected and talented partners to ensure that everything inside your home meets our high standards — and yours.',
      id: 'trade-partners',
      footerLine: 'Learn more about our incredible trade partners:',
      imageSrc: `${environment.S3_URL}images/about/Trade_Partners_BG_Cropped_Desktop_2x.jpg`,
      items: [
        {
          imageSrc: `${environment.S3_URL}logos/about/6_Wolf-Sub-Zero-Logo.png`,
          nameForSectionFooter: 'Sub-Zero and Wolf',
          href: 'https://www.subzero-wolf.com',
          footerSequence: 1,
        },
        {
          imageSrc: `${environment.S3_URL}logos/about/delaware_elevator.png`,
          nameForSectionFooter: 'Delaware Elevator',
          href: 'https://delawareelevator.com',
          footerSequence: 2,
        },
        {
          imageSrc: `${environment.S3_URL}logos/about/1_century_cabinetry.png`,
          nameForSectionFooter: 'Century Kitchens',
          href: 'https://www.centurycabinetry.com',
          footerSequence: 5,
        },
        {
          imageSrc: `${environment.S3_URL}logos/about/3_The_Bold_Look_of_Kohler.svg.png`,
          nameForSectionFooter: 'Kohler',
          footerSequence: 6,
        },
        {
          imageSrc: `${environment.S3_URL}logos/about/james_hardie.png`,
          nameForSectionFooter: 'JamesHardie',
          footerSequence: 8,
        },
        {
          imageSrc: `${environment.S3_URL}logos/about/7_General_Electric_logo.png`,
          nameForSectionFooter: 'GE',
          footerSequence: 3,
        },
        {
          imageSrc: `${environment.S3_URL}logos/about/8_Miele-logo.png`,
          nameForSectionFooter: 'Miele',
          footerSequence: 4,
        },
        // {
        //   imageSrc: '',
        //   nameForSectionFooter: 'Hayes Stair Company',
        //   href: 'http://www.hayesstair.com',
        //   footerSequence: 7,
        // },
      ],
    },
  },
  {
    property: 'simpleButtonsSectionInputs',
    inputs: {
      heading: 'All Questions Answered',
      subheading:
        'Get the Keswick Homes Guide to Building a Custom Home or schedule a meeting with our team.',
      id: 'all-questions-answered',
      buttons: [
        {
          text: 'FIND THE ANSWERS',
          includeDropdownChevron: true,
          link: `${environment.S3_URL}pdfs/31021_KWH_All-Questions-Answered_Flyer_OLA_i1F.pdf`,
        },
        {
          text: 'LIST YOUR LAND',
          includeDropdownChevron: true,
          link: `${environment.S3_URL}pdfs/31021_KWH_List_Your_Land_Flyer_OLA_i1F.pdf`,
        },
        {
          text: 'SCHEDULE AN APPOINTMENT',
          includeDropdownChevron: false,
          link: 'https://calendly.com/jmcdonough-7/30min',
        },
      ],
    },
  },
  ...LocationsMapData.map((componentConfig, index) => ({
    ...componentConfig,
    sequence: index + 1 + 8,
    inputs: {
      ...componentConfig.inputs,
      id: index === 0 ? 'about-locations-header' : 'about-locations-map',
    },
  })),
];
