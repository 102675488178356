import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { SliderDimensions, SliderPhotoItem } from './photo-slider.models';

type PhotoItemIndex = number;
type ImageSrc = string;

@Component({
  selector: 'kwh-photo-slider',
  templateUrl: './photo-slider.component.html',
  styleUrls: ['./photo-slider.component.scss'],
})
export class PhotoSliderComponent extends BreakpointsUtils implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  private static AUTOSLIDE_INTERVAL_MS = 5000;

  @Input() public photoItems: SliderPhotoItem[] = [];
  @Input() public mobileSliderDimensions: SliderDimensions;
  @Input() public sliderDimensions: SliderDimensions;
  @Input() public marginTop: string;
  @Input() public marginBottom: string;
  @Input() public buttonsOnOneSide: boolean = false;
  @Input() public includeCaptions: boolean = true;
  @Input() public noMargins: boolean = false;

  public selectedPhotoIndex: number = 0;
  public photoItemsStyle: string = 'left: 0px;';
  private arrowsTimeout;
  public arrowsStyle: string = 'display: block;';
  public resolvedImageSrcs: Map<PhotoItemIndex, ImageSrc> = new Map();
  private autoslideInterval;

  public ngOnInit(): void {
    // TODO: subscribe to window sizing and replace with
    // mobile/desktop depending on size using setImageSrcsToUse();
    this.startAutoslide();
  }

  private startAutoslide(): void {
    if (this.photoItems?.length > 1) {
      clearInterval(this.autoslideInterval);
      this.autoslideInterval = setInterval(() => {
        this.goToPhoto(this.selectedPhotoIndex + 1, false);
      }, PhotoSliderComponent.AUTOSLIDE_INTERVAL_MS);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['sliderDimensions']) {
      document.body.style.setProperty(
        '--photo-slider-width',
        this.sliderDimensions.x
      );
      document.body.style.setProperty(
        '--photo-slider-height',
        this.sliderDimensions.y
      );
    }
    if (changes['photoItems']) {
      this.goToPhoto(0, false);
      this.startAutoslide();
    }
    this.setImageSrcsToUse();
  }

  public setImageSrcsToUse(): void {
    (this.photoItems || []).forEach(
      (photoItem: SliderPhotoItem, photoItemIndex: PhotoItemIndex) => {
        this.resolvedImageSrcs.set(photoItemIndex, photoItem.desktopImageSrc);
      }
    );
  }

  public goToPhoto(photoIndex, userInititated: boolean = true): void {
    let resolvedPhotoIndex: number = photoIndex;
    if (!this.photoItems) resolvedPhotoIndex = 0;
    else if (photoIndex + 1 > this.photoItems.length) resolvedPhotoIndex = 0;
    else if (photoIndex < 0) resolvedPhotoIndex = this.photoItems.length - 1;

    this.selectedPhotoIndex = resolvedPhotoIndex;
    this.transitionToPhoto();
    if (userInititated) {
      this.startAutoslide();
    }
  }

  public transitionToPhoto(): void {
    let photoItemsStyle;
    if (this.sliderDimensions.x.includes('px')) {
      photoItemsStyle =
        'left: -' +
        (
          Number(this.sliderDimensions.x.split('px')[0]) *
          this.selectedPhotoIndex
        ).toString() +
        'px;';
    } else if (this.sliderDimensions.x.includes('%')) {
      photoItemsStyle =
        'left: -' +
        (
          Number(this.sliderDimensions.x.split('%')[0]) *
          this.selectedPhotoIndex
        ).toString() +
        '%;';
    } else if (this.sliderDimensions.x.includes('vw')) {
      photoItemsStyle =
        'left: -' +
        (
          Number(this.sliderDimensions.x.split('vw')[0]) *
          this.selectedPhotoIndex
        ).toString() +
        'vw;';
    }
    this.photoItemsStyle = photoItemsStyle;
    this.arrowsStyle = 'display: none;';
    clearTimeout(this.arrowsTimeout);
    this.arrowsTimeout = setTimeout(() => {
      this.arrowsStyle = 'display: block;';
    }, 750);
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
