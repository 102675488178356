<div class="map-container">
  <google-map
    #map
    [height]="mapConfig.height"
    [width]="mapConfig.width"
    [center]="centerCoordinatesOverride || mapConfig.center"
    [zoom]="mapConfig.zoom"
    [options]="mapConfig.options"
    (mapClick)="closeAddressWindows()"
  >
    <map-marker
      *ngFor="let item of filteredItems; index as idx"
      #markers
      #marker="mapMarker"
      [position]="{ lat: item.address.latitude, lng: item.address.longitude }"
      [options]="{ icon: pinAtIndex(item, idx) }"
      (mapClick)="openAddressWindow(item)"
    ></map-marker>
    <map-info-window #infoWindows *ngFor="let item of filteredItems">
      <div
        class="map-info-window-container"
        *ngIf="onlyAddressAndDirectionsInTooltip; else homesiteTooltip"
      >
        <span class="info-text"
          >{{ item.address.address1 }}<br *ngIf="item.address.address2" />{{
            item.address.address2
          }}<br />{{ item.address.city }} {{ item.address.stateLabel }}
          {{ item.address.zip }}</span
        >
        <span class="info-view-details" (click)="goToGoogleDirections(item)"
          >Get Directions</span
        >
      </div>
      <ng-template #homesiteTooltip>
        <div class="map-info-window-container">
          <span class="info-title">Homesite {{ item.homesite }}</span>
          <span class="info-text">{{ item.address.address1 }}</span>
          <span *ngIf="!!item.address.address2" class="info-text">{{
            item.address.address2
          }}</span>
          <span class="info-text"
            >{{ item.address.city }} {{ item.address.stateLabel }}
            {{ item.address.zip }}</span
          >
          <span class="info-view-details" (click)="goTo(item)"
            >View Details</span
          >
        </div>
      </ng-template>
    </map-info-window>
    <div *ngIf="!isDesktop" class="swiper-overlay-container">
      <swiper [config]="slideConfig">
        <div class="swiper-wrapper">
          <div *ngFor="let item of filteredItems" class="swiper-slide">
            <div
              class="card-content-container"
              *ngIf="
                onlyAddressAndDirectionsOnMobileOverlay;
                else withLotAndPrice
              "
            >
              <span class="card-details">{{ item.address.address1 }}</span>
              <span class="card-details"
                >{{ item.address.city }} {{ item.address.stateLabel }}
                {{ item.address.zip }}</span
              >
              <div
                class="card-view-details"
                (click)="goToGoogleDirections(item)"
              >
                <span>Get Directions</span>
                <img [src]="viewDetailsIcon" />
              </div>
            </div>
            <ng-template #withLotAndPrice>
              <div class="card-content-container">
                <span class="card-title">{{ item.address.address1 }}</span>
                <span class="card-title"
                  >{{ item.address.city }} {{ item.address.stateLabel }}
                  {{ item.address.zip }}</span
                >
                <span class="card-details">
                  {{ item.lotSize }} {{ lotUnitsLabelsMap[item.lotUnits] }} |
                  Homesite Price:
                  {{ item.lotPrice | currency : "USD" : "symbol" : ".0" }}
                </span>
                <div class="card-view-details" (click)="goTo(item)">
                  <span>View Details</span>
                  <img [src]="viewDetailsIcon" />
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </swiper>
    </div>
  </google-map>
</div>
