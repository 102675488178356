<div id="{{ id }}" class="locations-map-container">
  <div class="locations-map-legend">
    <div class="locations-map-legend-content">
      <div class="locations-map-legend-row row-1">
        <div class="locations-map-legend-box"></div>
        Where We've Built
      </div>
      <div class="locations-map-legend-row row-2">
        <div class="locations-map-legend-box"></div>
        Office Locations
      </div>
    </div>
  </div>
  <div
    [style]="isAddressesModalOpen ? 'opacity: 1' : 'opacity: 0'"
    class="locations-map-address-info-modal"
  >
    <div class="locations-map-address-info-modal-content">
      <div
        *ngFor="let item of getItemsForModal()"
        class="locations-map-address-info-modal-item"
      >
        <div class="locations-map-address-info-modal-item-header">
          {{ item.modalTitle }}
        </div>
        <div class="locations-map-address-info-modal-item-line">
          {{ item.modalContent || item.addressLines?.mainAddressLine }}
        </div>
        <div
          *ngIf="item.addressLines?.addressLine2"
          class="locations-map-address-info-modal-item-line"
        >
          {{ item.addressLines.addressLine2 }}
        </div>
        <div
          *ngIf="item.addressLines?.cityStateZip"
          class="locations-map-address-info-modal-item-line"
        >
          {{ item.addressLines.cityStateZip }}
        </div>
      </div>
      <button
        type="button"
        (click)="toggleAddressesModal(false)"
        class="locations-map-hide-info-button locations-map-black-button"
      >
        HIDE
        <img src="{{ S3_URL }}icons/close_info_icon.svg" />
      </button>
    </div>
  </div>
  <button
    [style]="isAddressesModalOpen ? 'opacity: 0' : 'opacity: 1'"
    type="button"
    (click)="toggleAddressesModal(true)"
    class="locations-map-show-info-button locations-map-black-button"
  >
    SHOW INFO
    <img src="{{ S3_URL }}icons/show_info_up_arrow.svg" />
  </button>
  <google-map
    *ngIf="isBrowser"
    [height]="mapHeight"
    [width]="mapWidth"
    [center]="mapCenter"
    [zoom]="resolveMapZoom()"
    [options]="mapOptions"
  >
    <map-marker
      #marker="mapMarker"
      *ngFor="let item of items"
      [position]="getCoordinatesToUse(item)"
      [options]="item.markerOptions"
      (mapClick)="openAddressWindow(marker)"
    >
    </map-marker>
    <map-info-window [options]="infoWindowOptions">
      <div class="locations-map-info-window">
        <div>{{ infoWindowContent?.line1 }}</div>
        <div *ngIf="infoWindowContent?.line2">
          {{ infoWindowContent?.line2 }}
        </div>
        <div>{{ infoWindowContent?.line3 }}</div>
        <div
          class="locations-map-info-window-link"
          (click)="navigateTo(infoWindowContent?.directionsLink)"
        >
          Get Directions
        </div>
      </div></map-info-window
    >
  </google-map>
</div>
