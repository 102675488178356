import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface FormSubmitResponse {
  success: boolean;
  error?: any;
}
@Injectable({
  providedIn: 'root',
})
export class FormService {
  baseUrl: string = environment.API_STEM;

  constructor(private http: HttpClient) {}

  getFormStructure(formName: string) {
    return this.http
      .get<Observable<any>>(`${environment.url}form`, {
        params: { name: formName },
      })
      .pipe(
        map((data: any) => ({
          ...data,
          formFields: data?.formFields?.filter?.(
            (formField) => formField?.metadata?.included !== false
          ),
        }))
      );
  }

  submitFormData(payload: FormPayload) {
    return this.http.post<any>(`${environment.url}form`, payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  submitFormDataToAR(payload: any) {
    const finalPayload = {
      ['data.json']: {
        ...payload,
      },
    };

    return this.http.post<any>(`${this.baseUrl}`, finalPayload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}

interface FormPayloadField {
  id: number;
  value: string;
}

interface FormPayload {
  name: string;
  fields: FormPayloadField[];
}
