import { Component, Input, OnInit } from '@angular/core';

import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { PeopleBoxesSectionInputs } from './people-boxes-section.models';

@Component({
  selector: 'kwh-people-boxes-section',
  templateUrl: './people-boxes-section.component.html',
  styleUrls: ['./people-boxes-section.component.scss'],
})
export class PeopleBoxesSectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  @Input() peopleBoxesSectionInputs: PeopleBoxesSectionInputs;
  @Input() id: string;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() items = []; //TODO: add types and models

  public isFullDisplayOpen: boolean = false;
  public fullDisplayItem: any;

  ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'peopleBoxesSectionInputs');
  }

  toggleFullDisplay = (item, bool: boolean = !this.isFullDisplayOpen) => {
    this.fullDisplayItem = item;
    this.isFullDisplayOpen = bool;
  };
}
