<swiper [config]="slideConfig">
  <div class="swiper-wrapper">
    <div *ngFor="let slide of slideData; let i = index" class="swiper-slide">
      <div
        [ngStyle]="{ 'background-image': 'url(' + slide?.mobileImageSrc + ')' }"
      >
        <h1>{{ slide?.heading }}<br />{{ slide?.headingAfterForcedBreak }}</h1>
        <h3>{{ slide?.subheading }}</h3>
        <a href="{{ slide?.buttonLink }}" class="btn btn-primary">{{
          slide?.buttonText
        }}</a>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</swiper>
