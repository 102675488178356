<kwh-thin-hero
  [thinHeroInputs]="thinHeroInputs"
  [shadedMobileButtonSection]="true"
></kwh-thin-hero>
<kwh-info-section-with-image
  [infoSectionWithImageInputs]="infoSectionWithImageInputs"
></kwh-info-section-with-image>
<kwh-info-section-with-image
  [infoSectionWithImageInputs]="infoSectionWithImageInputs2"
></kwh-info-section-with-image>
<kwh-numbered-info-section
  [numberedInfoSectionInputs]="numberedInfoSectionInputs"
></kwh-numbered-info-section>
<kwh-people-boxes-section
  [peopleBoxesSectionInputs]="peopleBoxesSectionInputs"
></kwh-people-boxes-section>
<kwh-brand-boxes-section
  [brandBoxesSectionInputs]="brandBoxesSectionInputs"
></kwh-brand-boxes-section>
<kwh-large-brand-scroller
  [largeBrandScrollerInputs]="largeBrandScrollerInputs"
></kwh-large-brand-scroller>
<kwh-simple-buttons-section
  [simpleButtonsSectionInputs]="simpleButtonsSectionInputs"
></kwh-simple-buttons-section>
<kwh-simple-section
  [simpleSectionInputs]="simpleSectionInputs"
></kwh-simple-section>
<kwh-locations-map
  [locationsMapInputs]="locationsMapInputs"
></kwh-locations-map>
