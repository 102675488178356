<div
  #overlay
  class="full-shadow-modal-overlay {{ coverScreen ? 'cover-screen' : '' }}"
  (click)="handleOnClose($event)"
  [style]="extraDark ? 'background: rgba(0, 0, 0, 0.9);' : ''"
>
  <div class="full-shadow-modal-inner-content">
    <img
      *ngIf="!coverScreen"
      #closeIcon
      src="{{ S3_URL }}icons/close_info_icon.svg"
      (click)="handleOnClose($event)"
    />
    <ng-content></ng-content>
  </div>
</div>
