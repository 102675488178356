export enum SalesStatus {
  AVAILABLE = 'available',
  CONTRACT_PENDING = 'contract_pending',
  SOLD = 'sold',
}

export type ImageAsset = {
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
  lastIP: string;
  id: number;
  galleryId: number;
  isPreferredDefault: boolean;
  sequence: number;
  title: string;
  caption: string;
  url: string;
  status: string;
  category: string;
};

export enum LotUnits {
  SqFt = 'sq_ft',
  Acres = 'acres',
  Acre = 'acre',
}

export const MappedLotUnitsLabels = {
  [LotUnits.SqFt]: 'Sq. Ft.',
  [LotUnits.Acres]: 'Acres',
  [LotUnits.Acre]: 'Acre',
};

export type AvailableLand = {
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;
  lastIP: string;
  id: number;
  communityId: number;
  planId: number;
  entityAddressId: number;
  elevationId: number;
  salesStatus: string;
  mlsNumber: number;
  mlsUrl: string;
  beds: number;
  masterBedroomLocation: string;
  fullBaths: number;
  halfBaths: number;
  sqFt: number;
  diningAreas: number;
  stories: number;
  garageSpaces: number;
  garageEntry: string;
  garageDetached: string;
  basement: boolean;
  price: number;
  lotPrice: number;
  siteCosts: number;
  lotSize: number;
  lotUnits: LotUnits;
  yearBuilt: number;
  marketingHeadline: string;
  marketingDescription: string;
  websiteUrl: string;
  homesite: string;
  listingType: string;
  isModel: boolean;
  lotNumber: number;
  externalId: number;
  availabilityDate: string;
  status: SalesStatus;
  unSubmittedForms: [];
  planName: string;
  brand: string;
  communityName: string;
  communityExternalId: number;
  divisionName: string;
  divisionExternalId: number;
  regions: Array<{
    label: string;
    stateName: string;
    stateLabel: string;
  }>;
  address: {
    address1: string;
    address2: string;
    city: string;
    zip: number;
    latitude: number;
    longitude: number;
    directions: string;
    country: string;
    stateId: number;
    stateName: string;
    stateLabel: string;
    entityId: number;
  };
  interiorImages: Array<ImageAsset>;
  exteriorImages: Array<ImageAsset>;
  floorplanImages: Array<ImageAsset>;
};
