import { Component, Input, OnInit } from '@angular/core';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';

import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { SimpleButtonsSectionInputs } from './simple-buttons-section.models';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'kwh-simple-buttons-section',
  templateUrl: './simple-buttons-section.component.html',
  styleUrls: ['./simple-buttons-section.component.scss'],
})
export class SimpleButtonsSectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService);
  }

  @Input() simpleButtonsSectionInputs: SimpleButtonsSectionInputs;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() id: string;
  @Input() buttons = []; //TODO: add types and models

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'simpleButtonsSectionInputs');
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
