import { Component, Input, OnInit } from '@angular/core';

import { setComponentInputsFromData } from './../../utils/component-inputs.utils';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { ScreenService } from './../../services/screen-size.service';
import { ContactFormInputs } from '../../common/contact-form/contact-form.models';
import { ThinHeroInputs } from './../../common/thin-hero/thin-hero.models';
import { LocationsMapInputs } from 'src/app/common/locations-map/locations-map.models';
import { SimpleSectionInputs } from 'src/app/common/simple-section/simple-section.models';

import { ContactData } from '../../data/contact.data';

@Component({
  selector: 'kwh-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent extends BreakpointsUtils implements OnInit {
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  @Input() thinHeroInputs: ThinHeroInputs;
  @Input() contactFormInputs: ContactFormInputs;
  @Input() simpleSectionInputs: SimpleSectionInputs;
  @Input() locationsMapInputs: LocationsMapInputs;

  ngOnInit(): void {
    setComponentInputsFromData(this, ContactData);
  }
}
