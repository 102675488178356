<div *ngIf="isDesktop" id="{{ id }}" class="numbered-i-s-container">
  <h2>{{ heading }}</h2>
  <p class="numbered-i-s-subheading">{{ subheading }}</p>
  <div class="numbered-i-s-items-container">
    <div *ngFor="let item of items" class="numbered-i-s-item">
      <div class="numbered-i-s-item-number">{{ item.number }}</div>
      <div class="numbered-i-s-item-text">
        <h3>{{ item.heading }}</h3>
        <p>{{ item.paragraph }}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isMobile || isTablet" class="numbered-container">
  <h2>{{ heading }}</h2>
  <p class="subheading">{{ subheading }}</p>
  <div class="numbered-items-container">
    <kwh-numbered-slider [slideData]='slideData' [slideConfig]='slideConfig' [customClass]='customClass'></kwh-numbered-slider>
  </div>
</div>