import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BreakpointsUtils } from 'src/app/utils/breakpoints-base-class.utils';
import { MenuModalComponent } from '../menu-modal/menu-modal.component';
import { ScreenService } from './../../services/screen-size.service';

@Component({
  selector: 'kwh-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BreakpointsUtils implements OnInit {
  constructor(private dialog: MatDialog, private screenService: ScreenService) {
    super(screenService);
  }

  @Input() hidden: boolean;
  @Input() transparent: boolean;

  ngOnInit(): void {}

  openModal(): void {
    const modalConfig = new MatDialogConfig();
    modalConfig.closeOnNavigation = true;
    modalConfig.disableClose = false;
    modalConfig.backdropClass = 'backdrop-light';
    modalConfig.panelClass = 'full-screen';
    this.dialog.open(MenuModalComponent, modalConfig);
  }
}
