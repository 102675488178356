import { MapStyling } from "src/app/utils/map-styling.utils"

export type AvailableLandMapOptions = {
    options: Record<string, any>,
    center: { lat: number, lng: number },
    zoom: number,
    height: string,
    width: string
}

export const AvailableLandOverviewMapData = [
    {
        property: 'mapConfig',
        inputs: {
            options: {
                zoomControl: true,
                disableDefaultUI: true,
                minZoom: 7,
                styles: [
                    ...MapStyling,
                ]
            },
            center: {
                lat: 38.6944231,
                lng: -76.949308
            },
            zoom: 8.85,
            height: '100%',
            width: '100%'
        } as AvailableLandMapOptions
    },
]