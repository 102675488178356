<div id="{{ id }}" class="b-b-section-container">
  <div class="b-b-section">
    <h2>{{ heading }}</h2>
    <p class="b-b-section-subheading">{{ subheading }}</p>
    <div class="b-b-section-items-container">
      <div
        *ngFor="let item of items"
        class="b-b-section-item {{ item.buttonHref ? '' : 'no-button' }}"
      >
        <div class="b-b-section-item-image-container">
          <img
            *ngIf="item.imageSrc"
            class="b-b-section-item-image"
            src="{{ item.imageSrc }}"
            alt="{{ item.imageAltText }}"
          />
          <div
            *ngIf="!item.imageSrc"
            class="b-b-section-item-image-placeholder-container"
          >
            <div class="b-b-section-item-image-placeholder">
              {{ item.imageAltText }}
            </div>
          </div>
        </div>
        <div class="b-b-section-item-content">
          <div class="b-b-section-item-text">
            <h3 *ngIf="item.caption1">{{ item.caption1 }}</h3>
            <h3 *ngIf="item.caption2">{{ item.caption2 }}</h3>
          </div>
          <button *ngIf="item.buttonHref" (click)="navigateTo(item.buttonHref)">
            LEARN MORE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
