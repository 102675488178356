import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'kwh-form-submission-modal',
  templateUrl: './form-submission-modal.component.html',
  styleUrls: ['./form-submission-modal.component.scss'],
})
export class FormSubmissionModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<FormSubmissionModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  assetsStem = environment.S3_URL;

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }
}
