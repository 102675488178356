import { ComponentType } from '@angular/cdk/portal';
import cloneDeep from 'lodash-es/cloneDeep';

interface ComponentOptions {
  [componentName: string]: any;
}

const doDefaultsRecursive = (defaults, target) => {
  if (defaults) {
    const keysToDefault = Object.keys(defaults);
    keysToDefault.forEach((keyToDefault) => {
      const defaultVal = defaults[keyToDefault];
      if (Array.isArray(defaultVal)) {
        target[keyToDefault].forEach((itemInList) => {
          doDefaultsRecursive(defaultVal[0], itemInList);
        });
      } else if (defaultVal && typeof defaultVal === 'object') {
        if (defaultVal.hasOwnProperty('$use')) {
          if (!target.hasOwnProperty(keyToDefault))
            target[keyToDefault] = target[defaultVal.$use];
        } else {
          doDefaultsRecursive(defaultVal, target[keyToDefault]);
        }
      } else {
        if (typeof defaultVal === 'string') {
          if (!target.hasOwnProperty(keyToDefault))
            target[keyToDefault] = defaultVal.replace(
              /\{\[\{([a-zA-Z0-9]*)\}\]\}/g,
              (_, property, __) => target[property]
            );
        } else {
          if (!target.hasOwnProperty(keyToDefault))
            target[keyToDefault] = defaultVal;
        }
      }
    });
  }
};

// TODO: add more types to this logic
export const setComponentInputDefaults = (componentData: any[]): any[] => {
  componentData.forEach((config) => {
    doDefaultsRecursive(config.defaults, config.inputs);
  });
  return componentData;
};

const executeCreateInputsFromSourceItem = <T>(
  PageDataArrayCloned: any[],
  sourceItem: any,
  classInstance: Partial<ComponentType<T>>
) => {
  PageDataArrayCloned.forEach((config, configIndex) => {
    if (config.createInputsFromItem && sourceItem) {
      PageDataArrayCloned[configIndex] = {
        ...config,
        inputs: {
          ...config.inputs,
          ...config.createInputsFromItem(sourceItem, classInstance),
        },
      };
    }
  });
};

export const setComponentInputsFromData = <T>(
  classInstance: Partial<ComponentType<T>>,
  PageDataArray: any[],
  sourceItem?: any
) => {
  const PageDataArrayCloned = cloneDeep(PageDataArray);
  executeCreateInputsFromSourceItem(
    PageDataArrayCloned,
    sourceItem,
    classInstance
  );
  const defaultedPageDataArray = setComponentInputDefaults(PageDataArrayCloned);
  defaultedPageDataArray.forEach(
    (inputConfig) => (classInstance[inputConfig.property] = inputConfig.inputs)
  );
};

export const useInputsObjectIfApplicable = (
  classInstance,
  inputsObjectPropertyName: string
): void => {
  if (classInstance[inputsObjectPropertyName]) {
    for (let key in classInstance[inputsObjectPropertyName]) {
      classInstance[key] = classInstance[inputsObjectPropertyName][key];
    }
  }
};
