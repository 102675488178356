import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FilterCondition } from '../types/filters';
import { PaginatorOptions } from '../types/interfaces';
import { CMSPlan } from '../types/plan';
import { htmlToText } from '../utils/html.utils';
import { buildParams } from '../utils/rest-helper.utils';
import { sortImages } from '../utils/sort-images.utils';
import { PlanDetails } from './../types/plan';
import { slugify } from './../utils/url.utils';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  planDetails$: BehaviorSubject<PlanDetails> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getPlans(
    conditions: FilterCondition,
    additionalOptions?: PaginatorOptions
  ): Observable<any> {
    const params = buildParams(conditions);

    return this.http
      .get(`${environment.url}plan/templates`, {
        params: { ...params, ...additionalOptions },
      })
      .pipe(map(matchFieldsWithExpectedStructure), map(addDeducedFields));
  }

  getPlanById(id: number): Observable<any> {
    return combineLatest([
      this.http.get(`${environment.url}plan/${id}/template`, {}),
      this.http.get(`${environment.url}plan/${id}/asset`, {}),
      this.http.get(`${environment.url}plan/${id}/feature`, {}),
    ]).pipe(
      map(([item, assets, features]) => [
        matchFieldsWithExpectedStructure({ items: [item] }, assets, features),
        assets,
        features,
      ]),
      map(([data, _]) => addDeducedFields(data)),
      map((data) => data.items?.[0])
    );
  }

  getPlanIdByName(name: string): Observable<any> {
    return this.http.get(`${environment.url}plan/by-name`, {
      params: { name },
    });
  }

  getPlanPDF(planId: number): Observable<any> {
    return this.http
      .get(`${environment.url}plan/${planId}/asset`, {})
      .pipe(
        map(
          (assets: any[]) =>
            assets?.find?.((a) => a.assetType === 'brochure')?.url || null
        )
      );
  }
}

const matchFieldsWithExpectedStructure = (
  data,
  assets = null,
  features = null
) => ({
  ...data,
  items: data.items.map((item: CMSPlan) => {
    const elevationSorted = sortImages(item.elevationImages).map((it) => ({
      src: it.url,
      caption: it.caption,
    }));
    const interiorSorted = sortImages(item.interiorImages).map((it) => ({
      src: it.url,
      caption: it.caption,
    }));
    const floorplanSorted = sortImages(item.floorplanImages).map((it) => ({
      src: it.url,
      caption: it.caption,
    }));
    const defaultElevation = item.elevationImages.find(
      (e) => e.isPreferredDefault
    );

    return {
      id: item?.id,
      slug: slugify(item.name),
      title: item.name,
      expandedTitle: item.marketingHeadline || item.name,
      thumbnailSrc: defaultElevation?.url || elevationSorted?.[0]?.src,
      images: [...elevationSorted, ...interiorSorted, ...floorplanSorted],
      bathsRangeStart: item.bathsFullMin + item.bathsHalfMin * 0.5,
      bathsRangeEnd: item.bathsFullMax + item.bathsHalfMax * 0.5,
      bedsRangeStart: item.bedsMin,
      bedsRangeEnd: item.bedsMax,
      sqFt: item.sqFtMin || item.sqFtMax || 0,
      price: item.basePrice,
      summary: htmlToText(item.marketingDescription),
      layoutPDFSrc:
        assets?.find?.((a) => a.assetType === 'brochure')?.url || null,
      headerImageSrc: assets?.find?.((a) => a.assetType === 'header_image')
        ?.url,
      includedFeatures:
        features
          ?.find?.((f) => f.entityFeatureType === 'included')
          ?.feature?.split('\n') ?? null,
      optionalFeatures:
        features
          ?.find?.((f) => f.entityFeatureType === 'optional')
          ?.feature?.split('\n') ?? null,
    };
  }),
});

const addDeducedFields = (data) => ({
  ...data,
  items: data.items.map((item) => ({
    ...item,
    captionLine1: `${item.sqFt}+ sq. ft.`,
    captionLine2: `${item.bedsRangeStart} - ${item.bedsRangeEnd} Bedrooms | ${item.bathsRangeStart} - ${item.bathsRangeEnd} Baths`,
  })),
});
