// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_STEM: 'https://post.ndgcommunications.com/keswick',
  url: 'https://keswick-dev-api.ndgcommunications.com/api/v1/public/',
  APP_URL: 'https://keswick-dev.ndgcommunications.com/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyD_ms5dBMe5-QlxJQ_4DrTtUPN_PTVImD8',
  S3_URL: 'https://keswick-frontend-assets-dev.s3.amazonaws.com/',
  APPT_SCHEDULE_URL: 'https://calendly.com/jmcdonough-7/30min'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
