import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

type address = string;
type failure = {
  failed: boolean;
};

const geocoderApiUrl =
  'https://maps.google.com/maps/api/geocode/json?address={{address}}&sensor=false&key={{apiKey}}';
const geocoderRetryWait = 1000;

export class GoogleMapsUtils {
  constructor(private http: HttpClient) {}

  public resolvedCoordinates: Map<address, google.maps.LatLngLiteral> =
    new Map();
  private coordinateResolutionFailureStatus: Map<address, failure> = new Map();

  private TEST_RETRY_LOGIC: boolean = false; // DEV NOTE: Flip to see/troubleshoot retry logic
  public getCoordsFromGeocoder(addresses: address[]): void {
    this.TEST_RETRY_LOGIC &&
      console.log('Attempting with', addresses.length, ' addresses ');
    addresses.forEach((address: address) => {
      if (address) {
        let url: string = geocoderApiUrl;
        url = url.replace('{{address}}', address);
        url = url.replace('{{apiKey}}', environment.GOOGLE_MAPS_API_KEY);
        this.http
          .get(url)
          .subscribe(
            (response: {
              status: google.maps.GeocoderStatus;
              results: any[];
            }) => {
              if (response.status == google.maps.GeocoderStatus.ZERO_RESULTS) {
                console.warn(
                  `Google Maps found no coordinate results for address: ${address}`
                );
                this.coordinateResolutionFailureStatus.set(address, {
                  failed: true,
                });
              } else if (response.status == google.maps.GeocoderStatus.OK) {
                const bindData = (): void => {
                  this.resolvedCoordinates.set(
                    address,
                    response.results[0].geometry.location
                  );
                  this.resolvedCoordinates = new Map(this.resolvedCoordinates); // Clone to ensure Angular detects update
                };
                if (this.TEST_RETRY_LOGIC) {
                  const failed = Math.random() > 0.5 ? true : false;
                  if (!failed) bindData();
                  this.coordinateResolutionFailureStatus.set(address, {
                    failed: failed,
                  });
                } else {
                  bindData();
                  this.coordinateResolutionFailureStatus.set(address, {
                    failed: false,
                  });
                }
                const status = this.getGeocoderRoutineStatus(addresses);
                if (status.finished && status.failedAddresses.length) {
                  setTimeout(() => {
                    this.coordinateResolutionFailureStatus = new Map();
                    this.getCoordsFromGeocoder(status.failedAddresses);
                  }, geocoderRetryWait);
                }
              } else {
                console.warn(
                  `Google Maps geocoder returned a bad status: ${response.status}`
                );
                this.coordinateResolutionFailureStatus.set(address, {
                  failed: true,
                });
              }
            }
          );
      }
    });
  }

  private getGeocoderRoutineStatus(addresses: address[]): {
    finished: boolean;
    failedAddresses: address[];
  } {
    return {
      finished:
        this.coordinateResolutionFailureStatus.size === addresses.length,
      failedAddresses: Array.from(
        this.coordinateResolutionFailureStatus.keys()
      ).filter(
        (address) =>
          this.coordinateResolutionFailureStatus.get(address).failed === true
      ),
    };
  }
}
