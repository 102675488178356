import { Component, Input, OnInit } from '@angular/core';

import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { NavigationService } from './../../services/navigation.service';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { BrandBoxesSectionInputs } from './brand-boxes-section.models';

@Component({
  selector: 'kwh-brand-boxes-section',
  templateUrl: './brand-boxes-section.component.html',
  styleUrls: ['./brand-boxes-section.component.scss'],
})
export class BrandBoxesSectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  @Input() brandBoxesSectionInputs: BrandBoxesSectionInputs;
  @Input() id: string;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() items = []; //TODO: add types and models

  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'brandBoxesSectionInputs');
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
