<div class="centered-section">
  <swiper [config]="slideConfig" [ngClass]="customClass">
    <div class="swiper-wrapper">
      <div *ngFor="let slide of slideData; let i = index" class="swiper-slide">
        <div class="item-number">{{ slide?.number }}</div>
        <div class="item-text">
          <h3>{{ slide?.heading }}</h3>
          <p>{{ slide?.paragraph }}</p>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </swiper>
</div>
