import { LotUnits } from 'src/app/types/land';
import { htmlToText } from './html.utils';

export const transformLandItem = ([item, assets, features]) => {
  return {
    ...item,
    headerImageSrc: assets?.find?.((a) => a.assetType === 'header_image')?.url,
    lotSize: Number(item.lotSize),
    lotUnits:
      item.lotUnits === LotUnits.Acres && Number(item.lotSize) === 1
        ? LotUnits.Acre
        : item.lotUnits,
    summary: htmlToText(item.marketingDescription),
    includedFeatures:
      features
        ?.find?.((f) => f.entityFeatureType === 'included')
        ?.feature?.split('\n') ?? null,
    optionalFeatures:
      features
        ?.find?.((f) => f.entityFeatureType === 'optional')
        ?.feature?.split('\n') ?? null,
  };
};
