<kwh-hero *ngIf="isDesktop" [heroInputs]="heroInputs"> </kwh-hero>
<kwh-slider *ngIf="isMobile || isTablet" [slideData]='slideData' [slideConfig]='slideConfig' [customClass]='customClass'></kwh-slider>
<kwh-simple-section
  [simpleSectionInputs]="simpleSectionInputs"
>
</kwh-simple-section>
<kwh-image-shadowed-callout
  [imageShadowedCalloutInputs]="imageShadowedCalloutInputs"
>
</kwh-image-shadowed-callout>
<kwh-subheading-gallery-section
  *ngIf="isBrowser"
  [subheadingGallerySectionInputs]="subheadingGallerySectionInputs"
>
</kwh-subheading-gallery-section>
<kwh-callout-with-icon
  [calloutWithIconInputs]="calloutWithIconInputs"
>
</kwh-callout-with-icon>
<kwh-image-shadowed-callout
  [imageShadowedCalloutInputs]="imageShadowedCallout2Inputs"
>
</kwh-image-shadowed-callout>
