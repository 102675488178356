<div id="{{ id }}" class="hero-container">
  <div class="hero-inner-div">
    <kwh-photo-slider
      [photoItems]="photoItems"
      [sliderDimensions]="sliderDimensions"
      marginTop="0px"
      marginBottom="0px"
      [buttonsOnOneSide]="true"
    >
    </kwh-photo-slider>
  </div>
</div>
