<div class="b-a-g-container">
  <div class="b-a-g-inner-div">
    <div
      *ngIf="!isDesktop && !isMobileGalleriesSelectorOpen"
      class="b-a-g-buttons-expander"
      (click)="toggleIsMobileGalleriesSelectorOpen(true)"
    >
      SELECT PHOTO GALLERY
      <img src="{{ S3_URL }}icons/gallery/Plus_Icon_MOBILE.svg" />
    </div>
    <div
      *ngIf="isDesktop || isMobileGalleriesSelectorOpen"
      class="b-a-g-buttons-container"
      (click)="toggleIsMobileGalleriesSelectorOpen(false)"
    >
      <ng-container *ngIf="!isDesktop"
        >Select Photo Gallery
        <img src="{{ S3_URL }}icons/gallery/Minus_Icon_MOBILE.svg"
      /></ng-container>
      <button
        *ngFor="let gallery of galleries; index as i"
        class="{{ selectedGallery.name === gallery.name ? 'selected' : '' }}"
        (click)="selectGallery($event, i)"
      >
        {{ gallery.name }}
      </button>
    </div>
    <kwh-gallery-section
      id="{{ screenName }}-photo-slider"
      [item]="{ images: selectedGallery?.photos || [] }"
    ></kwh-gallery-section>
  </div>
</div>
