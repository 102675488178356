import { HomeType } from '../types/home';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterCondition } from '../types/filters';
import { buildParams } from '../utils/rest-helper.utils';
import { Home, HomeDetails } from '../types/home';
import { List } from '../types/list';
import { getDefaultImage, parseDate } from '../utils/card-helper.utils';
import { PaginatorOptions } from '../types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class HomesService {
  homeDetails$: BehaviorSubject<Home> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getHomes(
    type: string,
    conditions: FilterCondition,
    additionalOptions?: PaginatorOptions
  ): Observable<List<Home[]>> {
    const params = buildParams(conditions);

    return this.http.get<List<Home[]>>(`${environment.url}inventory`, {
      params: { ...params, ...additionalOptions, type },
    });
  }

  getQMIHomesByCommunityId(
    communityId: string,
    conditions?: FilterCondition,
    additionalOptions?: PaginatorOptions
  ): Observable<List<Home[]>> {
    const params = buildParams(conditions);

    return this.http.get<List<Home[]>>(
      `${environment.url}community/${communityId}/move-in-homes`,
      {
        params: { ...params, ...additionalOptions },
      }
    );
  }

  getModelHomesByCommunityId(
    communityId: string,
    conditions?: FilterCondition,
    additionalOptions?: PaginatorOptions
  ): Observable<List<Home[]>> {
    const params = buildParams(conditions);

    return this.http.get<List<Home[]>>(
      `${environment.url}community/${communityId}/models`,
      {
        params: { ...params, ...additionalOptions },
      }
    );
  }

  getHomesByPlanId(
    planId: string,
    communityId: string,
    type: HomeType,
    conditions?: FilterCondition,
    additionalOptions?: PaginatorOptions
  ): Observable<List<Home[]>> {
    const sortConditions = buildParams(conditions);

    return this.http.get<List<Home[]>>(
      `${environment.url}inventory/in-plan/${planId}`,
      {
        params: {
          community: communityId,
          type,
          ...sortConditions,
          ...additionalOptions,
        },
      }
    );
  }

  getHomeById(id: string): Observable<Home> {
    return this.http.get<Home>(`${environment.url}inventory/${id}`);
  }

  getHomeIdBySlug(slug: string): Observable<any> {
    return this.http.get<any>(`${environment.url}inventory/community/by-name`, { params: { community: 'keswick-homes', address: slug }});
  }

  setHome(home: Home): void {
    const homeDetails = this.mapToHomeDetails(home);
    this.homeDetails$.next(homeDetails);
  }

  clearHome(): void {
    this.homeDetails$.next(null);
  }

  private mapToHomeDetails(home: Home): HomeDetails {
    return {
      ...home,
      mainTitle: home.isModel
        ? `${home.planType.name}`
        : `${home.address?.address1} ${home.address?.address2}`,
      squareFootage: home.sqFt.toLocaleString(),
      isSquareFootagePresent: home.sqFt > 0,
      defaultImage: getDefaultImage(home.exteriorImages),
      availableDate: home.availabilityDate && parseDate(home.availabilityDate),
    };
  }
}
