import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { FilterFunction } from 'src/app/types/interfaces';
import { AvailableLand, SalesStatus } from 'src/app/types/land';
import { BreakpointsUtils } from 'src/app/utils/breakpoints-base-class.utils';
import { setComponentInputsFromData } from 'src/app/utils/component-inputs.utils';
import { MapStyling } from 'src/app/utils/map-styling.utils';
import { environment } from 'src/environments/environment';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { MappedLotUnitsLabels } from '../../types/land';
import {
  AvailableLandMapOptions,
  AvailableLandOverviewMapData,
} from './map.constants';

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'kwh-available-land-overview-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class AvailableLandOverviewMapComponent
  extends BreakpointsUtils
  implements OnInit
{
  @ViewChildren('markers', { read: MapMarker }) markers: QueryList<MapMarker>;
  @ViewChildren('infoWindows', { read: MapInfoWindow })
  infoWindows: QueryList<MapInfoWindow>;

  @Input() useNumberedPins: boolean = true;
  @Input() onlyAddressAndDirectionsInTooltip: boolean = false;
  get onlyAddressAndDirectionsOnMobileOverlay(): boolean {
    return this.onlyAddressAndDirectionsInTooltip;
  }

  @Input() items: Array<AvailableLand> = [];
  filteredItems: Array<AvailableLand> = [];
  viewDetailsIcon: string = `${environment.S3_URL}icons/available-land/teal_right_arrow.svg`;
  mapStyles: typeof MapStyling = MapStyling;
  slideConfig: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 0,
    autoplay: false,
  };

  lotUnitsLabelsMap = MappedLotUnitsLabels;

  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService);
  }

  @Input() mapConfig: AvailableLandMapOptions;
  @Input() centerCoordinatesOverride: { lat: number; lng: number } = null;

  ngOnInit(): void {
    setComponentInputsFromData(this, AvailableLandOverviewMapData);
  }
  ngAfterViewInit(): void {
    this.filteredItems = this.items || [];
  }

  applyFilters(predicates: Array<FilterFunction<AvailableLand>>): void {
    let ret = this.items || [];
    for (let predicate of predicates) {
      ret = ret.filter(predicate);
    }
    this.filteredItems = ret;
  }

  pinAtIndex(item: AvailableLand, idx: number): string {
    if (this.useNumberedPins) {
      const available = item.salesStatus === SalesStatus.AVAILABLE;
      return `${environment.S3_URL}icons/available-land/Location_Pin_Icons/${
        available ? 'Available' : 'Sold'
      }/Location_Pin_${idx + 1}.svg`;
    } else {
      return `${environment.S3_URL}icons/available-land/Location_Pin_Icons/Location_Pin_Black_General.svg`;
    }
  }

  addressToSlug(addr: string): string {
    return encodeURIComponent(addr.toLowerCase().replace(/\s+/g, '-'));
  }

  createAddressSlug(item: AvailableLand) {
    const slugs = [
      `${this.addressToSlug(item.address.address1)}`,
      !!item.address.address2
        ? `${this.addressToSlug(item.address.address2)}`
        : '',
      !!item.homesite ? `${this.addressToSlug(item.homesite)}` : '',
    ].filter((str) => str.length > 0);
    return slugs.join('-');
  }

  goTo(item: AvailableLand): void {
    return this.navigationService.navigateTo(
      `/available-land/${this.createAddressSlug(item)}`
    );
  }

  openAddressWindow(item: AvailableLand): void {
    this.closeAddressWindows();
    const anchor = this.markers.find(
      (_, idx) => idx === this.filteredItems.indexOf(item)
    );
    this.infoWindows
      .find((_, idx) => idx === this.filteredItems.indexOf(item))
      ?.open(anchor, true);
  }

  closeAddressWindows(): void {
    this.infoWindows.forEach((w) => w.close());
  }

  public goToGoogleDirections(item: AvailableLand): void {
    const googleLink = `https://www.google.com/maps/dir//${this.createAddressSlug(
      item
    )}+${item.address?.city}+${item.address?.stateLabel}+${item.address?.zip}`;
    this.navigationService.navigateTo(googleLink);
  }
}
