<section id="{{ id }}" class="image-shadowed-callout">
  <div class="image-s-callout-heading">
    <h1 *ngIf="heading">{{ heading }}</h1>
    <p *ngIf="subheading">{{ subheading }}</p>
  </div>
  <div class="image-s-callout-background shading-layover">
    <div class="image-s-callout-content" [style]="contentStyle">
      <h1 *ngIf="heading">{{ heading }}</h1>
      <p *ngIf="subheading" [style]="subheadingStyle">{{ subheading }}</p>
      <div class="image-s-callout-buttons" [style]="buttonContainerStyle">
        <button
          *ngFor="let button of buttons; index as i"
          [style]="buttonStyle"
          (click)="navigateTo(button.link)"
        >
          {{ button.text }}
        </button>
      </div>
    </div>
  </div>
  <div class="image-s-callout-background" [style]="backgroundStyle"></div>
  <div *ngIf="isMobile || isTablet" class="image-s-callout-image">
    <img [src]="mobileImageSrc" />
  </div>
</section>
