import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { Component, Input, OnDestroy } from '@angular/core';
import { ScreenService } from '../../services/screen-size.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SwiperCore, { Pagination } from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'kwh-numbered-slider',
  templateUrl: './numbered-slider.component.html',
  styleUrls: ['./numbered-slider.component.scss'],
})
export class NumberedSliderComponent
  extends BreakpointsUtils
  implements OnDestroy
{
  @Input() slideData: any;
  @Input() slideConfig: any;
  @Input() customClass: string;
  currentIndex = 0;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private screenService: ScreenService) {
    super(screenService);
    this.detectScreenSize();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  detectScreenSize(): void {
    this.screenService.isDesktop$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {});
  }

  onIndexChange(evt): void {
    this.currentIndex = evt;
  }
}
