import { ViewportScroller } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PlatformService } from 'src/app/services/platform.service';

@Injectable({
  providedIn: 'root',
})
export class ScrollService implements OnDestroy {
  anchorScroll$ = new BehaviorSubject(false);
  anchorScrollMap$ = new BehaviorSubject(false);
  scrollFreeze$ = new BehaviorSubject(false);
  navOpaque$ = new BehaviorSubject(false);
  communityIndex$ = new BehaviorSubject<number>(null);
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private platformService: PlatformService
  ) {}

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public scrollTopOnNavigation(): void {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((e) => {
        setTimeout(() => {
          this.scrollToTopInstant();
        }, 0);
      });
  }

  public toggleScrollFreeze(state): void {
    this.scrollFreeze$.next(state);
  }

  public toggleNavOpacity(state): void {
    this.navOpaque$.next(state);
  }

  public scrollToTop(): void {
    if(this.platformService.isBrowser()) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  public scrollToTopInstant(): void {
    if(this.platformService.isBrowser()) {
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }
  }
}
