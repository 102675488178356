import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { AboutModule } from './features/about/about.module';
import { ContactModule } from './features/contact/contact.module';
import { FooterComponent } from './features/footer/footer.component';
import { HeaderComponent } from './features/header/header.component';
import { HomeModule } from './features/home/home.module';
import { CanonicalLinkService } from './services/canonical-link.service';
import { SiteService } from './services/site.service';
import { WINDOW_PROVIDERS } from './services/window.service';

@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent],
  imports: [
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    GoogleMapsModule,
    HttpClientModule,
    ReactiveFormsModule,
    HomeModule,
    SharedModule,
    AboutModule,
    ContactModule,
  ],
  providers: [SiteService, WINDOW_PROVIDERS, CanonicalLinkService],
  bootstrap: [AppComponent],
})
export class AppModule {}
