import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SwiperOptions } from 'swiper';

import { NavigationService } from 'src/app/services/navigation.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { LargeBrandScrollerInputs } from './large-brand-scroller.model';

/**
 * TODO: borrow auto-scroll behavior from hero/reviews and implement here
 */

@Component({
  selector: 'kwh-large-brand-scroller',
  templateUrl: './large-brand-scroller.component.html',
  styleUrls: ['./large-brand-scroller.component.scss'],
})
export class LargeBrandScrollerComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  public itemsPerPage: number = 5;
  // If you change this, change in CSS also
  public squishedBreakpoint: number = 1100;
  public itemsPerPageSquished: number = 4;
  public itemsPerPageToUse: number = 5;

  @Input() largeBrandScrollerInputs: LargeBrandScrollerInputs;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() id: string;
  @Input() footerLine: string;
  @Input() imageSrc: string;
  @Input() items = []; //TODO: add types and models
  customClass: string = 'brand-mobile-slider';
  slideConfig: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 0,
    autoplay: false,
  };
  slideData: any[] = [];

  public itemsSortedForFooter = []; //TODO: add types and models

  // value of 1 indicates selected page
  scrollPages: number[] = [0];

  ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'largeBrandScrollerInputs');
    this.slideData = this.largeBrandScrollerInputs.items;
    this.resolveScrollDotCount();
    this.sortItemsForFooter();
    this.getItemsPerPageToUse();
    this.aBreakpointWasCrossed$.subscribe(() => {
      this.getItemsPerPageToUse();
    });
  }

  public getItemsPerPageToUse(): void {
    this.itemsPerPageToUse =
      window.innerWidth < this.squishedBreakpoint
        ? this.itemsPerPageSquished
        : this.itemsPerPage;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.sortItemsForFooter();
    }
  }

  public sortItemsForFooter(): void {
    this.itemsSortedForFooter = [...this.items].sort(
      (a, b) => a.footerSequence - b.footerSequence
    );
  }

  public resolveScrollDotCount(): void {
    // dynamically determine how many pages
    let lengthOfPages = 0;
    this.items.forEach((item, itemIndex) => {
      if (!((itemIndex + 1) % 5)) {
        lengthOfPages = (itemIndex + 1) / 5;
      } else if (itemIndex + 1 === this.items.length) {
        lengthOfPages += 1;
      }
    });
    const newScrollPages = [...new Array(lengthOfPages)].fill(0);
    // Select first page
    newScrollPages[0] = 1;
    this.scrollPages = newScrollPages;
  }

  public goToPage(index: number): void {
    this.scrollPages = this.scrollPages.map((_, indexInList) =>
      indexInList === index ? 1 : 0
    );
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
