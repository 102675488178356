import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointsUtils } from 'src/app/utils/breakpoints-base-class.utils';
import { ScreenService } from './../../services/screen-size.service';

import { NavigationService } from 'src/app/services/navigation.service';
@Component({
  selector: 'kwh-menu-modal',
  templateUrl: './menu-modal.component.html',
  styleUrls: ['./menu-modal.component.scss'],
})
export class MenuModalComponent extends BreakpointsUtils implements OnInit {
  // showAbout = false;
  // showResources = false;
  // showHome = false;

  constructor(
    private dialogRef: MatDialogRef<MenuModalComponent>,
    private router: Router,
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  ngOnInit(): void {
    this.listenForNavChange();
  }

  close(): void {
    this.dialogRef.close();
  }

  listenForNavChange(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.close();
      }
    });
  }

  public navigateTo(link: string): void {
    this.close();
    this.navigationService.navigateTo(link);
  }

  // toggleDropdown(type): void {
  //   if (type === 'about') {
  //     this.showAbout = !this.showAbout;
  //     const myHandler = () => {
  //       setTimeout(() => {
  //         this.showAbout = false;
  //         document.body.removeEventListener('click', myHandler, true);
  //       }, 50);
  //     };
  //     if (this.showAbout === true) {
  //       document.body.addEventListener('click', myHandler, true);
  //     }
  //   } else if (type === 'findHome') {
  //     this.showHome = !this.showHome;
  //     const myHandler = () => {
  //       setTimeout(() => {
  //         this.showHome = false;
  //         document.body.removeEventListener('click', myHandler, true);
  //       }, 50);
  //     };
  //     if (this.showHome === true) {
  //       document.body.addEventListener('click', myHandler, true);
  //     }
  //   } else {
  //     this.showResources = !this.showResources;
  //     const myHandler = () => {
  //       setTimeout(() => {
  //         this.showResources = false;
  //         document.body.removeEventListener('click', myHandler, true);
  //       }, 50);
  //     };
  //     if (this.showResources === true) {
  //       document.body.addEventListener('click', myHandler, true);
  //     }
  //   }
  // }
}
