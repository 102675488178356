<section id="{{ id }}" class="i-boxes-callout" [style]="containerStyle">
  <div class="i-boxes-callout-background">
    <div class="i-boxes-callout-content" [style]="contentStyle">
      <h1 *ngIf="heading" [style]="h1Style">
        {{ heading }}
      </h1>
      <p *ngIf="subheading" [style]="subheadingStyle">{{ subheading }}</p>
      <div
        class="i-boxes-callout-items-container"
        [style]="items?.length === 1 ? 'justify-content: center;' : ''"
      >
        <div *ngFor="let item of items" class="i-boxes-callout-item">
          <div class="i-boxes-callout-item-image-container">
            <img class="i-boxes-callout-item-image" src="{{ item.imageSrc }}" />
          </div>
          <div class="i-boxes-callout-item-content-container">
            <div class="i-boxes-callout-item-heading">{{ item.heading }}</div>
            <div class="i-boxes-callout-item-line line-1">
              {{ item.textLine1 }}
            </div>
            <div class="i-boxes-callout-item-line line-2">
              {{ item.textLine2 }}
            </div>
            <div class="i-boxes-callout-item-link-container">
              <div
                (click)="navigateTo(item.link)"
                class="i-boxes-callout-item-link"
              >
                <div class="i-boxes-callout-item-link-text">
                  {{ item.linkText }}
                </div>
                <img
                  src="{{ S3_URL }}icons/home/Right_Arrow_Black_noborder.svg"
                  *ngIf="isDesktop"
                />
                <img
                  src="{{ S3_URL }}icons/right_arrow_white.svg"
                  *ngIf="isMobile || isTablet"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
