import { Location, ViewportScroller } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private location: Location
  ) {}

  public navigateTo(link: string): void {
    if (!link) return;
    /**
     * Anchors. Account for header if possible.
     */
    if (link.startsWith('#')) {
      const elementVerticalPosition = (
        document.querySelector(link) as HTMLElement
      )?.offsetTop;
      const headerHeight = document.querySelector('header')?.offsetHeight;
      if (elementVerticalPosition != null && headerHeight != null) {
        scrollTo({
          left: 0,
          top: elementVerticalPosition - headerHeight,
          behavior: 'smooth',
        });
      } else {
        const element = document.querySelector(link);
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
      /**
       * Internal relative URL
       */
    } else if (link.endsWith('.pdf')) {
      //TODO: any other downloadable file types?
      const contrivedClickable = document.createElement('a');
      contrivedClickable.href = link;
      contrivedClickable.target = '_blank'; // new tab
      contrivedClickable.click();
      contrivedClickable.remove();
    } else if (!link.startsWith('http')) {
      this.router.navigateByUrl(link);
      /**
       * External URLs
       */
    } else {
      window.open(link, '_blank');
    }
  }

  public goBack(): void {
    this.location.back();
  }
}
