import { environment } from './../../environments/environment';
export const LocationsMapData = [
  {
    property: 'simpleSectionInputs',
    inputs: {
      heading: 'Where to',
      headingSecondLine: 'Find Us',
      subheading:
        "We've got offices in Virginia and Maryland, and we've built homes throughout the D.C. area and beyond. If you're looking to build anywhere in Virginia, Maryland or D.C., we can make your dream home a reality. ",
      id: 'where-to-find-us',
    },
  },
  {
    property: 'locationsMapInputs',
    /**
     * TODO: add inputs for everything else we need on the map. We are
     * moving on but we will need to come back and work on this.
     */
    inputs: {
      mapZoom: 9.8,
      mapZoomForMobile: 8,
      mapCenterLat: 38.978932819003965,
      mapCenterLng: -76.9198139768238,
      legendLat: 39.235717,
      legendLng: -77.541812,
      items: [
        {
          iconSrc: `${environment.S3_URL}icons/about/Office_Location_Icon.svg`,
          address: '11022 Willow Bottom Drive, Columbia, MD 21044',
          clickable: true,
          showInModal: true,
          modalTitle: 'MARYLAND OFFICE',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/Office_Location_Icon.svg`,
          address:
            '8280 Willow Oaks Corporate Drive, Suite 600, Fairfax, VA 22031',
          clickable: true,
          showInModal: true,
          modalTitle: 'VIRGINIA OFFICE',
        },
        {
          showInModal: true,
          modalTitle: 'PHONE',
          modalContent: '866.819.9990',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '11285 Creswell Landing, Lorton, VA 22079',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '37125 Decon Wick Lane, Purcellville, VA 20132',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '8624 Polk Street, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1311 Kirby Road, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1307 Kirby Road, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '879 Spring Hill Road, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '7020 Mountain Gate, Bethesda, MD, 20817',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '8747 Book Road, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: "9410 Boxman Neavitt Road, St. Michael's, MD, 21663",
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: "8831 Dawson Road, St. Michael's, MD, 21663",
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: "104 Marea Terrace, St. Michael's, MD, 21663",
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '6838 Georgetown Pike, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '11285 Cresswell Landing, Lorton, VA, 22079',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '11218 Kinsale Court, Ellicot City, MD, 21042',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1140 Bellview Road, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '472 River Ben Road, Great Falls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1001 Savile Lane, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '9801 Beach Mill Road, Great Falls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '2087 Grace manor Court, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1023 Eaton Drive, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '2031 Virgina ave, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '040-A Brook Valley Lane, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '12515 Knollbrook Drive, Clifton, VA, 20124',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '2800 Hill Road, Vienna, VA, 22181',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '623 Welles Stree, Vienna, VA, 22181',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '332 Beulah Road,, Vienna, VA, 22182',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '10026 Hyla Brook, Columbia, MD, 21044',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '5200 Tre Towers Court, Centreville, VA, 20120',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1018 Eaton Drive, McLean, VA, 22102',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '10108 Pasture Gate Lane, Columbia, MD, 21044',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1014 Eaton Drive, McLean, VA, 22102',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '10300 Hampton Road, Fairfax Station, VA, 22039',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '9110 Dara Lane, Great Galls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '9108 Dara Lane, Great Galls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '9111 Dara Lane, Great Galls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '10464 Springvale Meadows Lane, Great Galls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '18684 Riverlook Court, Lansdowne, VA, 20176',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '8720 Standish Road, Alexandria, VA, 22308',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '901 Turkey Run Road, McLean, VA, 22101',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '7212 Byrneley Lane, Annandale, VA, 22003',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '7912 Jansen Drive, Springfield, VA, 22152',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '3020 Falls Run Road, McLean, VA, 22102',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '1889 Virgina Ave, McLean, VA, 22102',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '10616 Good Spring Ave, Great Falls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '7601 Dunquin Court, Clifton, VA, 20124',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '8751 mountain Valley Road, Fairfax, VA, 22039',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '6019 Foxhall Farm Road, Catonsville, MD, 21228',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '8624 Polk Street, McLean, VA, 22102',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '11710 Janney Court, Fulton, MD, 20759',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '11205 Gunston Road, Lorton, VA, 22079',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '9998 Blackberry Lane, Great Galls, VA, 22066',
        },
        {
          iconSrc: `${environment.S3_URL}icons/about/where_we_built_icon.svg`,
          address: '6510 Newman Road, Clifton, VA, 20124',
        },
      ],
    },
  },
];
