<div
  id="{{ id }}"
  class="sh-gallery-section-container"
  [style]="containerStyle"
>
  <div class="sh-gallery-section">
    <div
      class="sh-gallery-nav-button left"
      (click)="goToItem(currentSubheadingGalleryItemIndex - 1)"
    >
      <img *ngIf="isDesktop" src="{{ leftButtonSrc }}" />
      <img *ngIf="isMobile || isTablet" src="{{ mobileLeftButtonSrc }}" />
    </div>
    <h2 [style]="headingStyle">{{ heading }}</h2>
    <div
      class="sh-gallery-section-subheading-item-container"
      [style]="
        isDesktop
          ? (useMinWidth() ? 'min-width: ' : 'width: ') + subheadingWidthToUse
          : (useMinWidth() ? 'min-width: ' : 'width: ') +
            mobileSubheadingWidthToUse
      "
    >
      <p
        *ngFor="
          let subheadingGalleryItem of subheadingGalleryItems || [];
          index as i
        "
        class="sh-gallery-section-subheading-item"
        [style]="galleryItemsStyle"
      >
        {{ subheadingGalleryItem?.text }}
      </p>
    </div>
    <div
      class="sh-gallery-nav-button right"
      (click)="goToItem(currentSubheadingGalleryItemIndex + 1)"
    >
      <img *ngIf="isDesktop" src="{{ rightButtonSrc }}" />
      <img *ngIf="isMobile || isTablet" src="{{ mobileRightButtonSrc }}" />
    </div>
    <div class="sh-gallery-center-icon-container">
      <img class="sh-gallery-center-icon" src="{{ centerIconSrc }}" />
    </div>
    <div class="sh-gallery-bottom-caption-container">
      <div class="sh-gallery-bottom-caption-line-1">
        {{
          subheadingGalleryItems[currentSubheadingGalleryItemIndex]
            ?.bottomCaptionLine1
        }}
      </div>
      <div class="sh-gallery-bottom-caption-line-2">
        {{
          subheadingGalleryItems[currentSubheadingGalleryItemIndex]
            ?.bottomCaptionLine2
        }}
      </div>
    </div>
  </div>
</div>
