import { Component, Input, OnInit } from '@angular/core';

import { InfoSectionWithImageInputs } from './info-section-with-image.models';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { ScreenService } from './../../services/screen-size.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'kwh-info-section-with-image',
  templateUrl: './info-section-with-image.component.html',
  styleUrls: ['./info-section-with-image.component.scss'],
})
export class InfoSectionWithImageComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService);
  }

  @Input() infoSectionWithImageInputs: InfoSectionWithImageInputs;
  @Input() id: string;
  @Input() imagePlacement: 'left' | 'right';
  @Input() heading: string;
  @Input() paragraph: string;
  @Input() imageSrc: string;
  @Input() buttons; //TODO: use interface

  ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'infoSectionWithImageInputs');
  }

  navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
