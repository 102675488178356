<div id="{{ id }}" class="simple-section-container" [style]="containerStyle">
  <div class="simple-section">
    <h2 [style]="headingStyle">
      {{ heading
      }}<ng-container *ngIf="headingSecondLine"
        ><br />{{ headingSecondLine }}</ng-container
      >
    </h2>
    <p
      class="simple-section-subheading"
      [style]="subheadingWidth ? 'max-width: ' + subheadingWidth : ''"
    >
      {{ subheading }}
    </p>
  </div>
</div>
