import { Component, Input, OnInit } from '@angular/core';

import { NavigationService } from 'src/app/services/navigation.service';
import { BreakpointsUtils } from '../../utils/breakpoints-base-class.utils';
import { ScreenService } from '../../services/screen-size.service';
import {
  ImageBoxesCalloutInputs,
  ImageBoxesCalloutItem,
} from './image-boxes-callout.models';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';

@Component({
  selector: 'kwh-image-boxes-callout',
  templateUrl: './image-boxes-callout.component.html',
  styleUrls: ['./image-boxes-callout.component.scss'],
})
export class ImageBoxesCalloutComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  @Input() public imageBoxesCalloutInputs: ImageBoxesCalloutInputs;
  @Input() public id: string;
  @Input() public heading: string = null;
  @Input() public subheading: string = null;
  @Input() public subheadingWidth: string;
  @Input() public verticalOffset: number;
  @Input() public backgroundColor: 'white' | 'offwhite' | 'black' = 'white';
  @Input() public items: ImageBoxesCalloutItem[];

  public containerStyle: string;
  public h1Style: string;
  public contentStyle: string;
  public subheadingStyle: string;

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'imageBoxesCalloutInputs');
    this.setHtmlAttributes();
  }

  private setHtmlAttributes(): void {
    this.contentStyle =
      this.verticalOffset != null
        ? `position: relative; top: ${this.verticalOffset}px;`
        : null;
    let subheadingStyle = this.subheadingWidth
      ? `max-width: ${this.subheadingWidth};`
      : '';
    if (this.isDesktop) {
      if (this.backgroundColor === 'black')
        subheadingStyle += ' color: var(--kwh-white);';
      this.subheadingStyle = subheadingStyle;
      this.containerStyle =
        this.backgroundColor === 'white'
          ? 'background: var(--kwh-white);'
          : this.backgroundColor === 'black'
          ? 'background: var(--kwh-black);'
          : '';
      this.h1Style =
        this.backgroundColor === 'black' ? 'color: var(--kwh-white);' : '';
    } else {
      if (this.backgroundColor === 'black')
        subheadingStyle += ' color: var(--kwh-white);';
      this.subheadingStyle = subheadingStyle;

      this.containerStyle =
        this.backgroundColor === 'white'
          ? 'background: var(--kwh-white);'
          : this.backgroundColor === 'black'
          ? 'background: var(--kwh-black);'
          : '';
      this.h1Style =
        this.backgroundColor === 'black' ? 'color: var(--kwh-white);' : '';
    }
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
