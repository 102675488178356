import { Component, Input, OnInit } from '@angular/core';

import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { NavigationService } from './../../services/navigation.service';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import {
  CompanyContactBoxesSectionInputs,
  CompanyContactBoxesSectionItem,
} from './company-contact-boxes-section.models';

@Component({
  selector: 'kwh-company-contact-boxes-section',
  templateUrl: './company-contact-boxes-section.component.html',
  styleUrls: ['./company-contact-boxes-section.component.scss'],
})
export class CompanyContactBoxesSectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  @Input() companyContactBoxesSectionInputs: CompanyContactBoxesSectionInputs;
  @Input() id: string;
  @Input() heading: string;
  @Input() subheading1: string;
  @Input() subheading2: string;
  @Input() items: CompanyContactBoxesSectionItem[]; //TODO: add types and models

  ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'companyContactBoxesSectionInputs');
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
