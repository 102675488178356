import { CommunitySalesManager } from './../types/community';
import { Community, CommunityCard } from '../types/community';
import { Home, HomeMapData, ModelHomeCard, QMIHomeCard } from '../types/home';
import { Image } from '../types/image';
import { FullScreenImage } from '../types/interfaces';
import { Plan, PlanCard } from '../types/plan';

export const getMinMaxLabel = (
  minCount: number,
  maxCount: number,
  format?: boolean
): string => {
  if (!minCount || !maxCount) {
    return '';
  }

  const minEqualsMax = minCount === maxCount;
  return minEqualsMax
    ? `${format ? minCount.toLocaleString() : minCount}`
    : `${format ? minCount.toLocaleString() : minCount} - ${
        format ? maxCount.toLocaleString() : maxCount
      }`;
};

export const mapCommunityDataToCardData = (
  community: Community
): CommunityCard => {
  return {
    id: community.id,
    name: community.name,
    brand: community.brand,
    planTypes: community.planTypes.map((pt) => pt.label).join(', '),
    city: community.address?.city,
    state: community.address?.stateName,
    priceFrom: community.priceFrom,
    bedsMinMax: getMinMaxLabel(community.bedsMin, community.bedsMax),
    bathsFullMinMax: getMinMaxLabel(
      community.bathsFullMin,
      community.bathsFullMax
    ),
    sqFtMinMax: getMinMaxLabel(community.sqFtMin, community.sqFtMax, true),
    garageMinMax: getMinMaxLabel(community.garageMin, community.garageMax),
    availability: community.communityAvailabilityRef,
    position: new google.maps.LatLng(
      community.address?.latitude,
      community.address?.longitude
    ),
    carouselImages: mapImages(community.images),
    defaultImage: getDefaultImage(community.images),
    modelHomesCount: community.modelHomesCount,
    moveInHomesCount: community.moveInNowHomesCount,
    plansCount: community.plansCount,
    openingDate: community.openingDate,
    externalId: community.externalId,
    divisionExternalId: community.divisionExternalId,
  };
};

export const mapPlanDataToCardData = (plan: Plan): PlanCard => {
  return {
    id: plan.id,
    name: plan.name,
    brand: plan.brand,
    planType: plan.planType ? plan.planType.label : '',
    basePrice: plan.basePrice,
    bedsMinMax: getMinMaxLabel(plan.bedsMin, plan.bedsMax),
    bathsFullMinMax: getMinMaxLabel(plan.bathsFullMin, plan.bathsFullMax),
    sqFtMinMax: getMinMaxLabel(plan.sqFtMin, plan.sqFtMax, true),
    garageSpaces: plan.garageSpaces,
    isTemplate: plan.isTemplate,
    carouselImages: [
      ...mapImages(plan.elevationImages),
      ...mapImages(plan.interiorImages),
    ],
    defaultElevationImage: getDefaultImage([
      ...plan.elevationImages.sort((a, b) => a.sequence - b.sequence),
      ...plan.interiorImages.sort((a, b) => a.sequence - b.sequence),
    ]),
    availableLocations: plan.availableLocations,
  };
};

export const mapQMIHomeCardToData = (home: Home): QMIHomeCard => {
  return {
    id: home.id,
    brand: home.brand,
    addressFirst: home.address?.address1,
    addressSecond: home.address?.address2,
    community: home.community?.name,
    state: home.address?.stateName,
    city: home.address?.city,
    price: home.price,
    beds: home.beds,
    baths: home.fullBaths,
    sqFt: home.sqFt,
    garageSpaces: home.garageSpaces,
    availableDate: home.availabilityDate && parseDate(home.availabilityDate),
    position: new google.maps.LatLng(
      home.address?.latitude,
      home.address?.longitude
    ),
    carouselImages: mapImages(home.exteriorImages),
    defaultImage: getDefaultImage(home.exteriorImages),
    homesite: home.homesite,
  };
};

export const mapModelHomeCardToData = (home: Home): ModelHomeCard => {
  return {
    id: home.id,
    brand: home.brand,
    name: 'Model Name',
    planType: home.planType ? home.planType.label : '',
    community: home.community?.name,
    price: home.price,
    beds: home.beds,
    baths: home.fullBaths,
    sqFt: home.sqFt,
    garageSpaces: home.garageSpaces,
    position: new google.maps.LatLng(
      home.address?.latitude,
      home.address?.longitude
    ),
    carouselImages: mapImages(home.exteriorImages),
    defaultImage: getDefaultImage(home.exteriorImages),
    homesite: home.homesite,
  };
};

export const getHomeDataToMap = (home: Home): HomeMapData => {
  return {
    id: home.id,
    brand: home.brand,
    name: home.planType ? home.planType.label : '',
    community: home.community?.name,
    address1: home.address?.address1,
    address2: home.address?.address2,
    city: home.address?.city,
    state: home.address?.stateName,
    priceFrom: home.price,
    position: new google.maps.LatLng(
      home.address?.latitude,
      home.address?.longitude
    ),
    defaultImage: getDefaultImage(home.exteriorImages),
  };
};

export const parseDate = (date: Date): string => {
  const newDate = new Date(date);
  const year = newDate.toLocaleString('en-EN', { year: 'numeric' });
  const month = newDate.toLocaleString('en-EN', { month: 'long' });

  return `${month} ${year}`;
};

const mapImages = (images: Image[]): FullScreenImage[] => {
  return images
    .sort((a, b) => a.sequence - b.sequence)
    .map((image) => ({
      path: image.url,
      caption: image.caption,
      sequence: image.sequence,
    }));
};

export const getDefaultImage = (
  images: Image[],
  imagesAreSorted: boolean = false
): string => {
  const sortedImages = imagesAreSorted
    ? images
    : images.sort((a, b) => a.sequence - b.sequence);

  const defaultImages = sortedImages.filter(
    (image) => image.isPreferredDefault
  );

  if (defaultImages.length) {
    return defaultImages[0].url;
  }

  return sortedImages.length ? images[0].url : '';
};

export const getMainOSCConsultant = (
  salesManagers: CommunitySalesManager[]
): CommunitySalesManager => {
  const oscConsultants = salesManagers.filter(
    (sm) => sm.type === 'osc' && sm.status === 'active'
  );

  return oscConsultants[0];
};
