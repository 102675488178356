<swiper [config]="slideConfig">
  <div class="swiper-wrapper">
    <div *ngFor="let slide of slideData; let i = index" class="swiper-slide">
      <div>
        <img
          src="{{ slide?.imageSrc }}"
          alt="{{ slide?.nameForSectionFooter }}"
        />
        <a href="{{ slide?.href }}" class="btn btn-primary"
          >Learn More
          <img
            src="{{ S3_URL }}icons/about/learn_more_right_arrow.svg"
            class="arrow"
        /></a>
      </div>
    </div>
  </div>
  <div class="swiper-pagination"></div>
</swiper>
