<div *ngIf="isDesktop" id="{{ id }}" class="l-b-scroller-container">
  <div class="l-b-scroller-background-image shading-layover">
    <h3 class="l-b-scroller-heading">{{ heading }}</h3>
    <p class="l-b-scroller-subheading">{{ subheading }}</p>
    <div class="l-b-scroller-scroll-container">
      <ng-container *ngFor="let item of items; index as i">
        <div
          *ngIf="item.imageSrc"
          class="l-b-scroller-item"
          [style]="
            'left: -' + scrollPages.indexOf(1) * itemsPerPageToUse * 211 + 'px;'
          "
        >
          <img (click)="navigateTo(item.href)" src="{{ item.imageSrc }}" />
        </div>
      </ng-container>
    </div>
    <div class="l-b-scroller-scroll-dots">
      <div
        *ngFor="let page of scrollPages; index as i"
        class="l-b-scroller-scroll-dot {{ page === 1 ? 'selected' : '' }}"
        (click)="goToPage(i)"
      ></div>
    </div>
    <div class="l-b-scroller-footer">
      <p class="l-b-scroller-footer-line">{{ footerLine }}</p>
      <p class="l-b-scroller-footer-items">
        <ng-container *ngFor="let item of itemsSortedForFooter; index as i">
          <span>{{ i === 0 ? "" : "&nbsp;&nbsp;|&nbsp;&nbsp;" }}</span>
          <span
            (click)="navigateTo(item.href)"
            class="l-b-scroller-footer-item"
            >{{ item.nameForSectionFooter }}</span
          >
        </ng-container>
      </p>
    </div>
  </div>
  <div
    class="l-b-scroller-background-image"
    [style]="'background-image: url(' + imageSrc + ')'"
  ></div>
</div>

<div
  *ngIf="isMobile || isTablet"
  id="{{ id }}"
  class="l-b-scroller-container"
  [style.background]="
    'linear-gradient(0deg, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(' +
    S3_URL +
    'images/about/Trade_Partners_BG_Cropped_Mobile_2x.jpg)'
  "
>
  <div class="l-b-scroller-wrapper">
    <h2>Our Trade Partners</h2>
    <p>
      Keswick Homes teams with only the most respected and talented partners to
      ensure everything in your home meets our high standards — and yours.
    </p>
    <kwh-brand-slider
      *ngIf="isMobile || isTablet"
      [slideData]="slideData"
      [slideConfig]="slideConfig"
      [customClass]="customClass"
    ></kwh-brand-slider>
  </div>
</div>
