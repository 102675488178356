import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, ActivatedRoute } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';

import { ScreenService } from './services/screen-size.service';
import { ScrollService } from './services/scroll.service';
import { BreakpointsUtils } from './utils/breakpoints-base-class.utils';
import { PlatformService } from './services/platform.service';
import { SeoHelperService } from 'src/app/services/seo-helper.service';
import { PlansService } from './services/plans.service';
import { HomesService } from './services/homes.service';
import { SEO_DATA } from 'src/app/data/seo.data';

// import { swapComponent } from './animations';

@Component({
  selector: 'kwh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   swapComponent
  // ]
})
export class AppComponent extends BreakpointsUtils implements OnInit {
  title = 'Keswick Homes';

  private static HEADER_TRANSPARENCY_BREAKPOINT_PX = 50;

  public includeHiddenHeader: boolean = false;
  public headerIsTransparent: boolean =
    this.isMobile || this.isTablet ? false : true;

  constructor(
    private screenService: ScreenService,
    private scrollService: ScrollService, // private contexts: ChildrenOutletContexts
    private router: Router,
    private viewportScroller: ViewportScroller,
    private platformService: PlatformService,
    private seoHelperService: SeoHelperService,
    private plansService: PlansService,
    private homesService: HomesService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private doc: Document
  ) {
    super(screenService);
    this.listenForNavEnd();
  }

  public ngOnInit(): void {
    if(this.platformService.isBrowser()) {
      this.addS3Styles();
      this.handleHiddenHeaderForRoutes();
      this.listenForScroll();
      this.aBreakpointWasCrossed$.subscribe(() => {
        const includeHiddenHeader = this.isHomepage(this.router.url)
          ? this.isMobile || this.isTablet
          : true;
        if (this.includeHiddenHeader !== includeHiddenHeader)
          this.includeHiddenHeader = includeHiddenHeader;
      });
    }
  }

  private addS3Styles() {
    let styleElem = this.doc.head.appendChild(document.createElement('style'));
    styleElem.innerHTML = `
      .mat-select-arrow {
        background-image: url(${this.S3_URL}icons/dropdown_arrow_up.svg) !important;
      }
      .front,.header {
        .search {
          .mat-form-field {
            .mat-form-field-wrapper {
              .mat-form-field-infix {
                &:after {
                  content: url("${this.S3_URL}icons/icon_wt_search.svg");
                }
              }
            }
          }
        }
      }
      .gm-style {
        .gm-style-iw-t {
          .gm-ui-hover-effect {
            img {
              content: url("${this.S3_URL}icons/icon_close.svg");
            }
          }
        }
      }
    `;
  }

  public handleScroll(): void {
    if (
      this.isHomepage(window.location.pathname) &&
      !(this.isMobile || this.isTablet)
    ) {
      const scrollPosition = this.viewportScroller.getScrollPosition();
      const verticalScrollPos = scrollPosition?.[1];
      this.headerIsTransparent =
        verticalScrollPos < AppComponent.HEADER_TRANSPARENCY_BREAKPOINT_PX;
    }
  }

  private listenForScroll(): void {
    document.addEventListener('scroll', () => {
      this.handleScroll();
    });
  }

  private isHomepage(url: string): boolean {
    return ['', '/'].includes(url);
  }

  private handleHiddenHeaderForRoutes(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const includeHiddenHeader = this.isHomepage(event.url)
          ? this.isMobile || this.isTablet
          : true;
        if (this.includeHiddenHeader !== includeHiddenHeader)
          this.includeHiddenHeader = includeHiddenHeader;
      });
  }

  private listenForNavEnd(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.manipulatePageMetadata(event);
      }
    });
  }

  private manipulatePageMetadata(event: NavigationEnd): void {
    const topLevelRoute = this.activatedRoute?.firstChild;
    switch (topLevelRoute.routeConfig.path) {
      case '': {
        const title = SEO_DATA.home.title;
        const description = SEO_DATA.home.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'about': {
        const title = SEO_DATA.about.title;
        const description = SEO_DATA.about.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'referral': {
        const title = SEO_DATA.referral.title;
        const description = SEO_DATA.referral.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'financing': {
        const title = SEO_DATA.financing.title;
        const description = SEO_DATA.financing.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'realtors': {
        const title = SEO_DATA.realtors.title;
        const description = SEO_DATA.realtors.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'home-stories': {
        const title = SEO_DATA['home-stories'].title;
        const description = SEO_DATA['home-stories'].description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'contact': {
        const title = SEO_DATA.contact.title;
        const description = SEO_DATA.contact.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'build-your-home': {
        const title = SEO_DATA['build-your-home'].title;
        const description = SEO_DATA['build-your-home'].description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'gallery': {
        const title = SEO_DATA.gallery.title;
        const description = SEO_DATA.gallery.description;
        this.seoHelperService.setPageData(title, description, event);
        break;
      }
      case 'home-design': {
        const homesPageRoute = topLevelRoute?.firstChild;
        switch (homesPageRoute.routeConfig.path) {
          case '': {
            const homesSubPageRoute = homesPageRoute?.firstChild;
            switch (homesSubPageRoute?.routeConfig?.path) {
              default:
              case '': {
                const title = SEO_DATA.portfolio.title;
                const description = SEO_DATA.portfolio.description;
                this.seoHelperService.setPageData(title, description, event);
                break;
              }
              case ':item': {
                const { item } = homesSubPageRoute.snapshot.params;
                this.plansService.getPlanIdByName(item)
                  .pipe(
                    filter(value => !!value),
                    take(1)
                  ).subscribe(planId => {
                    this.plansService.getPlanById(planId.id)
                      .pipe(
                        filter(value => !!value),
                        take(1)
                      )
                      .subscribe(plan => {
                        const title = `Home Design Portfolio | Keswick Homes | ${plan.expandedTitle}`;
                        const description = plan.summary;
                        this.seoHelperService.setPageData(title, description, event);
                      });
                  });
                break;
              }
            }
            break;   
          }
        }
        break;
      }
      case 'available-land': {
        const landPageRoute = topLevelRoute?.firstChild;
        switch (landPageRoute.routeConfig.path) {
          case '': {
            const landSubPageRoute = landPageRoute?.firstChild;
            switch (landSubPageRoute?.routeConfig?.path) {
              default: 
              case '': {
                const title = SEO_DATA['available-land'].title;
                const description = SEO_DATA['available-land'].description;
                this.seoHelperService.setPageData(title, description, event);
                break;
              }
              case ':item': {
                const { item } = landSubPageRoute.snapshot.params;
                this.homesService.getHomeIdBySlug(item)
                  .pipe(
                    filter(value => !!value),
                    take(1)
                  ).subscribe(homeId => {
                    this.homesService.getHomeById(homeId.id)
                      .pipe(
                        filter(value => !!value),
                        take(1)
                      )
                      .subscribe(home => {
                        const title = `Available Land | Keswick Homes | Homesite ${home.homesite}`;
                        const description = home.marketingDescription;
                        this.seoHelperService.setPageData(title, description, event);
                      });
                  });
                break;
              }
            }
            break;   
          }
        }
        break;
      }
    }
  }

  ngAfterViewInit() {
    this.scrollService.scrollTopOnNavigation();
  }

  onRouterActivate(event: any) {
    // console.log(event);
  }
}
