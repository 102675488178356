import { Component, Input, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

import { NumberedInfoSectionInputs } from './numbered-info-section.models';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';

@Component({
  selector: 'kwh-numbered-info-section',
  templateUrl: './numbered-info-section.component.html',
  styleUrls: ['./numbered-info-section.component.scss'],
})
export class NumberedInfoSectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  @Input() numberedInfoSectionInputs: NumberedInfoSectionInputs;
  @Input() id: string;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() items: any[] = []; //TODO: add types and models
  customClass: string = 'about-process-slider';
  slideConfig: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 0,
    autoplay: false,
  };
  slideData: any[];
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'numberedInfoSectionInputs');
    this.slideData = this.items
  }
}
