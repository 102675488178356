<header
  [class]="
    'header' +
    (hidden ? '' : ' fixed-header ') +
    (transparent ? 'transparent' : '')
  "
>
  <nav class="navigation">
    <div class="logo" routerLink="/">
      <img src="{{ S3_URL }}logos/KeswickLogoBlack.svg" alt="Keswick Logo" />
    </div>
    <div class="nav-links">
      <ul class="top">
        <li class="link about">
          <a routerLinkActive="active" routerLink="/about"> About Us </a>
        </li>
        <li class="link referral">
          <a routerLinkActive="active" routerLink="/referral">
            Referral Rewards
          </a>
        </li>
        <li class="link financing">
          <a routerLinkActive="active" routerLink="/financing"> Financing </a>
        </li>
        <!--TODO: When uncommenting these, give them a class and apply the right sizing so that bolding doesn't move things around-->
        <!-- <li class="link">
          <a routerLinkActive="active" routerLink="/realtors"> For Realtors </a>
        </li> -->
        <!-- <li class="link">
          <a routerLinkActive="active" routerLink="/home-stories">
            Home Stories
          </a>
        </li> -->
        <li class="link contact">
          <a routerLinkActive="active" routerLink="/contact"> Contact Us </a>
        </li>
      </ul>
      <ul class="bottom">
        <li class="link dream-home">
          <a routerLinkActive="active" routerLink="/build-your-home">
            Build Your Dream Home
          </a>
        </li>
        <li class="link gallery">
          <a routerLinkActive="active" routerLink="/gallery"> Gallery </a>
        </li>
        <li class="link home-design">
          <a routerLinkActive="active" routerLink="/home-design">
            Home Design Portfolio
          </a>
        </li>
        <!--TODO: When uncommenting these, give them a class and apply the right sizing so that bolding doesn't move things around-->
        <!-- <li class="link">
          <a routerLinkActive="active" routerLink="/available-land">
            Available Land
          </a>
        </li> -->
      </ul>
    </div>
    <img
      (click)="openModal()"
      class="menu"
      src="{{ S3_URL }}icons/MenuIcon.svg"
      alt="Mobile Menu Hamburger Icon"
    />
  </nav>
</header>
