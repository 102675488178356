import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { NavigationService } from 'src/app/services/navigation.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { escapeCharactersForCss } from './../../utils/css-utils';
import { ThinHeroInputs } from './thin-hero.models';

@Component({
  selector: 'kwh-thin-hero',
  templateUrl: './thin-hero.component.html',
  styleUrls: ['./thin-hero.component.scss'],
})
export class ThinHeroComponent extends BreakpointsUtils implements OnInit {
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  @Input() public thinHeroInputs: ThinHeroInputs;
  @Input() public id: string;
  @Input() public imageSrc: string;
  @Input() public mobileImageSrc: string;
  @Input() public heading: string = null;
  @Input() public subheading: string = null;
  @Input() public mobileSubheading: string;
  @Input() public subheadingWidth: string;
  @Input() public subheadingWeight: string;
  @Input() public mobileSubheadingWidth: string;
  @Input() public verticalOffset: number;
  @Input() public headerVerticalOffset: number;
  @Input() public fancyStyle: boolean = true;
  @Input() public opacity: number = 0.6;
  @Input() public preformattedSubheading: boolean = false;
  @Input() public shadedMobileButtonSection: boolean = false;
  @Input() public buttons = []; //TODO: add types and models
  @Input() public availableLandDetailsPage: boolean = false;

  public shadingLayoverStyle: string;
  public contentStyle: string;
  public headingStyle: string;
  public subheadingStyle: string;
  public mobileSubheadingStyle: string;
  public backgroundStyle: string;
  public buttonContainerStyle: string;
  public buttonStyle: string;
  public mobileButtonsContainerStyle: string;

  public ngOnInit(): void {
    this.getButtons();
    useInputsObjectIfApplicable(this, 'thinHeroInputs');
    this.setHtmlAttributes();
    this.aBreakpointWasCrossed$.subscribe(() => {
      this.setHtmlAttributes();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['thinHeroInputs']) {
      useInputsObjectIfApplicable(this, 'thinHeroInputs');
      this.setHtmlAttributes();
    }
  }

  public getButtons(): any[] {
    return this.isDesktop
      ? this.buttons.filter((b) => b.includeForDesktop !== false)
      : this.buttons.filter((b) => b.includeForMobile !== false);
  }

  private setHtmlAttributes(): void {
    this.shadingLayoverStyle = `
      ${
        this.opacity != null
          ? `background-color: rgba(0,0,0,${this.opacity});`
          : ''
      }
    `;
    this.contentStyle =
      this.verticalOffset != null
        ? `position: relative; top: ${this.verticalOffset}px;`
        : null;
    if (this.isDesktop) {
      this.headingStyle = `
          ${
            this.fancyStyle
              ? ''
              : `
            font-family: var(--font-museo);
            font-size: 50px;
            letter-spacing: 3px;
            line-height: 55px;
            text-align: center;
            text-shadow: 0 0 10px rgba(0,0,0,0.5);
            font-weight: 100;
          `
          }
          ${
            this.headerVerticalOffset
              ? `position: relative; top: ${this.headerVerticalOffset}px;`
              : ''
          }
        `;
      this.subheadingStyle = `
      ${this.subheadingWidth ? `max-width: ${this.subheadingWidth};` : ''}
      ${this.subheadingWeight ? `font-weight: ${this.subheadingWeight};` : ''}
      ${
        this.fancyStyle
          ? ''
          : `
        padding-top: 15px;
        text-shadow: 0 0 10px rgba(0,0,0,0.5);
      `
      }
      ${this.preformattedSubheading ? 'white-space: pre;' : ''}
    `;
      if (this.imageSrc)
        this.backgroundStyle = `background-image: url(${escapeCharactersForCss(
          this.imageSrc || ''
        )})`;
      this.buttonContainerStyle =
        this.buttons.length === 1
          ? 'justify-content: center;'
          : 'justify-content: space-around;';
      this.buttonStyle =
        this.buttons.length === 1 ? 'width: 350px' : 'width: 300px';
    } else {
      if (this.imageSrc)
        this.backgroundStyle = `background-image: url(${escapeCharactersForCss(
          this.mobileImageSrc || ''
        )}),linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4));
background-blend-mode: overlay;`;
      this.mobileSubheadingStyle = `
      ${
        this.mobileSubheadingWidth
          ? `max-width: ${this.mobileSubheadingWidth};`
          : 'max-width: 90%;'
      }`;
      this.buttonContainerStyle =
        this.buttons.length === 1
          ? 'justify-content: center;'
          : 'justify-content: space-around;';
      this.buttonStyle =
        this.buttons.length === 1 ? 'width: 93%' : 'width: 93%';
      this.mobileButtonsContainerStyle = this.shadedMobileButtonSection
        ? 'background: var(--kwh-offwhite);'
        : 'background: var(--kwh-white);';
    }
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
