import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AvailableLand } from '../types/land';
import { transformLandItem } from '../utils/available-land.utils';

@Injectable({
  providedIn: 'root',
})
export class AvailableLandService {
  constructor(private http: HttpClient) {}

  getAvailableLand(): Observable<Array<AvailableLand>> {
    return this.http
      .get<{ items: Array<AvailableLand> }>(`${environment.url}inventory`)
      .pipe(
        map((res) =>
          res.items?.map((landItem) =>
            transformLandItem([landItem, null, null])
          )
        )
      );
  }
}
