import { environment } from './../../environments/environment';
import { LocationsMapData } from './locations-map.data';

export const ContactData = [
  {
    property: 'thinHeroInputs',
    inputs: {
      id: 'contact-hero',
      imageSrc: `${environment.S3_URL}images/contact/Contact_Header_Cropped_Desktop_2x.jpg`,
      mobileImageSrc: `${environment.S3_URL}images/contact/Contact_Header_Cropped_Mobile_2x.jpg`,
      heading: "Let's Get Started",
      mobileSubheading:
        'Ready to learn more about Keswick Homes and what we can do for you? We look forward to learning more about you and your dreams for a new custom home.',
      subheading:
        "Ready to learn more about Keswick Homes and what we can do for you? We're always happy to help, and we look forward to learning more about you and your dreams for a new custom home. You can simply click the button below to schedule an appointment now. Or, to give us more information, complete and submit the form on this page.",
      subheadingWidth: '1103px',
      mobileSubheadingWidth: '90%',
      buttons: [
        {
          text: 'Schedule An Appointment',
          link: 'https://calendly.com/jmcdonough-7/30min',
        },
      ],
    },
  },
  {
    property: 'contactFormInputs',
    inputs: {
      id: 'contact-us',
      formName: 'contact-us',
      subheading:
        'Both virtual and in-person appointments are available for your convenience. Simply fill out the form below to let us know how we can help you. A member of our team will be in touch soon.',
      disclaimers: [
        'Your personal information is for internal purposes only. We respect your privacy and are committed to treating your information responsibly. Any personal information you provide is considered confidential and WILL NOT BE SOLD OR SHARED with third parties.',
      ],
      viewMapLink: '#contact-locations-header',
      includeViewMapButton: true,
      contactInfoItems: [
        {
          header: 'MARYLAND LOCATION',
          boldLines: ['11022 Willow Bottom Drive', 'Columbia, MD 21044'],
        },
        {
          header: 'VIRGINIA LOCATION',
          boldLines: [
            '8280 Willow Oaks Corporate Drive',
            'Suite 600',
            'Fairfax, VA 22031',
          ],
        },
        {
          header: 'HOURS',
          normalLines: ['Monday – Friday', '9am – 5pm EST'],
          boldLines: ['By Appointment Only'],
        },
        {
          header: 'PHONE',
          boldLines: ['866.819.9990'],
        },
      ],
      formFieldConfigs: [],
    },
  },
  ...LocationsMapData.map((componentConfig, index) => ({
    ...componentConfig,
    sequence: index + 2,
    inputs: {
      ...componentConfig.inputs,
      id: index === 0 ? 'contact-locations-header' : 'contact-locations-map',
    },
  })),
];
