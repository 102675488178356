<section id="{{ id }}" class="callout-w-icon" [style]="containerStyle">
  <div class="callout-w-icon-background">
    <div class="callout-w-icon-content" [style]="contentStyle">
      <img *ngIf="isMobile || isTablet" src="{{ iconSrc }}" />
      <h1 *ngIf="heading" [style]="h1Style">
        <img *ngIf="isDesktop" src="{{ iconSrc }}" /> {{ heading }}
      </h1>
      <p *ngIf="subheading" [style]="subheadingStyle">{{ subheading }}</p>
      <div class="callout-w-icon-buttons" [style]="buttonContainerStyle">
        <button
          *ngFor="let button of buttons; index as i"
          [style]="buttonStyle"
          (click)="navigateTo(button.link)"
        >
          {{ button.text }}
        </button>
      </div>
    </div>
  </div>
</section>
