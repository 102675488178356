import { ElementRef } from '@angular/core';
import { FieldAndValue } from './../types/interfaces';

export class FormsUtils {
  /**
   *
   * Due to having configurable inputs on the front-end side, regular
   * data binding would be tricky. This handles finding the value of
   * regular inputs, radio buttons, textareas, and MatSelects which
   * is what the pages were using as of 08/30/2022. For MatSelect there
   * are some limitations. This relies on a custom
   * <div class="mat-select-fieldname"> to hold the field "name" attribute
   * since it's not exposed in the DOM.
   *
   * @param elementRef
   * @returns
   */
  public static getFormValues(elementRef: ElementRef): FieldAndValue[] {
    const values: FieldAndValue[] = Array.from(
      elementRef?.nativeElement?.querySelectorAll(
        'input,textarea,.mat-select-fieldname'
      ) || []
    ).map(
      (element: HTMLDivElement | HTMLInputElement | HTMLTextAreaElement) => {
        return element instanceof HTMLDivElement
          ? {
              field: element.innerText?.trim() || '',
              value:
                (
                  (element.nextElementSibling as HTMLElement).querySelector(
                    '.mat-select-value-text'
                  ) as HTMLElement
                )?.innerText?.trim() || '',
            }
          : {
              field: element.name?.trim() || '',
              value: element.value?.trim() || '',
              ...(element instanceof HTMLInputElement &&
                element.type === 'radio' && {
                  checked: element.checked,
                }),
              ...(element instanceof HTMLInputElement &&
                element.accept === 'image/*' && {
                  file: element.files?.[0],
                }),
            };
      }
    );
    return values;
  }

  /**
   * See user Registration Form for an example.
   *
   * @param inputElement
   * @param previewImgElementFinder
   */
  public static imageInputOnChangeHandler(
    inputElement: HTMLInputElement,
    previewImgElementFinder: () => HTMLImageElement
  ) {
    var file = inputElement?.files?.[0];
    var reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        const previewImgElement = previewImgElementFinder();
        if (previewImgElement) previewImgElement.src = reader.result as string;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }
}
