<div id="{{ id }}" class="c-c-b-section-container">
  <div class="c-c-b-section">
    <h2>{{ heading }}</h2>
    <p
      *ngIf="subheading1"
      class="c-c-b-section-subheading c-c-b-section-subheading-one"
    >
      {{ subheading1 }}
    </p>
    <p
      *ngIf="subheading2"
      class="c-c-b-section-subheading c-c-b-section-subheading-two"
    >
      {{ subheading2 }}
    </p>
    <div class="c-c-b-section-items-container">
      <div *ngFor="let item of items" class="c-c-b-section-item">
        <div class="c-c-b-section-item-image-container">
          <div class="c-c-b-section-item-image-overlay"></div>
          <img
            *ngIf="item.backgroundImageSrc"
            class="c-c-b-section-item-image"
            src="{{ item.backgroundImageSrc }}"
            alt="{{ item.imageAltText }}"
          />
          <div
            *ngIf="!item.backgroundImageSrc"
            class="c-c-b-section-item-image-placeholder-container"
          >
            <div class="c-c-b-section-item-image-placeholder">
              {{ item.imageAltText }}
            </div>
          </div>
          <img
            *ngIf="item.logoSrc"
            class="c-c-b-section-item-image c-c-b-section-item-icon"
            src="{{ item.logoSrc }}"
            alt="{{ item.logoAltText }}"
          />
          <div
            *ngIf="!item.logoSrc"
            class="c-c-b-section-item-image-placeholder-container"
          >
            <div class="c-c-b-section-item-image-placeholder">
              {{ item.logoAltText }}
            </div>
          </div>
        </div>
        <div class="c-c-b-section-item-content">
          <div class="c-c-b-section-item-company">{{ item.company }}</div>
          <div class="c-c-b-section-item-contact-name">
            {{ item.firstName }} {{ item.lastName }}
          </div>
          <div
            class="c-c-b-section-item-lower-detail-content c-c-b-section-item-contact-id"
          >
            {{ item.companyId }}
          </div>
          <div
          *ngIf="isDesktop"
            class="c-c-b-section-item-lower-detail-content c-c-b-section-item-contact-phone"
          >
            {{ item.phone }}
          </div>
          <div
          *ngIf="isDesktop"
            class="c-c-b-section-item-lower-detail-content c-c-b-section-item-contact-email"
          >
            <a href="emailto:{{ item.email }}">Email {{ item.firstName }}</a>
          </div>
          <a *ngIf="isMobile || isTablet" [href]="'tel:' + item.phone" class="btn">CALL</a>
          <a *ngIf="isMobile || isTablet" [href]="'emailto:' + item.email" class="btn">EMAIL</a>
          <!-- <div class="c-c-b-section-item-text">
            <h3 *ngIf="item.company">{{ item.company }}</h3>
            <h3 *ngIf="item.firstName">
              {{ item.firstName }} {{ item.lastName || "" }}
            </h3>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
