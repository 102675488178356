<div class="p-i-g-section-gallery-container">
  <div
    class="p-i-g-section-gallery-inner-div"
    [style.background]="shadedBackground ? 'var(--kwh-offwhite)' : ''"
    [class.desktop-padding-top]="useDesktopPaddingTop"
  >
    <swiper
      #swiper
      [config]="slideConfig"
      [class.smaller-sizing]="smallerSizing"
    >
      <div class="swiper-wrapper">
        <div
          *ngFor="let slide of photoItems; let i = index"
          class="swiper-slide"
          [class.smaller-sizing]="smallerSizing"
        >
          <div
            [style.backgroundImage]="
              'url(' +
              (isDesktop ? slide?.desktopImageSrc : slide?.mobileImageSrc) +
              ')'
            "
          >
            <img
              class="left-arrow"
              src="{{ S3_URL }}{{
                isDesktop
                  ? 'icons/about/Right_Arrow_Box_White.svg'
                  : 'icons/right_arrow_white.svg'
              }}"
              alt="left"
              (click)="slideLeft()"
            />
            <img
              class="right-arrow"
              src="{{ S3_URL }}{{
                isDesktop
                  ? 'icons/about/Right_Arrow_Box_White.svg'
                  : 'icons/right_arrow_white.svg'
              }}"
              alt="right"
              (click)="slideRight()"
            />
            <p *ngIf="isDesktop" [innerText]="slide?.caption"></p>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
</div>
