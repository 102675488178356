import { Component, Input, OnInit } from '@angular/core';

import { NavigationService } from 'src/app/services/navigation.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { CalloutWithIconInputs } from './callout-with-icon.models';

@Component({
  selector: 'kwh-callout-with-icon',
  templateUrl: './callout-with-icon.component.html',
  styleUrls: ['./callout-with-icon.component.scss'],
})
export class CalloutWithIconComponent
  extends BreakpointsUtils
  implements OnInit
{
  constructor(
    private navigationService: NavigationService,
    private screenService: ScreenService
  ) {
    super(screenService);
  }

  @Input() public calloutWithIconInputs: CalloutWithIconInputs;
  @Input() public id: string;
  @Input() public iconSrc: string;
  @Input() public heading: string = null;
  @Input() public subheading: string = null;
  @Input() public subheadingWidth: string;
  @Input() public verticalOffset: number;
  @Input() public buttons = []; //TODO: add types and models
  @Input() public backgroundColor: 'white' | 'offwhite' | 'black' = 'white';

  public containerStyle: string;
  public h1Style: string;
  public contentStyle: string;
  public subheadingStyle: string;
  public buttonContainerStyle: string;
  public buttonStyle: string;

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'calloutWithIconInputs');
    this.setHtmlAttributes();
    this.aBreakpointWasCrossed$.subscribe(() => {
      this.setHtmlAttributes();
    });
  }

  private setHtmlAttributes(): void {
    this.contentStyle =
      this.verticalOffset != null
        ? `position: relative; top: ${this.verticalOffset}px;`
        : null;
    let subheadingStyle = this.subheadingWidth
      ? `max-width: ${this.subheadingWidth};`
      : '';
    if (this.isDesktop) {
      if (this.backgroundColor === 'black')
        subheadingStyle += ' color: var(--kwh-white);';
      this.subheadingStyle = subheadingStyle;
      this.buttonContainerStyle =
        this.buttons.length === 1
          ? 'justify-content: center;'
          : 'justify-content: space-between;';
      this.buttonStyle =
        this.buttons.length === 1 ? 'width: 350px' : 'width: 350px';
      this.containerStyle =
        this.backgroundColor === 'white'
          ? 'background: var(--kwh-white);'
          : this.backgroundColor === 'black'
          ? 'background: var(--kwh-black);'
          : '';
      this.h1Style =
        this.backgroundColor === 'black' ? 'color: var(--kwh-white);' : '';
    } else {
      if (this.backgroundColor === 'black')
        subheadingStyle += ' color: var(--kwh-white);';
      this.subheadingStyle = subheadingStyle;

      this.buttonContainerStyle = 'justify-content: center;';
      this.buttonStyle = 'width: 100%';
      this.containerStyle =
        this.backgroundColor === 'white'
          ? 'background: var(--kwh-white);'
          : this.backgroundColor === 'black'
          ? 'background: var(--kwh-black);'
          : '';
      this.h1Style =
        this.backgroundColor === 'black' ? 'color: var(--kwh-white);' : '';
    }
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }
}
