<!--Duplicate hidden header is non-fixed to make content's top spacing bulletproof-->
<kwh-header *ngIf="includeHiddenHeader" [hidden]="true"></kwh-header>
<kwh-header
  [transparent]="!includeHiddenHeader"
  [transparent]="headerIsTransparent"
></kwh-header>
<!-- <main class="container" [@routeAnimations]="getRouteAnimationData()"> -->
<main class="container" (scroll)="(handleScroll)">
  <router-outlet (activate)="onRouterActivate($event)"></router-outlet>
</main>
<kwh-footer></kwh-footer>
