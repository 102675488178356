import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SiteService {
  site = 'drbhomes';
  toggle = true;
  site$: BehaviorSubject<string> = new BehaviorSubject(null);

  siteUpdated = new EventEmitter<string>();
  toggleUpdated = new EventEmitter<boolean>();

  constructor() {}

  setSite(site: string): void {
    this.site$.next(site);
    // this.setPrimaryColor(site);
    // if (location.hostname === 'www.drbhomes.com') {
    //   this.addGoogleTagManagerScript(GA_ID[site]);
    // }
  }

  updateCurrentSite(current: string) {
    this.site = current;
    return this.site;
  }

  updateMapToggle(toggle: boolean) {
    this.toggle = toggle;
    return this.toggle;
  }

  backToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
