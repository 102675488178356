import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { MockedChanges, SimpleSectionInputs } from './simple-section.models';

@Component({
  selector: 'kwh-simple-section',
  templateUrl: './simple-section.component.html',
  styleUrls: ['./simple-section.component.scss'],
})
export class SimpleSectionComponent extends BreakpointsUtils implements OnInit {
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  @Input() simpleSectionInputs: SimpleSectionInputs;
  @Input() heading: string;
  @Input() headingSecondLine: string;
  @Input() subheading: string;
  @Input() id: string;
  @Input() fancyHeading: boolean;
  @Input() invertColors: boolean = true;
  @Input() subheadingWidth: string;
  @Input() marginBottom: string;

  public containerStyle: string;
  public headingStyle: string;

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'simpleSectionInputs');
    this.initialSetStyles();
    this.aBreakpointWasCrossed$.subscribe(() => this.initialSetStyles());
  }

  public initialSetStyles(): void {
    this.setStyles({
      fancyHeading: { currentValue: this.fancyHeading },
      invertColors: { currentValue: this.invertColors },
      marginBottom: { currentValue: this.marginBottom },
    } as MockedChanges);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setStyles(changes);
  }

  public setStyles(changes: SimpleChanges | MockedChanges): void {
    let headingStyle = '';
    if (changes['fancyHeading']?.currentValue && this.isDesktop) {
      headingStyle += `
          font-family: var(--font-annabelle);
          color: var(--kwh-pink-text);
          font-size: 95px;
          letter-spacing: 0;
          line-height: 104px;
          text-align: center;
      `;
    } else if (changes['fancyHeading']?.currentValue && !this.isDesktop) {
      headingStyle += `
          font-family: var(--font-annabelle);
          color: var(--kwh-pink-text);
          font-size: 64px;
          letter-spacing: 0;
          line-height: 58px;
          text-align: center;
      `;
    }
    if (headingStyle !== this.headingStyle) {
      this.headingStyle = headingStyle;
    }
    let containerStyle = '';
    if (changes['invertColors']) {
      if (changes['invertColors'].currentValue) {
        containerStyle += `
          background-color: var(--kwh-black);
          color: var(--kwh-white);
        `;
      }
    }
    if (changes['marginBottom']) {
      containerStyle += `
        margin-bottom: ${changes['marginBottom'].currentValue};
      `;
    }
    if (containerStyle !== this.containerStyle) {
      this.containerStyle = containerStyle;
    }
  }
}
