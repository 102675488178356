<footer class="footer">
  <div class="top-footer">
    <div class="location">
      <div class="location-icon">
        <img src="{{ S3_URL }}icons/MapIcon.svg" alt="Map" />
      </div>
      <div class="location-text">
        <p class="office">Maryland Office</p>
        <p class="address">11022 Willow Bottom Drive</p>
        <p class="address">Columbia, MD 21044</p>
      </div>
    </div>
    <div class="location">
      <div class="location-icon">
        <img src="{{ S3_URL }}icons/MapIcon.svg" alt="Map" />
      </div>
      <div class="location-text">
        <p class="office">Virginia Office</p>
        <p class="address">8280 Willow Oaks Corporate Drive</p>
        <p class="address">Suite 600</p>
        <p class="address">Fairfax, VA 22031</p>
      </div>
    </div>
    <div *ngIf="!isMobile" class="location phone">
      <div class="location-icon">
        <img src="{{ S3_URL }}icons/PinkPhone.svg" alt="Phone" />
      </div>
      <div class="location-text">
        <p class="office">Phone</p>
        <p class="address">866.819.9990</p>
      </div>
    </div>
    <a href="tel:8668199990" target="_blank" class="btn-call"
      ><img src="{{ S3_URL }}icons/PhoneIcon.svg" alt="Phone" /><span
        >Call 866.819.9990</span
      ></a
    >
    <div class="icons">
      <ul class="social">
        <li>
          <a href="https://www.facebook.com/KeswickHomes" target="_blank"
            ><img src="{{ S3_URL }}icons/FacebookIcon.svg" alt="Facebook"
          /></a>
        </li>
        <li>
          <a href="https://www.instagram.com/keswick_homes/" target="_blank"
            ><img src="{{ S3_URL }}icons/Instagram.svg" alt="Instagram"
          /></a>
        </li>
        <li>
          <a
            href="https://www.houzz.com/pro/keswickhomes/keswick-homes"
            target="_blank"
            ><img src="{{ S3_URL }}icons/HouzzIcon.svg" alt="Houzz"
          /></a>
        </li>
        <li>
          <a
            href="https://www.bbb.org/us/md/columbia/profile/home-builders/keswick-builders-llc-0011-90355835"
            target="_blank"
            ><img src="{{ S3_URL }}icons/BBBAccreditedBusiness.svg" alt="BBB"
          /></a>
        </li>
      </ul>
      <img class="eho-icon" src="{{ S3_URL }}icons/EHOIcon.svg" alt="EHO" />
    </div>
  </div>
  <div class="bottom-footer">
    <div class="container">
      <div class="copyright">
        <p>
          © Copyright 2021 Keswick Homes. All rights reserved. Programs and
          terms are subject to change without notice and availability. Keswick
          Builders, L.L.C.
          <span class="desktop-mhbr">&nbsp;|&nbsp;MHBR #7863</span>
        </p>
        <p class="mhbr">MHBR #7863</p>
        <p>
          <span class="legal-link" (click)="toggleIsTermsOpen()"
            >Terms &amp; Legal</span
          >&nbsp;|&nbsp;<span
            class="legal-link"
            (click)="toggleIsPrivacyPolicyOpen()"
            >Privacy Policy</span
          >
        </p>
      </div>
      <p class="top" (click)="scrollToTop()">
        To The Top
        <img src="{{ S3_URL }}icons/UpArrow.svg" />
      </p>
    </div>
  </div>
</footer>
<kwh-full-shadow-modal
  *ngIf="isTermsOpen"
  [onClose]="toggleIsTermsOpen"
  [extraDark]="true"
>
  <div class="footer-legal-text">
    TERMS & CONDITIONS APPLICABLE TO THIS SITE Use of this Site Signifies Your
    Agreement to the Terms and Conditions<br /><br />PLEASE READ THESE TERMS AND
    CONDITIONS CAREFULLY BEFORE USING THIS SITE<br /><br />Keswick Homes and its
    related, associated, affiliated, successor and subsidiary entities
    (collectively referred to as "Keswick Homes") are pleased to provide this
    website (the "Site") for your personal, non-commercial use. By accessing,
    viewing or using the content, material or services available through the
    Site, you agree to the terms and conditions provided below including Keswick
    Homes' Privacy Policy (collectively, "Terms and Conditions"). If you do not
    agree to the Terms and Conditions, please do not use this Site. In the event
    that any Keswick Homes website, contains terms and conditions for the use of
    that site which conflict with or are inconsistent with the Terms and
    Conditions, those terms and conditions will prevail and govern to the extent
    of the conflict or inconsistency in connection with the use of that site.<br /><br /><br />Keswick
    Homes reserves the right to update, revise or modify these Terms and
    Conditions at any time, without prior notice, by updating this posting. Any
    such updates, revisions or modifications will become automatically effective
    thirty (30) days after they are initially posted. Your use of this Site
    following any such update, revision, or modification constitutes your
    agreement to follow and be bound by such revision, update or modification.
    We recommend you review these Terms and Conditions each time you use this
    Site.<br /><br /><br />Ownership of Intellectual Property/Restrictions on
    Use of Materials<br /><br />The Site is owned and operated by Keswick Homes.
    Unless otherwise indicated the contents including without limitation, all
    logos, trade-marks, service marks, trade-dress, texts, graphics, images, and
    the collection, organization, arrangement and assembly of the contents of
    this Site (collectively, the "Content") are the property of Keswick Homes
    and are protected, without limitation, pursuant to Canadian, U.S. and
    foreign intellectual property laws, including copyright, trademark and other
    laws and regulations. You do not acquire any ownership rights to any Content
    through your access to, or use of, the Site.<br /><br /><br />No Content
    from this Site, either in whole or in part, may be copied, reproduced,
    republished, uploaded, posted, transmitted, archived, modified, sold or
    distributed in any way, without Keswick Homes's prior written consent except
    that you may download one copy of the Content on any single computer for
    your personal non-commercial use only, provided you keep intact all
    copyright and other proprietary notices. Modification of the Content or use
    of the Content for any other purpose is a violation of Keswick Homes's
    copyright, trademark, and trade secret rights and others' proprietary
    rights. The use of any Content on any other web site or networked computer
    environment for any purpose is strictly prohibited.<br /><br /><br />In the
    event you download software from the Site, the software including any files,
    images incorporated in or generated by the software and data accompanying
    the Software (together, the "Software") are non-exclusively licensed to you
    by Keswick Homes solely for the non-commercial purpose of using the Site.
    Keswick Homes does not transfer title in and to the Software to you. As
    between you and Keswick Homes, Keswick Homes retains full and complete
    right, title, and interest in and to the Software and all intellectual
    property rights therein. You may not redistribute, transmit, reproduce,
    publish, license, rewrite, modify, create derivative works from, transfer,
    sell, decompile, reverse-engineer or disassemble the Software. Except as
    expressly provided herein, no license, or other right is granted herein by
    Keswick Homes, either directly or by implication, estoppel or otherwise
    under these Terms and Conditions with respect to any Content, the Software
    or any other intellectual property.<br /><br /><br />Use of Site and Site
    Material/ Acceptable Site Use<br /><br />General Rules.<br /><br />Users may
    not use the Site in order to transmit, distribute, store or destroy the
    Content or any material: (a) in violation of any applicable law or
    regulation; (b) in a manner that will infringe the copyright, trademark,
    trade secret or other intellectual property rights of others or violate the
    privacy, publicity or other personal rights of others; or (c) that is
    defamatory, obscene, threatening, scandalous, inflammatory, pornographic,
    profane, abusive or hateful. Users may use the Site only for lawful purposes
    and in compliance with the Terms and Conditions.<br /><br /><br />Web Site
    Security Rules.<br /><br />Users are prohibited from violating or attempting
    to violate the security of the Site, including, without limitation: (a)
    accessing data not intended for such user or logging into a server or
    account which the user is not authorised to access; (b) attempting to probe,
    scan or test the vulnerability of a system or network or to breach security
    or authentication measures without proper authorisation; or (c) attempting
    to interfere with service to any user, host or network, including, without
    limitation, via means of submitting a virus to the Site, overloading,
    "flooding", "spamming", "mailbooming" or "crashing". Violations of any
    system or network security may result in civil or criminal liability.
    Keswick Homes will investigate occurrences which may involve such violations
    and may involve and co-operate with, law enforcement authorities in
    prosecuting users who are involved in such violations.<br /><br /><br />Hyperlinks<br /><br />Unless
    you have a written permission from Keswick Homes you may not provide a
    hypertext link to the site on another website.<br /><br /><br />Securities<br /><br />Neither
    Keswick Homes nor any of its officers, directors, employees, agents or
    licensors: (a) has passed on the merit of any of the securities referred to
    on this Site or any linked site; or (b) has endorsed or sponsored any of
    these securities; or (c) makes or has made any recommendation regarding any
    securities issued by any of the companies identified on or linked to this
    Site, nor the advisability of investing in securities generally for any
    particular individual. PLEASE SEEK THE ADVICE OF PROFESSIONALS, AS
    APPROPRIATE, REGARDING THE EVALUATION OF ANY SPECIFIC SECURITY, INDEX,
    REPORT, OPINION, ADVICE OR OTHER CONTENT. The information contained on this
    website does not constitute an offer to sell or the solicitation of an offer
    to buy any securities of Keswick Homes or any other company in any
    jurisdiction in which such an offer or invitation is not authorized and/or
    would be contrary to local law or regulation.<br /><br /><br />Forward-looking
    Information and Statements<br /><br />Certain information contained in this
    Site may constitute forward-looking statements and information within the
    meaning of applicable Canadian and U.S. States securities laws. These
    forward-looking statements reflect the beliefs of Keswick Homes's management
    at the time of posting and are based on assumptions and information
    available to the management team of Keswick Homes at the time of posting. In
    some cases, these statements or information may be identified by their use
    of forward-looking terminology such as "believes," "expects," "may,"
    "should," "would," "will," "intends," "plans," "estimates," "anticipates,"
    "predicts," "forecasts," "outlook," "potential," "continues," "likely,"
    "projects," "future," and similar words and expressions. Although Keswick
    Homes believes that the anticipated future results, performance or
    achievements expressed or implied by the forward-looking statements and
    information on this Site are based upon reasonable assumptions and
    expectations, you should not place undue reliance on forward-looking
    statements and information because they involve assumptions, known and
    unknown risks, uncertainties and other factors which may cause the actual
    results, performance or achievements of the company to differ materially
    from anticipated future results, performance or achievement expressed or
    implied by such forward-looking statements and information. Various factors
    that could cause actual results to differ materially from those contemplated
    or implied by forward-looking statements and information include the risks
    and factors detailed from time to time in Keswick Homes's form 40-F filed
    with the United States Securities and Exchange Commission and Management's
    Discussion and Analysis of Financial Results as well as other documents
    filed by Keswick Homes with the securities regulators in Canada and the
    United States. We caution that the important factors found therein that may
    affect future results is not exhaustive. When relying on our forward-looking
    statements to make decisions with respect to Keswick Homes, investors and
    others should carefully consider these factors and other uncertainties and
    potential events. Except as may be required by law, Keswick Homes undertakes
    no obligation to publicly update or revise any forward-looking statements or
    information, whether written or oral, that may be as a result of new
    information, future events or otherwise.<br /><br /><br />Investor Relations
    Information<br /><br />The information posted to the Investor Relations
    section of this Site is maintained by Keswick Homes. All information posted
    to the Investor Relations section of this Site was accurate at the time of
    posting, but may be superseded by subsequent disclosures made by Keswick
    Homes. Keswick Homes does not assume any duty of disclosure beyond that
    which is required by law. For Keswick Homes official investor disclosure
    documents, please see the Keswick Homes filings made with Canadian and
    United States securities regulators at www.sedar.com and
    www.sec.gov/edgar.shtml<br /><br /><br />Links<br /><br />The Site may
    contain links to third party sites. These links are provided solely as a
    convenience to you and not as an endorsement by Keswick Homes. Keswick Homes
    is not responsible for the content of linked third-party sites and does not
    make any representations regarding the content or accuracy of materials on
    such third party sites. If you decide to access linked third party sites,
    you do so at your own risk.<br /><br /><br />Disclaimer<br /><br />Keswick
    Homes MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS SITE OR
    ITS CONTENTS, WHICH ARE FOR INFORMATIONAL PURPOSES ONLY AND ARE PROVIDED FOR
    USE ON AN "AS IS, WHERE IS" BASIS AND "AS AVAILABLE." Keswick Homes
    DISCLAIMS ALL REPRESENTATIONS, WARRANTIES, AND CONDITIONS, EXPRESS OR
    IMPLIED, OR ARISING BY STATUTE, USAGE, CUSTOM OR OTHERWISE, INCLUDING
    WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
    A PARTICULAR PURPOSE, WITH RESPECT TO THIS SITE AND ANY WEB SITE WITH WHICH
    IT IS LINKED. Keswick Homes DOES NOT WARRANT THE FUNCTIONS, INFORMATION OR
    LINKS CONTAINED ON THIS SITE NOR DOES IT WARRANT THAT THE SITE'S CONTENTS
    WILL MEET YOUR REQUIREMENTS. Keswick Homes DOES NOT WARRANT THAT THIS SITE
    OR ITS CONTENTS ARE FIT FOR ANY PARTICULAR PURPOSE OR THAT THE OPERATION OF
    THIS SITE OR ITS CONTENTS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS
    WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE
    ARE FREE OF VIRUSES, WORMS, TROJAN HORSES, CANCELBOTS OR OTHER HARMFUL
    COMPONENTS. Keswick Homes DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
    REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS SITE IN
    TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND
    NOT Keswick Homes) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR
    OR CORRECTION.<br /><br />NEITHER Keswick Homes NOR ANY OF ITS AFFILIATES,
    LICENSORS, SERVICE PROVIDERS OR SUPPLIERS MAKES OR HAS MADE ANY
    RECOMMENDATIONS REGARDING THE SECURITIES OR INVESTMENT SERVICES OF ANY
    PERSON OR ENTITY WITH RESPECT TO THE CONTENT OF THIS SITE, OR OF THE
    ADVISABILITY OF INVESTING IN SECURITIES GENERALLY FOR ANY PARTICULAR
    INDIVIDUAL.<br /><br />Keswick Homes SHALL HAVE THE RIGHT FOR ANY REASON, IN
    ITS SOLE DISCRETION, TO TERMINATE, CHANGE, SUSPEND OR DISCONTINUE,
    TEMPORARILY OR PERMANENTLY, ANY ASPECT OF THE SITE, INCLUDING BUT NOT
    LIMITED TO CONTENT, FEATURES AND HOURS OF AVAILABILITY, WITHOUT FURTHER
    NOTICE TO YOU.<br /><br /><br />Limitation of Liability<br /><br />YOU USE
    THIS SITE AND ITS CONTENT AT YOUR OWN RISK. UNDER NO CIRCUMSTANCES,
    INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL Keswick Homes OR THEIR
    RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AGENTS OR LICENSEES
    BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, COMPENSATORY, DIRECT
    OR INDIRECT DAMAGES, LOSS OF DATA, INCOME OR PROFIT OR DAMAGE TO PROPERTY
    THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SITE OR THE
    CONTENTS ON THE SITE, EVEN IF Keswick Homes OR A Keswick Homes AUTHORISED
    REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO
    EVENT SHALL Keswick Homes TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND
    CAUSES OF ACTION (WHETHER IN CONTRACT, OR NOT (INCLUDING, BUT NOT LIMITED
    TO, NEGLIGENCE) OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR
    ACCESSING THIS SITE.<br /><br /><br />Indemnity of User<br /><br />You agree
    to defend, indemnify, and hold harmless Keswick Homes, and each of its
    officers, directors, employees, parents, shareholders, licensees, assigns
    and agents, from and against any claims, actions or demands, including
    without limitation reasonable legal and accounting fees, alleging or
    resulting from your use of the Content contained on the Site or your breach
    of the terms of these Terms and Conditions. Keswick Homes shall provide
    notice to you promptly of any such claim, suit, or proceeding and may assist
    you, in its sole discretion, at your expense, in defending any such claim,
    suit or proceeding.<br /><br /><br />Jurisdiction<br /><br />By accessing
    the Site, you agree that the laws of the Province of Ontario will apply to
    all matters relating to your use of this Site, without regard to rules
    relating to conflict of laws. Keswick Homes makes no claims that the
    Contents may be lawfully viewed or downloaded outside of Canada. Access to
    the Contents may not be legal by certain persons or in certain countries. If
    you access the Site from outside of Canada, you do so at your own risk and
    are responsible for compliance with the laws of your jurisdiction. Any
    action relating to these Terms and Conditions must be brought in Toronto,
    Ontario, Canada and you irrevocably consent to the exclusive jurisdiction of
    the courts of Ontario, Canada.<br /><br /><br />Relationships<br /><br />The
    relationship between Keswick Homes and you will be that of independent
    contractors, and neither of us nor any of our respective officers, agents or
    employees will be held or construed to be partners, joint ventures,
    fiduciaries, employees or agents of the other as a result of these Terms and
    Conditions or your use of the Site.<br /><br /><br />Severability<br /><br />If
    any provision of these Terms and Conditions shall be unlawful, void or for
    any reason unenforceable, then that provision shall be deemed severable from
    these Terms and Conditions and shall not affect the validity and
    enforceability of any remaining provisions. These Terms and Conditions
    constitute the entire agreement between you and Keswick Homes relating to
    the subject matter herein.<br /><br /><br />Violations of Terms of Use<br /><br />Keswick
    Homes reserves the right to seek all remedies available at law and in equity
    for violations of these Terms of Use, including the right to block access
    from a particular Internet address to our Site.<br /><br /><br />Entire
    Agreement<br /><br />These Terms and Conditions, together with those
    incorporated or referred to herein, constitute the entire agreement between
    us relating to the subject matter hereof, and supersede any prior
    understandings or agreements (whether electronic, oral or written) regarding
    the subject matter, and may not be amended or modified except by Keswick
    Homes as set forth above.
  </div>
</kwh-full-shadow-modal>
<kwh-full-shadow-modal
  *ngIf="isPrivacyPolicyOpen"
  [onClose]="toggleIsPrivacyPolicyOpen"
  [extraDark]="true"
>
  <div class="footer-legal-text">
    OUR COMMITMENT TO PRIVACY Keswick Homes and/or its related, associated,
    affiliated, successor and subsidiary entities, (collectively, "Keswick
    Homes", "we", "us" and "our") are committed to protecting your personal
    information. This Privacy Policy (the "Policy") governs the collection, use
    and disclosure of your personal information in any communication or
    interaction you may have with us, whether it be in-person, over the
    telephone, in writing, or electronic. Kewick Homes complies with all of the
    requirements of Canada's privacy legislation, The Personal Information
    Protection and Electronic Documents Act and substantially similar provincial
    legislation. By submitting your personal information, and using Keswick
    Homes services or website (the "Site"), you accept this Policy and consent
    to its terms and as otherwise described to you at the time of collection of
    your personal information. If you do not agree to the terms of this Policy,
    please do not use the Site.<br /><br /><br />POLICY AMENDMENTS<br /><br />This
    Policy may be modified, revised or updated at Keswick Homes' discretion, at
    any time, without notice to you. Please check back from time to time for
    such amendments. By continuing to view, browse or use the Site following any
    such modification or revision, you agree to be bound by such modification or
    revision.<br /><br /><br />WHAT IS PERSONAL INFORMATION?<br /><br />"Personal
    Information" is personally identifiable information about you, including
    your name, residential address and e-mail address, and your views and
    personal opinions. Publicly available information, such as business contact
    information or a public directory listing of your name, address, telephone
    number or information that is aggregated and not associated with a specific
    individual, including demographic information and statistics, is not
    considered Personal Information.<br /><br /><br />WHAT PERSONAL INFORMATION
    WILL KESWICK HOMES COLLECT?<br /><br />We will only request personal
    information from you that is necessary to establish, manage and maintain our
    relationship with you. Personal Information is collected by Keswick Homes
    when you provide it to us, such as, when you subscribe for investor alerts,
    request event reminders, or contact us through a contact form. We may also
    collect your IP address when you use the Site and we keep track of your
    dealings with us, including details of your visits to our Site and the
    resources that you access, including, but not limited to, traffic data,
    location data, weblogs and other communication data.<br /><br /><br />We may
    also receive Personal Information from our business partners and other third
    parties, provided that such third parties confirm to us that they have
    obtained your consent or we have obtained your consent in this regard.<br /><br /><br />WHAT
    NON-PERSONAL INFORMATION WILL KESWICK HOMES COLLECT?<br /><br />We collect
    certain non-personal information which is recorded by the standard operation
    of our Internet servers on an anonymous basis, such as the operating system
    and type of browser you are using, the sections of the Site you visit, the
    Site pages visited, and any content you post to or download from the
    Site.<br /><br /><br />This non-identifiable information is used on an
    aggregate basis and in a non-personally identifiable form, including: (i)
    for Site and system administration purposes, (ii) to improve the Site, (iii)
    to conduct internal reviews of the number of visitors to the Site (iv) to
    help us better understand visitors' use of our Site; (v) to respond to
    specific requests from our visitors; and (vi) to protect the security or
    integrity of our Site when necessary. Keswick Homes' servers may also
    automatically record information when you visit our website, including the
    URL that directed you to our website, your IP address, browser type and the
    date and time of your visit.<br /><br /><br />Cookies<br /><br />When you
    view the Site, we generally collect and store some information on your
    computer. This information will be stored in a "Cookie," which is a file
    created on your computer's hard drive, and will help us in many ways. For
    example, Cookies allow us to tailor the Site to better match your interests
    and preferences. With most Internet browsers, you can erase Cookies from
    your computer hard drive, block the creation of Cookies, or receive a
    warning before a Cookie is stored, although doing so may affect your use of
    the Site and your ability to access certain features of the Site. Please
    refer to your browser instructions or the help section of your browser to
    learn more about these functions.<br /><br /><br />Other Technologies<br /><br />Our
    Site may use Web beacon, gif or other technologies. When you access certain
    of our web pages, a non-identifiable notice of that visit is generated which
    may be processed by us. These technologies usually work in conjunction with
    cookies. If you don't want your cookie information to be associated with
    your visits to these pages, you can set your browser to turn off cookies. If
    you turn off cookies, Web beacon technologies will still detect visits to
    these pages, but the notices they generate cannot be associated with other
    non-identifiable cookie information and are disregarded.<br /><br /><br />HOW
    WILL KESWICK HOMES USE THE INFORMATION WE COLLECT?<br /><br />Keswick Homes
    collects and discloses your personal information for the following
    purposes:<br /><br />to contact and correspond with you;<br /><br />to
    respond to your communications and requests;<br /><br />to meet legal,
    regulatory, audit, security and financial requirements under federal and
    provincial regulations;<br /><br />to maintain business records for
    reasonable periods of time;<br /><br />to conduct market research and gather
    demographic and statistical information about our users, for managing our
    business; and<br /><br />at your option, to make you aware of Keswick Homes
    projects that may be of interest to you.<br /><br />Also, you may indicate
    that you are interested in receiving information from Keswick Homes. If so,
    your personal information will also be used to contact you or to send you
    e-mail materials and other mailings about productions or projects that might
    be of interest to you. You always have the option to tell us not to share
    your personal information with third parties or to exclude you from offers
    for targeted advertising.<br /><br /><br />We may be involved in the sale or
    transfer of some or all of our businesses. As part of that sale or transfer
    transaction, we may disclose your personal information to the acquiring
    organization, but will require the acquiring organization to agree to
    protect the privacy of your personal information in a manner that is
    consistent with the Policy.<br /><br />Limited disclosure of Personal
    Information to third parties may be required as part of Keswick Homes
    fulfilling its stated business duties and day-to-day operations. However,
    information will only be disclosed to such persons if they have entered into
    a written agreement with us to abide by this Policy.<br /><br /><br />PROCESSING
    OF PERSONAL INFORMATION<br /><br />Your personal information may be
    processed and stored outside of the province in which you reside and/or
    outside of Canada. Under the laws of these other jurisdictions, in certain
    circumstances foreign courts, law enforcement agencies or regulatory
    agencies may be entitled to access your personal information without notice
    to you. By submitting your Personal Information, you agree to this transfer,
    storing or processing.<br /><br /><br />CONSENT<br /><br />By providing us
    with your Personal Information, you consent to our using your Personal
    Information for the purpose set forth above. Other than as required by law,
    your Personal Information will not be used for any other purpose without
    your consent. You can at any time withdraw consent to receive further
    information or correspondence from us by contacting our Chief Privacy
    Officer at the address set forth below.<br /><br /><br />SECURITY<br /><br />Technical,
    contractual, administrative and physical security measures and safeguards,
    such as locked cabinets, restricted access and the use of passwords and
    firewalls have been adopted to protect your personal information against
    loss or theft, as well as unauthorized access, disclosure, copying, use or
    modification.<br /><br />Information collected from our Site is kept in a
    safe and secure system, isolated from direct connection to the Internet.<br /><br /><br />LINKS
    TO OTHER SITES<br /><br />The Site may contain links to independently owned,
    controlled and/or managed third party, Web or Internet sites to which the
    Policy does not apply. Should you select such a link, you will leave the
    Keswick Homes website. Please note that we do not monitor or control the
    content that appears on these third-party sites and such content may be
    constantly changing. If you view these third-party sites, the sites may
    assign a cookie. Keswick Homes does not control these cookies, nor is it
    responsible for any marketing or other use of your information by these
    third parties.<br /><br /><br />HOW TO CORRECT YOUR PERSONAL INFORMATION<br /><br />Keswick
    Homes wants to ensure that information about you is accurate, complete and
    current. Please inform Keswick Homes of any change of name, address or other
    information. In addition, you have the right to access the Personal
    Information that Keswick Homes has collected, stored, and disclosed about
    you, subject to any legal restrictions. If there are errors in the
    information in your files, you may request that it be changed. If we have
    disclosed inaccurate information about you to a third party, we will be
    pleased to contact the third party in order to advise them of the error.<br /><br /><br />In
    order to access this information, you must put your request in writing to
    the individual referred to below.<br /><br /><br />HOW TO ACCESS YOUR
    PERSONAL INFORMATION<br /><br />If you have any questions, concerns or would
    like more information about the collection, use or disclosure of your
    personal information by Keswick Homes, or if you simply want to know whether
    we have any of your personal information on file, you may contact us at the
    following address:<br /><br />e-mail:<br /><br />JMcDonough@keswickhomes.com<br /><br /><br /><br />mail:<br /><br />John
    McDonough<br /><br />8280 Willow Oaks Corporate Drive, Suite 600<br /><br />Fairfax,
    VA 22031<br /><br /><br /><br />If Keswick Homes refuses access to you, it
    will provide you with the reasons for its refusal upon request. Exceptions
    may include information that contains references to other individuals,
    information that cannot be disclosed for legal, security or commercial
    proprietary reasons, and information that is subject to solicitor-client or
    litigation privilege.<br /><br />YOU ACKNOWLEDGE THAT YOU HAVE READ THIS
    PRIVACY POLICY, UNDERSTAND IT AND AGREE TO ALL OF THE TERMS AND CONDITIONS
    IN THIS PRIVACY POLICY. BY CONTINUING TO BROWSE THIS SITE, YOU WILL BE
    LEGALLY GRANTING YOUR CONSENT TO KESWICK HOMES' COLLECTION, USE AND
    DISCLOSURE OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY
    POLICY EVEN IF YOU HAVE NOT READ THIS PRIVACY POLICY.<br /><br />Last
    updated: July, 29, 2014
  </div>
</kwh-full-shadow-modal>
