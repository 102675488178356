import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Input,
  isDevMode,
  OnInit,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { environment } from './../../../environments/environment';

import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { NavigationService } from 'src/app/services/navigation.service';
import { useInputsObjectIfApplicable } from 'src/app/utils/component-inputs.utils';
import { GoogleMapsUtils } from 'src/app/utils/google-maps-utils';
import { ScreenService } from './../../services/screen-size.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { LOCATIONS_MAP_CONSTANTS } from './locations-map.constants';
import { InfoWindowContent, LocationsMapInputs } from './locations-map.models';
import { LocationsMapUtils } from './locations-map.utils';
import { PlatformService } from './../../services/platform.service';

/**
 * TODO: Come back and add InfoWindows and other stuff. We are
 * moving on but we will need to come back and work on this.
 */

@Component({
  selector: 'kwh-locations-map',
  templateUrl: './locations-map.component.html',
  styleUrls: ['./locations-map.component.scss'],
})
export class LocationsMapComponent extends GoogleMapsUtils implements OnInit, AfterViewInit {
  @ViewChild(MapInfoWindow)
  mapInfoWindow: MapInfoWindow;

  constructor(
    private changeDetector: ChangeDetectorRef,
    http: HttpClient,
    private screenService: ScreenService,
    private navigationService: NavigationService,
    private platformService: PlatformService
  ) {
    super(http);
    this.isBrowser = this.platformService.isBrowser();
  }

  @Input() public locationsMapInputs: LocationsMapInputs;
  @Input() public id: string;
  @Input() public items = []; //TODO: add types and models
  @Input() public mapZoom: number;
  @Input() public mapZoomForMobile: number;
  @Input() private mapCenterLat: number;
  @Input() private mapCenterLng: number;

  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;
  public isBrowser: boolean;

  public mapHeight: string = LOCATIONS_MAP_CONSTANTS.mapHeight;
  public mapWidth: string = LOCATIONS_MAP_CONSTANTS.mapWidth;
  public mapCenter: google.maps.LatLngLiteral;
  public mapOptions: google.maps.MapOptions =
    LOCATIONS_MAP_CONSTANTS.mapOptions;
  public infoWindowOptions: google.maps.InfoWindowOptions;
  public infoWindowContent: InfoWindowContent;

  public isAddressesModalOpen = false;

  public S3_URL = environment.S3_URL;

  public ngOnInit(): void {
    useInputsObjectIfApplicable(this, 'locationsMapInputs');
    BreakpointsUtils.subscribeForClass(this);
  }

  public resolveMapZoom(): number {
    return this.isMobile ? this.mapZoomForMobile : this.mapZoom;
  }

  public ngAfterViewInit(): void {
    if(this.platformService.isBrowser()) {
      this.waitForGoogleApiThenLoadMap();
    }
  }

  private waitForGoogleApiThenLoadMap(): void {
    const checkAndSetMapData = (): void => {
      if (google && google.maps) {
        this.setMapData();
      } else {
        setTimeout(() => {
          checkAndSetMapData();
        }, LOCATIONS_MAP_CONSTANTS.googleMapsApiLoadWait);
      }
    };
    checkAndSetMapData();
  }

  // use async CMS logic here once CMS is ready
  private setMapData(): void {
    // Center map over correct geographic area:
    this.mapCenter = {
      lat: this.mapCenterLat,
      lng: this.mapCenterLng,
    };

    // Format and set marker data, add parsed address to items:
    // TODO: add more types to this logic
    const newItems = cloneDeep(this.items);
    newItems.forEach((item) => {
      if (item.address) {
        item.markerOptions = {
          icon: {
            url: item.iconSrc,
          },
          clickable: item.clickable || false,
        };
        const [mainAddressLine, addressLine2, cityStateZip] =
          LocationsMapUtils.parseAddressIntoLines(
            item.address,
            LOCATIONS_MAP_CONSTANTS
          );
        item.addressLines = {
          mainAddressLine,
          addressLine2,
          cityStateZip,
        };
      }
    });
    this.items = newItems;

    this.changeDetector.detectChanges();
    this.getCoordsFromGeocoder(newItems.map((item) => item.address));
  }

  // Create address lines and link, then open window over marker
  public openAddressWindow(marker: MapMarker): void {
    const [mainAddressLine, addressLine2, cityStateZip, directionsLink] =
      LocationsMapUtils.parseAddressAndLink(
        marker,
        LOCATIONS_MAP_CONSTANTS,
        this.resolvedCoordinates
      );
    if (mainAddressLine) {
      this.infoWindowContent = {
        line1: mainAddressLine,
        line2: addressLine2,
        line3: cityStateZip,
        directionsLink: directionsLink,
      };
      this.mapInfoWindow.open(marker);
    } else if (isDevMode()) {
      LocationsMapUtils.warnNoAddress(marker);
    }
  }

  public navigateTo(link: string): void {
    this.navigationService.navigateTo(link);
  }

  public getItemsForModal(): any[] {
    return cloneDeep(this.items).filter((item) => item.showInModal);
  }

  public toggleAddressesModal(
    bool: boolean = !this.isAddressesModalOpen
  ): void {
    this.isAddressesModalOpen = bool;
  }

  public getCoordinatesToUse(item: any): any {
    switch(item.address) {
      case `9410 Boxman Neavitt Road, St. Michael's, MD, 21663`:
        return new google.maps.LatLng(38.815618870330525, -76.25948390579316);
      case `8831 Dawson Road, St. Michael's, MD, 21663`:
        return new google.maps.LatLng(38.814548839103296, -76.23613795730144);
      case `104 Marea Terrace, St. Michael's, MD, 21663`:
        return new google.maps.LatLng(38.81240872842186, -76.20867213554648);
      default:
        return this.resolvedCoordinates.get(item.address);
    }
  } 
}
