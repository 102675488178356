<div id="{{ id }}" class="p-b-section-container">
  <div class="p-b-section">
    <h2>{{ heading }}</h2>
    <p class="p-b-section-subheading">{{ subheading }}</p>
    <div class="p-b-section-items-container">
      <div *ngFor="let item of items" class="p-b-section-item">
        <img
          class="p-b-section-item-image"
          src="{{ item.imageSrc }}"
          alt="{{ item.imageAltText }}"
        />
        <div
          *ngIf="item.readMoreOverlayTextParagraph1"
          class="p-b-section-item-text-overlay"
        >
          <div class="p-b-section-item-text-overlay-content">
            {{ item.readMoreOverlayTextParagraph1 }}
            {{ item.readMoreOverlayTextParagraph2 }}
          </div>
          <button
            class="p-b-section-item-text-overlay-button"
            (click)="toggleFullDisplay(item, true)"
          >
            READ MORE
            <img src="{{ S3_URL }}icons/learn_more_right_arrow.svg" />
          </button>
        </div>
        <div class="p-b-section-item-text">
          <h3>{{ item.caption }}</h3>
          <p>{{ item.subcaption }}</p>
          <div
            *ngIf="isMobile || isTablet"
            class="p-b-section-item-mobile-read-more-button"
          >
            <button (click)="toggleFullDisplay(item, true)">
              READ BIO
              <img
                src="{{ S3_URL }}icons/home/Right_Arrow_Black_noborder.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<kwh-full-shadow-modal
  *ngIf="isFullDisplayOpen"
  [onClose]="toggleFullDisplay"
  [coverScreen]="true"
>
  <div class="p-b-section-full-display-content">
    <div
      class="p-b-section-full-display-close-icon-section"
      (click)="toggleFullDisplay(false)"
    >
      <img src="{{ S3_URL }}icons/CloseIcon_Pink.svg" />
    </div>
    <div class="p-b-section-full-display-heading">
      {{ fullDisplayItem?.caption }}
    </div>
    <div class="p-b-section-full-display-subheading">
      {{ fullDisplayItem?.subcaption }}
    </div>
    <p class="p-b-section-full-display-content-paragraph">
      {{ fullDisplayItem?.readMoreOverlayTextParagraph1 }}
    </p>
  </div>
</kwh-full-shadow-modal>
