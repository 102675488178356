import { MapMarker } from '@angular/google-maps';

export class LocationsMapUtils {
  public static parseAddressIntoLines(
    address: string,
    LOCATIONS_MAP_CONSTANTS
  ): string[] {
    const addressSplit = address.split(',');
    const cityStateZip = addressSplit.slice(-2).join(',');
    const addressLine2 = addressSplit.slice(-3)[0];
    const mainAddressLine = addressSplit.slice(-4)[0];
    return [
      mainAddressLine,
      addressLine2 === mainAddressLine ? null : addressLine2,
      cityStateZip,
    ];
  }

  public static parseAddressAndLink(
    marker: MapMarker,
    LOCATIONS_MAP_CONSTANTS,
    resolvedCoordinates: Map<string, google.maps.LatLngLiteral>
  ): string[] {
    const markerPos = marker.marker.getPosition();
    let matchingAddress: string;
    for (let value of resolvedCoordinates) {
      if (
        markerPos.lat() === value[1].lat &&
        markerPos.lng() === value[1].lng
      ) {
        matchingAddress = value[0];
        break;
      }
    }
    if (matchingAddress) {
      const [mainAddressLine, addressLine2, cityStateZip] =
        LocationsMapUtils.parseAddressIntoLines(
          matchingAddress,
          LOCATIONS_MAP_CONSTANTS
        );
      const directionsLink = [
        ['{{addressUriEncoded}}', matchingAddress.replace(/\s+/g, '+')],
        ['{{latitude}}', markerPos.lat()],
        ['{{longitude}}', markerPos.lng()],
        [
          '{{zoomLevel}}',
          LOCATIONS_MAP_CONSTANTS.googleDirectionsLinkZoomLevel,
        ],
      ].reduce(
        (link, replacement) =>
          link.replace(replacement[0] as string, replacement[1] as any),
        LOCATIONS_MAP_CONSTANTS.googleDirectionsLinkTemplate
      );
      return [mainAddressLine, addressLine2, cityStateZip, directionsLink];
    } else return [];
  }

  public static warnNoAddress(marker: MapMarker) {
    console.error(
      `LocationsMapComponent: No matching address was found for marker at ${marker
        .getPosition()
        .lat()} ${marker.getPosition().lng()}`
    );
  }
}
