import { Component, OnInit } from '@angular/core';
import { SwiperOptions } from 'swiper';

import { ScreenService } from './../../services/screen-size.service';
import { setComponentInputsFromData } from '../../utils/component-inputs.utils';
import { HomeData } from 'src/app/data/home.data';
import { HeroInputs } from '../../common/hero/hero.models';
import { SimpleSectionInputs } from 'src/app/common/simple-section/simple-section.models';
import { CalloutWithIconInputs } from 'src/app/common/callout-with-icon/callout-with-icon.models';
import { SubheadingGallerySectionInputs } from 'src/app/common/subheading-gallery-section/subheading-gallery-section.models';
import { ImageShadowedCalloutInputs } from './../../common/image-shadowed-callout/image-shadowed-callout.models';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'kwh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BreakpointsUtils implements OnInit {
  customClass: string = 'home-mobile-slider';
  slideConfig: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 0,
    autoplay: true
  };
  homeData = HomeData;
  slideData: any[] = this.homeData[0].inputs.heroItems;
  isBrowser: boolean;

  constructor(private screenService: ScreenService, private platformService: PlatformService) {
    super(screenService);
    this.isBrowser = this.platformService.isBrowser();
  }

  public heroInputs: HeroInputs;
  public simpleSectionInputs: SimpleSectionInputs;
  public imageShadowedCalloutInputs: ImageShadowedCalloutInputs;
  public imageShadowedCallout2Inputs: ImageShadowedCalloutInputs;
  public calloutWithIconInputs: CalloutWithIconInputs;
  public subheadingGallerySectionInputs: SubheadingGallerySectionInputs;

  ngOnInit(): void {
    setComponentInputsFromData(this, HomeData);
  }
}
