export const SEO_DATA = {
    home: {
        title: 'Keswick Homes',
        description: 'Keswick Homes custom crafts a homebuilding experience in Virginia, Maryland and the Washington, D.C. Area. Our custom homes fit your unique needs and exceed your high expectations, all to ensure that your new home is everything you wanted and more.'
    },
    ['build-your-home']: {
        title: 'Build Your Dream Home | Keswick Homes',
        description: 'Keswick Homes team members guide you through every step and provide you with objective advice to help you achieve your vision of the perfect home.'
    },
    gallery: {
        title: 'Gallery | Keswick Homes',
        description: 'Explore the possibilities and envision your ideal new home. This gallery offers a brief selection of images from the Keswick portfolio of completed homes.'
    },
    portfolio: {
        title: 'Home Design Portfolio | Keswick Homes',
        description: 'Start building your dream home by browsing our home design portfolio. As a starting point for you, view pictures, floorplans, tours, and more to see how Keswick Homes can realize your unique vision.'
    },
    ['available-land']: {
        title: 'Available Land | Keswick Homes',
        description: 'Find the perfect property for your custom dream home. We have outstanding land parcels available throughout Virginia, Maryland and the Washington, D.C. Area.'
    },
    about: {
        title: 'About Us | Keswick Homes',
        description: 'Keswick Homes has a long history of creating truly inspired homes and satisfied clients in Loudoun, Fairfax, Howard and Montgomery counties, as well as throughout the D.C. region.'
    },
    contact: {
        title: 'Contact Us | Keswick Homes',
        description: 'Want more information about Keswick Homes? Contact us to speak to one of our team member.'
    },
    referral: {
        title: 'Referral Rewards | Keswick Homes',
        description: `SHARE THE JOY AND YOU'LL RECEIVE A $1,000 AMERICAN EXPRESS® GIFT CARD! PLUS, YOUR FRIENDS WILL RECEIVE $1,000 IN OPTIONS FOR THEIR NEW HOME.`
    },
    financing: {
        title: 'Financing | Keswick Homes',
        description: 'Keswick Homes preferred lenders are dedicated to providing our important clientele with a smooth and low-stress financing process, while helping them secure some of the most competitive rates available.'
    },
    realtors: {
        title: 'For Realtors | Keswick Homes',
        description: 'Get Keswick Connected to receive priority notification of upcoming events and promotions and easy access to scheduling for clients.'
    },
    ['home-stories']: {
        title: 'Home Stories | Keswick Homes',
        description: `Read our customers' stories to learn about their uniquely personal experiences with Keswick and the truly custom homes we've built together.`
    }
}
