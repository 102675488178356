<section id="{{ id }}" class="image-callout">
  <div class="image-callout-heading">
    <h1 *ngIf="heading">{{ heading }}</h1>
    <p *ngIf="subheading">{{ subheading }}</p>
  </div>
  <div class="image-callout-background shading-layover">
    <div class="image-callout-content" [style]="contentStyle">
      <h1 *ngIf="heading">{{ heading }}</h1>
      <!--TODO: if necessary name these like desktopSubheadingStyle to differentiate?-->
      <p *ngIf="subheading" [style]="subheadingStyle">{{ subheading }}</p>
      <div class="image-callout-buttons" [style]="buttonContainerStyle">
        <button
          *ngFor="let button of buttons; index as i"
          [style]="buttonStyle"
          (click)="navigateTo(button.link)"
        >
          {{ button.text }}
        </button>
      </div>
    </div>
  </div>
  <div class="image-callout-background" [style]="backgroundStyle"></div>
</section>
