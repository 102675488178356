import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SwiperComponent } from 'ngx-useful-swiper';
import { ScreenService } from 'src/app/services/screen-size.service';
import { BreakpointsUtils } from 'src/app/utils/breakpoints-base-class.utils';
import { SwiperOptions } from 'swiper';

import {
  SliderDimensions,
  SliderPhotoItem,
} from '../photo-slider/photo-slider.models';

@Component({
  selector: 'kwh-gallery-section',
  templateUrl: './gallery-section.component.html',
  styleUrls: ['./gallery-section.component.scss'],
})
export class GallerySectionComponent
  extends BreakpointsUtils
  implements OnInit
{
  @ViewChild('swiper') swiper: SwiperComponent;

  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  @Input() useDesktopPaddingTop: boolean = true;

  customClass: string = 'home-mobile-slider';
  slideConfig: SwiperOptions = {
    pagination: {
      // el: '.swiper-pagination',
      // clickable: true,
    },
    spaceBetween: 0,
    autoplay: true,
  };

  @Input() item: any; //TODO: type
  @Input() shadedBackground: boolean = true;
  @Input() smallerSizing: boolean = false;

  public photoItems: SliderPhotoItem[] = [];
  public sliderDimensions: SliderDimensions = {
    x: '954px',
    y: '717px',
  };
  public mobileSliderDimensions: SliderDimensions = {
    x: '100%',
    y: '78vw',
  };
  public marginTop: string;
  public marginBottom: string;

  ngOnInit(): void {}

  setImages() {
    this.photoItems =
      this.item?.images.map((imageConfig) => ({
        desktopImageSrc: this.encode(
          imageConfig.desktopImageSrc ||
            imageConfig.src ||
            imageConfig.url ||
            imageConfig.mobileImageSrc
        ),
        mobileImageSrc: this.encode(
          imageConfig.mobileImageSrc ||
            imageConfig.src ||
            imageConfig.url ||
            imageConfig.desktopImageSrc
        ),
        imageAltText: imageConfig.altText,
        caption: imageConfig.caption,
      })) || [];
  }

  ngOnChanges(changes: SimpleChanges) {
    changes['item'] && this.setImages();
  }

  encode(url) {
    return encodeURI(url).replace(/\(/g, '\\(').replace(/\)/g, '\\)');
  }

  slideLeft() {
    this.swiper.swiper.slidePrev();
  }

  slideRight() {
    this.swiper.swiper.slideNext();
  }
}
