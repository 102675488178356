import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./features/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./features/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'gallery',
    loadChildren: () =>
      import('./features/gallery/gallery.module').then((m) => m.GalleryModule),
  },
  {
    path: 'home-design',
    loadChildren: () =>
      import('./features/portfolio/portfolio.module').then(
        (m) => m.PortfolioModule
      ),
  },
  {
    path: 'financing',
    loadChildren: () =>
      import('./features/financing/financing.module').then(
        (m) => m.FinancingModule
      ),
  },
  // {
  //   path: 'available-land',
  //   loadChildren: () =>
  //     import('./features/available-land/available-land.module').then(
  //       (m) => m.AvailableLandModule
  //     ),
  // },
  // {
  //   path: 'available-land/:address',
  //   loadChildren: () =>
  //     import('./features/land-item/land-item.module').then(
  //       (m) => m.LandItemModule
  //     ),
  // },
  {
    path: 'build-your-home',
    loadChildren: () =>
      import('./features/dream-home/dream-home.module').then(
        (m) => m.DreamHomeModule
      ),
  },
  // {
  //   path: 'realtors',
  //   loadChildren: () =>
  //     import('./features/for-realtors/for-realtors.module').then(
  //       (m) => m.ForRealtorsModule
  //     ),
  // },
  // {
  //   path: 'home-stories',
  //   loadChildren: () =>
  //     import('./features/home-stories/home-stories.module').then(
  //       (m) => m.HomeStoriesModule
  //     ),
  // },
  {
    path: 'referral',
    loadChildren: () =>
      import('./features/referral-rewards/referral-rewards.module').then(
        (m) => m.ReferralRewardsModule
      ),
  },
  {
    path: 'our-process',
    loadChildren: () =>
      import('./features/our-process/our-process.module').then(
        (m) => m.OurProcessModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
