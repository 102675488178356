import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor() {}

  isValidEmail(control: AbstractControl): ValidationErrors {
    const currentEmailValue = control.value;
    if (
      !currentEmailValue ||
      /^\w+([\._+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(currentEmailValue)
    ) {
      return null;
    }
    return { email: true };
  }

  isValidPhone(control: AbstractControl): ValidationErrors {
    const currentPhoneValue = control.value;
    if (
      !currentPhoneValue ||
      currentPhoneValue.length === 0 ||
      (currentPhoneValue.length > 9 &&
        currentPhoneValue.match(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        ))
    ) {
      return null;
    }
    return { invalidPhone: true };
  }
  isValidZipCode(control: AbstractControl): ValidationErrors {
    const currentZipCode = control.value;
    if (
      currentZipCode === '' ||
      /^[0-9]{5}(?:-[0-9]{4})?$/.test(currentZipCode)
    ) {
      return null;
    }
    return { invalidZip: true };
  }
}
