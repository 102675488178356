<div *ngIf="isDesktop" id="{{ id }}" class="iswi-container clearfix">
  <div
    *ngIf="imagePlacement === 'left'"
    class="iswi-image-panel"
    [style]="'background-image: url(' + imageSrc + ')'"
  ></div>
  <div class="iswi-text-panel">
    <div class="iswi-text-panel-inner-div">
      <h2>{{ heading }}</h2>
      <p>{{ paragraph }}</p>
      <button
        *ngFor="let button of buttons"
        (click)="navigateTo(button.link)"
        [style]="button.width ? 'width: ' + button.width : ''"
      >
        {{ button.text }}
      </button>
    </div>
  </div>
  <div
    *ngIf="imagePlacement === 'right'"
    class="iswi-image-panel"
    [style]="'background-image: url(' + imageSrc + ')'"
  ></div>
</div>
<div *ngIf="isMobile || isTablet" id="{{ id }}" class="iswi-mobile">
  <div class="iswi-wrapper">
    <h2>{{ heading }}</h2>
    <p>{{ paragraph }}</p>
    <button *ngFor="let button of buttons" (click)="navigateTo(button.link)">
      {{ button.mobileText || button.text }}
    </button>
  </div>
  <img [src]="imageSrc" />
</div>
