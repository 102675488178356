<section id="{{ id }}" class="thin-hero">
  <div
    class="thin-hero-heading"
    [style]="isMobile || isTablet ? backgroundStyle : ''"
    [class.available-land-details-page]="availableLandDetailsPage"
  >
    <h1 *ngIf="heading">{{ heading }}</h1>
    <p
      *ngIf="(isMobile || isTablet) && mobileSubheading"
      [style]="mobileSubheadingStyle"
    >
      {{ mobileSubheading }}
    </p>
  </div>
  <div
    class="thin-hero-background shading-layover"
    [style]="shadingLayoverStyle"
  >
    <div class="thin-hero-content" [style]="contentStyle">
      <h1 *ngIf="heading" [style]="headingStyle">{{ heading }}</h1>
      <p *ngIf="subheading" [style]="subheadingStyle">{{ subheading }}</p>
      <div
        *ngIf="isDesktop"
        class="thin-hero-buttons"
        [style]="buttonContainerStyle"
      >
        <button
          *ngFor="let button of getButtons(); index as i"
          [style]="buttonStyle"
          (click)="navigateTo(button.link)"
        >
          {{ button.text }}
          <img
            *ngIf="button.includeDownloadIcon"
            src="{{ S3_URL }}icons/download_icon_white.svg"
          />
        </button>
      </div>
    </div>
  </div>
  <div class="thin-hero-background" [style]="backgroundStyle"></div>
  <div
    *ngIf="(isMobile || isTablet) && buttons?.length"
    class="thin-hero-buttons-wrapper"
    [style]="mobileButtonsContainerStyle"
  >
    <div class="thin-hero-buttons" [style]="buttonContainerStyle">
      <button
        *ngFor="let button of getButtons(); index as i"
        [style]="buttonStyle"
        (click)="navigateTo(button.link)"
      >
        {{ button.text }}
      </button>
    </div>
  </div>
</section>
