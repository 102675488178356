import { Component, OnInit } from '@angular/core';

import { AboutData } from './../../data/about.data';

import { setComponentInputsFromData } from '../../utils/component-inputs.utils';
import { ThinHeroInputs } from './../../common/thin-hero/thin-hero.models';
import { InfoSectionWithImageInputs } from './../../common/info-section-with-image/info-section-with-image.models';
import { NumberedInfoSectionInputs } from './../../common/numbered-info-section/numbered-info-section.models';
import { LocationsMapInputs } from 'src/app/common/locations-map/locations-map.models';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';
import { ScreenService } from './../../services/screen-size.service';
import { BrandBoxesSectionInputs } from 'src/app/common/brand-boxes-section/brand-boxes-section.models';
import { PeopleBoxesSectionInputs } from 'src/app/common/people-boxes-section/people-boxes-section.models';
import { LargeBrandScrollerInputs } from 'src/app/common/large-brand-scroller/large-brand-scroller.model';
import { SimpleButtonsSectionInputs } from './../../common/simple-buttons-section/simple-buttons-section.models';
import { SimpleSectionInputs } from 'src/app/common/simple-section/simple-section.models';

@Component({
  selector: 'kwh-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent extends BreakpointsUtils implements OnInit {
  constructor(private screenService: ScreenService) {
    super(screenService);
  }

  public thinHeroInputs: ThinHeroInputs;
  public infoSectionWithImageInputs: InfoSectionWithImageInputs;
  public infoSectionWithImageInputs2: InfoSectionWithImageInputs;
  public numberedInfoSectionInputs: NumberedInfoSectionInputs;
  public peopleBoxesSectionInputs: PeopleBoxesSectionInputs;
  public brandBoxesSectionInputs: BrandBoxesSectionInputs;
  public largeBrandScrollerInputs: LargeBrandScrollerInputs;
  public simpleButtonsSectionInputs: SimpleButtonsSectionInputs;
  public simpleSectionInputs: SimpleSectionInputs;
  public locationsMapInputs: LocationsMapInputs;

  ngOnInit(): void {
    setComponentInputsFromData(this, AboutData);
  }
}
