import { BehaviorSubject } from 'rxjs';
import { environment } from './../../environments/environment';
import { ScreenService } from './../services/screen-size.service';

export class BreakpointsUtils {
  public aBreakpointWasCrossed$ = new BehaviorSubject(null);

  constructor(private _screenService: ScreenService) {
    this._screenService.isMobile$.subscribe((next) => {
      this.isMobile = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this._screenService.isTablet$.subscribe((next) => {
      this.isTablet = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this._screenService.isDesktop$.subscribe((next) => {
      this.isDesktop = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
  }

  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;

  // Use for classes that are already extended 1x.
  // classInstance must have screenService injected and is[Breakpoint] properties
  public static subscribeForClass(classInstance): void {
    (classInstance.screenService as ScreenService).isMobile$.subscribe(
      (val) => (classInstance.isMobile = val)
    );
    (classInstance.screenService as ScreenService).isTablet$.subscribe(
      (val) => (classInstance.isTablet = val)
    );
    (classInstance.screenService as ScreenService).isDesktop$.subscribe(
      (val) => (classInstance.isDesktop = val)
    );
  }

  // Not related to breakpoints, but we need it everywhere.
  // In recent projects, we've created "ComponentBase" instead of "BreakpointsUtils"
  public S3_URL = environment.S3_URL;
}
