<div
  #contactForm
  id="{{ id }}"
  class="contact-form-container"
  [style]="useShadedBackground ? 'background-color: var(--kwh-offwhite);' : ''"
>
  <div class="contact-form-inner-div">
    <div
      *ngIf="heading"
      class="contact-form-heading"
      [class.smaller-heading]="smallerHeading"
    >
      <h3>{{ heading }}</h3>
    </div>
    <div
      *ngIf="subheading"
      class="contact-form-subheading"
      [class.m-t-0]="!!heading"
      [class.add-bottom-margin]="!!heading"
      [class.smaller-heading]="smallerHeading"
    >
      <p>{{ subheading }}</p>
    </div>
    <img
      class="contact-form-mobile-image-above-form"
      *ngIf="!isDesktop && mobileImageAbove"
      src="{{ mobileImageAbove }}"
    />
    <div class="contact-form-interactive-area-container">
      <div
        class="contact-form-side-panel {{
          isContactPage ? 'contact-page' : ''
        }}"
      >
        <div
          *ngIf="contactInfoItems.length"
          class="contact-form-locations-info-panel"
        >
          <div
            *ngFor="let contactInfoItem of contactInfoItems"
            class="contact-form-location-info-item"
          >
            <div class="contact-form-location-info-item-header">
              {{ contactInfoItem.header }}
            </div>
            <div
              *ngFor="let line of contactInfoItem.normalLines"
              class="contact-form-location-info-item-line contact-form-location-info-item-normal-line {{
                isContactPage ? 'contact-page' : ''
              }}"
            >
              {{ line }}
            </div>
            <div
              *ngFor="let line of contactInfoItem.boldLines"
              class="contact-form-location-info-item-line contact-form-location-info-item-bold-line {{
                isContactPage ? 'contact-page' : ''
              }}"
            >
              {{ line }}
            </div>
          </div>
        </div>
        <div class="contact-form-side-panel-images">
          <img *ngFor="let image of sidePanelImages" src="{{ image.src }}" />
        </div>
        <div
          *ngIf="includeViewMapButton"
          class="contact-form-view-map-button-container"
        >
          <button type="button" (click)="navigateTo(viewMapLink)">
            VIEW MAP
          </button>
        </div>
      </div>
      <div
        class="contact-form-main-panel {{
          isContactPage ? 'contact-page' : ''
        }}"
        [formGroup]="contactFormGroup"
        *ngIf="!!contactFormGroup"
      >
        <div
          *ngIf="includeContactPreferenceBox && (isMobile || isTablet)"
          class="contact-form-preference-box"
        >
          <div class="contact-form-preference-box-inner-div">
            <div class="contact-form-preference-box-header">
              {{ getRadioFormField()?.label }}:
            </div>
            <div class="contact-form-preference-option">
              <input
                type="radio"
                id="email"
                name="contactMethod"
                value="email"
                checked
                formControlName="contactMethod"
                (keyup)="checkForErrors()"
              />
              <label for="email">EMAIL</label>
            </div>
            <div class="contact-form-preference-option">
              <input
                type="radio"
                id="phone"
                name="contactMethod"
                value="phone"
                formControlName="contactMethod"
                (keyup)="checkForErrors()"
              />
              <label for="phone">PHONE</label>
            </div>
          </div>
        </div>
        <div class="contact-form-fields-container">
          <div
            *ngFor="let formFieldConfig of formFieldConfigs"
            [class.half-width]="formFieldConfig.halfWidth"
            [class.placeholder]="formFieldConfig.elementType === 'placeholder'"
            class="contact-form-field"
          >
            <label *ngIf="formFieldConfig?.elementType !== 'radio'"
              >{{ formFieldConfig.label
              }}<span class="contact-form-field-asterisk">{{
                formFieldConfig.required ? "*" : ""
              }}</span></label
            >
            <ng-container *ngIf="!!formFieldConfig.mask; else noMask">
              <input
                [name]="formFieldConfig.name"
                [formControlName]="formFieldConfig.formControlName"
                [style]="'height: ' + formFieldConfig.inputHeight || 'inherit'"
                [type]="formFieldConfig.type"
                [attr.value]="
                  formFieldConfig.forceValue !== undefined
                    ? formFieldConfig.forceValue
                    : null
                "
                [attr.disabled]="
                  formFieldConfig.forceValue !== undefined ? true : null
                "
                [mask]="formFieldConfig.mask"
                (keyup)="checkForErrors()"
                [class]="
                  errorMessages[formFieldConfig.formControlName]
                    ? 'has-errors'
                    : ''
                "
              />
            </ng-container>
            <ng-template #noMask>
              <input
                *ngIf="
                  (!formFieldConfig.elementType ||
                    formFieldConfig.elementType === 'input') &&
                  !formFieldConfig.mask
                "
                [name]="formFieldConfig.name"
                [formControlName]="formFieldConfig.formControlName"
                [style]="'height: ' + formFieldConfig.inputHeight || 'inherit'"
                [type]="formFieldConfig.type"
                [attr.value]="
                  formFieldConfig.forceValue !== undefined
                    ? formFieldConfig.forceValue
                    : null
                "
                [attr.disabled]="
                  formFieldConfig.forceValue !== undefined ? true : null
                "
                (keyup)="checkForErrors()"
                [class]="
                  errorMessages[formFieldConfig.formControlName]
                    ? 'has-errors'
                    : ''
                "
              />
            </ng-template>
            <div
              *ngIf="formFieldConfig.elementType === 'select'"
              class="mat-select-fieldname"
              style="display: none"
            >
              {{ formFieldConfig.name }}
            </div>
            <mat-select
              *ngIf="formFieldConfig.elementType === 'select'"
              placeholder="SELECT"
              [formControlName]="formFieldConfig.formControlName"
              (selectionChange)="checkForErrors()"
              [class]="
                errorMessages[formFieldConfig.formControlName]
                  ? 'has-errors'
                  : ''
              "
            >
              <ng-container *ngIf="!formFieldConfig.options.useProperty">
                <mat-option
                  class="kwh-mat-select-option"
                  *ngFor="let option of (formFieldConfig?.options?.useProperty ? this?.[formFieldConfig?.options?.useProperty] : formFieldConfig.options)"
                  [value]="option.label || option.value"
                  >{{ option.label }}</mat-option
                >
              </ng-container>
              <ng-container *ngIf="!!formFieldConfig.options.useProperty">
                <mat-option
                  class="kwh-mat-select-option"
                  *ngFor="let option of this?.[formFieldConfig.options.useProperty]"
                  [value]="option.label || option.value"
                  >{{ option.label }}</mat-option
                >
              </ng-container>
            </mat-select>
            <textarea
              *ngIf="formFieldConfig.elementType === 'textarea'"
              [name]="formFieldConfig.name"
              [formControlName]="formFieldConfig.formControlName"
              [class]="
                errorMessages[formFieldConfig.formControlName]
                  ? 'has-errors'
                  : ''
              "
              [style]="'height: ' + formFieldConfig.inputHeight || 'inherit'"
              [attr.value]="
                formFieldConfig.forceValue !== undefined
                  ? formFieldConfig.forceValue
                  : null
              "
              [attr.disabled]="
                formFieldConfig.forceValue !== undefined ? true : null
              "
              (keyup)="checkForErrors()"
            >
            </textarea>
            <div class="field-error-text">
              {{ errorMessages[formFieldConfig.formControlName] }}
            </div>
          </div>
        </div>
        <div
          *ngIf="includeContactPreferenceBox && isDesktop"
          class="contact-form-preference-box"
        >
          <div class="contact-form-preference-box-inner-div">
            <div class="contact-form-preference-box-header">
              {{ getRadioFormField()?.label }}:
            </div>
            <div class="contact-form-preference-option">
              <input
                type="radio"
                id="email"
                name="contactMethod"
                value="email"
                checked
                formControlName="contactMethod"
                (change)="checkForErrors()"
              />
              <label for="email">EMAIL</label>
            </div>
            <div class="contact-form-preference-option">
              <input
                type="radio"
                id="phone"
                name="contactMethod"
                value="phone"
                formControlName="contactMethod"
                (change)="checkForErrors()"
              />
              <label for="phone">PHONE</label>
            </div>
          </div>
        </div>
        <div class="contact-form-send-button-container">
          <button
            [attr.disabled]="!checkFormIsValid() || null"
            (click)="checkFormIsValid() && submit()"
          >
            {{ submitFormButtonText }}
          </button>
          <p class="contact-form-required-fields-helper">*Required fields</p>
        </div>
        <div
          *ngFor="let disclaimer of disclaimers"
          class="contact-form-disclaimer-container"
        >
          <p>{{ disclaimer }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
