import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  MatDialogConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { MaterialModule } from 'src/app/material/material.module';
import { GallerySectionComponent } from './gallery-section/gallery-section.component';
import { AvailableLandOverviewMapComponent } from './overview-map/map.component';

import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { BrandBoxesSectionComponent } from './brand-boxes-section/brand-boxes-section.component';
import { BrandSliderComponent } from './brand-slider/brand-slider.component';
import { ButtonsAndGalleryComponent } from './buttons-and-gallery/buttons-and-gallery.component';
import { CalloutWithIconComponent } from './callout-with-icon/callout-with-icon.component';
import { CompanyContactBoxesSectionComponent } from './company-contact-boxes-section/company-contact-boxes-section.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FormSubmissionModalComponent } from './form-submission-modal/form-submission-modal.component';
import { FullShadowModalComponent } from './full-shadow-modal/full-shadow-modal.component';
import { HeroComponent } from './hero/hero.component';
import { ImageBoxesCalloutComponent } from './image-boxes-callout/image-boxes-callout.component';
import { ImageCalloutComponent } from './image-callout/image-callout.component';
import { ImageShadowedCalloutComponent } from './image-shadowed-callout/image-shadowed-callout.component';
import { InfoSectionWithImageComponent } from './info-section-with-image/info-section-with-image.component';
import { LargeBrandScrollerComponent } from './large-brand-scroller/large-brand-scroller.component';
import { LocationsMapComponent } from './locations-map/locations-map.component';
import { NumberedInfoSectionComponent } from './numbered-info-section/numbered-info-section.component';
import { NumberedSliderComponent } from './numbered-slider/numbered-slider.component';
import { PeopleBoxesSectionComponent } from './people-boxes-section/people-boxes-section.component';
import { PhotoSliderComponent } from './photo-slider/photo-slider.component';
import { SimpleButtonsSectionComponent } from './simple-buttons-section/simple-buttons-section.component';
import { SimpleSectionComponent } from './simple-section/simple-section.component';
import { SliderComponent } from './slider/slider.component';
import { SubheadingGallerySectionComponent } from './subheading-gallery-section/subheading-gallery-section.component';
import { ThinHeroComponent } from './thin-hero/thin-hero.component';

@NgModule({
  declarations: [
    HeroComponent,
    ThinHeroComponent,
    FormSubmissionModalComponent,
    SliderComponent,
    InfoSectionWithImageComponent,
    NumberedInfoSectionComponent,
    PeopleBoxesSectionComponent,
    BrandBoxesSectionComponent,
    LargeBrandScrollerComponent,
    SimpleButtonsSectionComponent,
    SimpleSectionComponent,
    LocationsMapComponent,
    ButtonsAndGalleryComponent,
    ImageCalloutComponent,
    PhotoSliderComponent,
    CalloutWithIconComponent,
    ImageShadowedCalloutComponent,
    SubheadingGallerySectionComponent,
    FullShadowModalComponent,
    ContactFormComponent,
    CompanyContactBoxesSectionComponent,
    ImageBoxesCalloutComponent,
    NumberedSliderComponent,
    BrandSliderComponent,
    GallerySectionComponent,
    AvailableLandOverviewMapComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    RouterModule,
    NgxUsefulSwiperModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        disableClose: true,
        minHeight: 280,
      } as MatDialogConfig,
    },
  ],
  exports: [
    HeroComponent,
    ThinHeroComponent,
    FormSubmissionModalComponent,
    SliderComponent,
    InfoSectionWithImageComponent,
    NumberedInfoSectionComponent,
    PeopleBoxesSectionComponent,
    BrandBoxesSectionComponent,
    LargeBrandScrollerComponent,
    SimpleButtonsSectionComponent,
    SimpleSectionComponent,
    LocationsMapComponent,
    ButtonsAndGalleryComponent,
    ImageCalloutComponent,
    PhotoSliderComponent,
    CalloutWithIconComponent,
    ImageShadowedCalloutComponent,
    SubheadingGallerySectionComponent,
    FullShadowModalComponent,
    ContactFormComponent,
    CompanyContactBoxesSectionComponent,
    ImageBoxesCalloutComponent,
    GallerySectionComponent,
    MatProgressSpinner,
    AvailableLandOverviewMapComponent,
  ],
})
export class SharedModule {}
