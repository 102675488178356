import { Component, OnInit } from '@angular/core';

import { ScreenService } from './../../services/screen-size.service';
import { ScrollService } from './../../services/scroll.service';
import { BreakpointsUtils } from './../../utils/breakpoints-base-class.utils';

@Component({
  selector: 'kwh-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BreakpointsUtils implements OnInit {
  year: number = new Date().getFullYear();

  constructor(
    private screenService: ScreenService,
    private scrollService: ScrollService
  ) {
    super(screenService);
  }

  public isTermsOpen: boolean = false;
  public isPrivacyPolicyOpen: boolean = false;

  public toggleIsTermsOpen = () => {
    this.isTermsOpen = !this.isTermsOpen;
  };
  public toggleIsPrivacyPolicyOpen = () => {
    this.isPrivacyPolicyOpen = !this.isPrivacyPolicyOpen;
  };

  public ngOnInit(): void {}

  public scrollToTop(): void {
    this.scrollService.scrollToTop();
  }
}
